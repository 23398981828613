import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import firebaseConfig from '../firebaseconfig'
import { initializeApp } from 'firebase/app'
import { signOut } from 'firebase/auth'
import { GoogleLoginButton } from './GoogleLogin'
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import MobileLoginOverlay from './MobileLoginOverlay'

const app = initializeApp(firebaseConfig)
const auth = getAuth()

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenMobile, setIsOpenMobile] = useState(false)
  const [isOpenMobileEvents, setIsOpenMobileEvents] = useState(false)
  const [isLogedIn, setLoggedIn] = useState(false)
  const [isLogedInOptions, setLogedInOptions] = useState(false)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCartOpen, setCartOpen] = useState(false)
  const [isLoginOpen, setLoginOpen] = useState(false)

  useEffect(() => {
    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }
    })

    // Cleanup the auth state change listener
    return () => unsubscribe()
  }, [])

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const toggleDropdownmobile = () => {
    setIsOpenMobile(!isOpenMobile)
  }

  const handleDropdownToggle = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const toggleDropdownmobileEvents = () => {
    setIsOpenMobileEvents(!isOpenMobileEvents)
  }

  const handleGoogleLogin = () => {
    console.log('Hello')
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoginOpen(false)
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error('Login failed:', error)
      })
  }

  const handelLogin = () => {
    if (isLogedIn) {
      setLogedInOptions(!isLogedInOptions)
    } else {
      setLoginOpen(true)
    }
  }

  const search = () => {}

  const handleLogout = () => {
    // Call the Firebase sign out function
    signOut(auth)
      .then(() => {
        setLogedInOptions(false)
        // Logout successful, perform any additional actions
        console.log('User logged out successfully.')
        window.location.href = '/'
      })
      .catch((error) => {
        // Handle error if logout fails
        console.error('Logout error:', error)
      })
  }

  return (
    <>
      {isLoginOpen && (
        <MobileLoginOverlay setLoginOpen={setLoginOpen}></MobileLoginOverlay>
      )}

      <nav class="sticky top-0 z-50 border-gray-600 bg-gray-900">
        <div class="flex flex-wrap  justify-between items-center mx-auto max-w-screen-xl p-4">
          <div class="flex items-center md:w-auto w-full">
            <div class="flex items-center">
              <button
                onClick={toggleDropdownmobile}
                data-collapse-toggle="mobile-menu-2"
                type="button"
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  class="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <a href="/" class="flex items-center ml-5 mt-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/website%2FFootsypop-Events-logo-light.png?alt=media&token=3a81f8a6-bddb-4f83-91e9-da781c4985f7"
                class="h-6 mr-3"
                alt="event planners in bangalore"
              />
            </a>

            <div class="flex items-center ml-auto">
              <div class="flex items-center ml-auto  lg:order-2">
                <Link
                  to="/search"
                  onClick={search}
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0"
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    class="w-6 h-6"
                  >
                    <path
                      d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </Link>
              </div>

              <div class="flex items-center ml-auto  lg:order-2">
                <button
                  onClick={handelLogin}
                  data-collapse-toggle="mobile-menu-2"
                  type="button"
                  class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0"
                  aria-controls="mobile-menu-2"
                  aria-expanded="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                  >
                    <path
                      d="M15.7 12.71A6 6 0 0 0 12 2a6 6 0 0 0-5.77 7.68 5.93 5.93 0 0 0 2 3 10 10 0 0 0-6.22 8.18.86.86 0 0 0 0 .39 1.06 1.06 0 0 0 .18.35A1 1 0 0 0 3 22a1 1 0 0 0 .73-.22 1 1 0 0 0 .38-.67 8 8 0 0 1 15.9 0 1 1 0 0 0 .32.64A1.06 1.06 0 0 0 21 22h.11a1 1 0 0 0 .66-.37 1 1 0 0 0 .23-.73 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 0 1-2.23-.67 4 4 0 0 1-.6-6.16 4 4 0 0 1 2-1.09A4 4 0 1 1 12 12Z"
                      fill="#E9E9E9"
                      stroke="#FDFCFC"
                      stroke-width="0.1"
                    ></path>
                  </svg>
                </button>
              </div>

              {/* <div class="flex items-center ml-auto  lg:order-2">
          <button onClick={()=> setCartOpen(true)} data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0" aria-controls="mobile-menu-2" aria-expanded="false">
            <div class="relative">
              <svg width="1em" class="w-6 h-6" height="1em" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.22 6.049v.05h3.626c.304 0 .595.112.808.309.214.197.333.464.333.741v12.1c0 .86-.37 1.687-1.03 2.297-.662.61-1.559.953-2.495.953H4.546a3.682 3.682 0 0 1-2.494-.953c-.661-.61-1.031-1.436-1.031-2.297v-12.1c0-.277.119-.544.333-.741.213-.197.504-.309.808-.309h3.625V4.949c0-1.152.496-2.258 1.38-3.075C8.052 1.058 9.252.6 10.504.6c1.252 0 2.452.46 3.337 1.275.884.817 1.38 1.923 1.38 3.075v1.1Zm-2.333.05h.05V4.949c0-.598-.257-1.17-.714-1.592a2.538 2.538 0 0 0-1.719-.658c-.644 0-1.263.236-1.72.658a2.168 2.168 0 0 0-.713 1.592V6.099h4.816Zm-9.533 2.1h-.05v11.05c0 .306.132.6.365.815.233.215.549.335.877.335h11.916c.328 0 .644-.12.877-.335.233-.216.365-.509.365-.815V8.199H15.221V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H8.071V9.349c0 .277-.12.544-.333.741a1.194 1.194 0 0 1-.809.309c-.304 0-.595-.111-.809-.309a1.009 1.009 0 0 1-.333-.741V8.199H3.354Z" fill="#E9E9E9" stroke="#FDFCFC" stroke-width="0.1"></path>
              </svg>
              <span class="absolute text-center bg-pink-500 text-white text-xs font-medium leading-none rounded-full w-4 h-4 flex justify-center items-center top-0 right-0 -mt-1 -mr-1" style={{backgroundColor:"#FC2779"}}>
                3
              </span>
            </div>
          </button>
    
          </div> */}
            </div>
          </div>

          <div class="z-50">
            {isOpenMobile && (
              <div className="absolute left-0 right-0 z-50 mt-9 shadow-sm border-y bg-gray-900 border-gray-900">
                <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <a
                      href="/"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </li>
                  <li class="relative">
                    <a
                      href="#"
                      onClick={toggleDropdownmobileEvents}
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      Events
                    </a>
                    {isOpenMobileEvents && (
                      <ul class="absolute left-0 w-full mt-2 rounded-md shadow-lg lg:left-auto lg:top-auto lg:w-auto lg:mt-0 lg:rounded-none lg:shadow-none lg:border-0 lg:bg-transparent lg:relative lg:flex lg:flex-row lg:space-x-8 lg:p-0 lg:m-0 lg:items-center lg:justify-center lg:gap-4 text-gray-400 bg-gray-800 border-gray-700 z-10">
                        <li>
                          <a
                            href="/products/Anninversary"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Anniversary
                          </a>
                        </li>
                        <li>
                          <a
                            href="/products/Birthdays"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Birthday
                          </a>
                        </li>
                        <li>
                          <a
                            href="/Engagements"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Engagement
                          </a>
                        </li>
                        <li>
                          <a
                            href="/HouseWarming"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            House Warming
                          </a>
                        </li>
                        <li>
                          <a
                            href="/StageEvents"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Stage Events
                          </a>
                        </li>
                        <li>
                          <a
                            href="/Corporate"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Corporate Parties
                          </a>
                        </li>
                        <li>
                          <a
                            href="/WeddingPlanners"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Weddings
                          </a>
                        </li>
                        <li>
                          <a
                            href="/products/NamingCermony"
                            class="block py-2 px-3 hover:text-primary-700"
                          >
                            Naming Ceremony
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* <li>
                    <a
                      href="http://blog.footsypopevents.com"
                      target="_blank"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      Blog
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/About"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Contact"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div class="z-50">
            {isLogedInOptions && (
              <div className="absolute left-0 right-0 z-50 mt-9 shadow-sm border-y bg-gray-900 border-gray-900">
                <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                  <li>
                    <a
                      href="/myOrders"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                      aria-current="page"
                    >
                      My Events
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Contact"
                      class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      Support
                    </a>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 text-gray-400 lg:hover:text-white hover:bg-gray-700 hover:text-white lg:hover:bg-transparent border-gray-700"
                    >
                      {' '}
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            id="mega-menu-full"
            class="items-center hidden w-full md:flex md:w-auto md:order-1 lg:items-center justify-center mx-auto"
          >
            <ul class="flex flex-col mt-4 font-medium md:flex-row md:space-x-8 md:mt-0 mx-auto">
              <li>
                <a
                  href="/"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <button
                  id="mega-menu-full-dropdown-button"
                  data-collapse-toggle="mega-menu-full-dropdown"
                  onClick={toggleDropdown}
                  class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-gray-900 border-b border-gray-100 md:w-auto hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  Events
                  <svg
                    className="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </li>
              {/* <li>
                <a
                  href="http://blog.footsypopevents.com"
                  target="_blank"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  Blog
                </a>
              </li> */}
              <li>
                <a
                  href="/About"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/Contact"
                  class="block py-2 pl-3 pr-4 text-gray-900 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 text-white md:hover:text-blue-500 hover:bg-gray-700 hover:text-blue-500 md:hover:bg-transparent border-gray-700"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div
            id="mega-menu-full"
            className="items-center hidden w-full md:flex md:w-auto md:order-1 lg:items-center justify-center"
          >
            {/* Search */}
            <div
              onClick={search}
              className="relative group mr-2 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                class="w-6 h-6"
              >
                <path
                  d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"
                  fill="#FFFFFF"
                />
              </svg>
            </div>

            {/* Account */}
            <div
              onClick={handleDropdownToggle}
              onTouchMove={handleDropdownToggle}
              className="relative group mr-2 inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 text-gray-400 hover:bg-gray-700 focus:ring-gray-600 ml-auto lg:ml-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
              >
                <path
                  d="M15.7 12.71A6 6 0 0 0 12 2a6 6 0 0 0-5.77 7.68 5.93 5.93 0 0 0 2 3 10 10 0 0 0-6.22 8.18.86.86 0 0 0 0 .39 1.06 1.06 0 0 0 .18.35A1 1 0 0 0 3 22a1 1 0 0 0 .73-.22 1 1 0 0 0 .38-.67 8 8 0 0 1 15.9 0 1 1 0 0 0 .32.64A1.06 1.06 0 0 0 21 22h.11a1 1 0 0 0 .66-.37 1 1 0 0 0 .23-.73 10 10 0 0 0-6.25-8.19ZM12 12a4 4 0 0 1-2.23-.67 4 4 0 0 1-.6-6.16 4 4 0 0 1 2-1.09A4 4 0 1 1 12 12Z"
                  fill="#E9E9E9"
                  stroke="#FDFCFC"
                  strokeWidth="0.1"
                ></path>
              </svg>

              <div
                className={`absolute bg-gray-800 py-2 mt-2 rounded shadow-md w-48 z-50 right-0 transform translate-x-1/2 ${
                  isDropdownVisible ? 'block' : 'hidden'
                }`}
                style={{
                  top: 'calc(100% + 0.5rem)',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {/* Dropdown content */}
                {!isLogedIn ? (
                  <GoogleLoginButton
                    onClick={handleGoogleLogin}
                  ></GoogleLoginButton>
                ) : (
                  <>
                    <a
                      href="/myorders"
                      className="block text-gray-200 px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      My Events
                    </a>
                    <a
                      href="/contact"
                      className="block text-gray-200 px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      Support
                    </a>
                    <button
                      onClick={handleLogout}
                      className="block w-full bg-gray-800 text-gray-200 text-left px-4 py-2 text-sm hover:bg-gray-700"
                    >
                      Logout
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <div
            id="mega-menu-full-dropdown"
            className="absolute left-0  right-0 z-50 mt-0  border-gray-200 shadow-sm border-y bg-gray-800 border-gray-600"
          >
            <div className="grid max-w-screen-xl px-4 py-5 mx-auto text-gray-900 text-white sm:grid-cols-2 md:grid-cols-3 md:px-6">
              <ul aria-labelledby="mega-menu-full-dropdown-button">
                <li>
                  <a
                    href="/products/Anninversary"
                    className="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">Anniversary</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Find the perfect decorations and gifts to celebrate your anniversary.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/products/Birthdays"
                    className="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">Birthday</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Discover a wide variety of birthday decorations, gifts, and party supplies.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/Engagements"
                    className="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">Engagement</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Explore our collection of stunning decorations for all types of events and occasions.</span> */}
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    href="/HouseWarming"
                    className="block text-center p-3 rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">House Warming</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Find everything you need to host the perfect dinner party, from tableware to decorations.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/StageEvents"
                    className="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">Stage Events</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Get the party started with our selection of DJ equipment and event lighting.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/CulturalEvents"
                    className="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div className="font-semibold">Cultural Events</div>
                    {/* <span className="text-sm text-gray-500 text-gray-400">Make your next festival or carnival truly memorable with our festive decorations and supplies.</span> */}
                  </a>
                </li>
              </ul>
              <ul class="hidden md:block">
                <li>
                  <a
                    href="/Corporate"
                    class="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div class="font-semibold">Corporate Parties</div>
                    {/* <span class="text-sm text-gray-500 text-gray-400">Make your baby shower unforgettable with our unique and personalized decorations.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/WeddingPlanners"
                    class="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div class="font-semibold">Weddings</div>
                    {/* <span class="text-sm text-gray-500 text-gray-400">Create the wedding of your dreams with our selection of elegant decorations and accessories.</span> */}
                  </a>
                </li>
                <li>
                  <a
                    href="/products/NamingCermony"
                    class="block p-3 text-center rounded-lg hover:bg-gray-50 hover:bg-gray-700"
                  >
                    <div class="font-semibold">Naming Ceremony</div>
                    {/* <span class="text-sm text-gray-500 text-gray-400">Impress your clients and colleagues with a professional and memorable corporate event.</span> */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </>
  )
}

export default Header
