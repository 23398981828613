
const firebaseConfig = {
    apiKey: "AIzaSyCyrQ0hN0QbFq5E7jlMps8DBMqKXBfDYmY",
    authDomain: "footsypop-3e30b.firebaseapp.com",
    databaseURL: "https://footsypop-3e30b-default-rtdb.firebaseio.com",
    projectId: "footsypop-3e30b",
    storageBucket: "footsypop-3e30b.appspot.com",
    messagingSenderId: "1050377464215",
    appId: "1:1050377464215:web:ba2adf281f3b7ba12d3cd2",
    measurementId: "G-GBMJL1W320"
  };
  

export default firebaseConfig;
  