import React from "react";
import tw from "tailwind-styled-components";

const PolicyContainer = tw.div`
  w-full
  flex
  flex-col
  items-center
`;

const PolicyTitle = tw.h2`
  text-3xl
  font-bold
  my-4
`;

const PolicyText = tw.p`
  text-lg
  mb-2
`;

const PolicySection = tw.div`
  w-full
  max-w-3xl
  p-4
  my-4
  bg-white
  shadow-lg
  rounded-md
`;

const RefundPolicyCon = () => {
  return (
    <PolicyContainer>
      <PolicyTitle>Refund and Cancellation Policy</PolicyTitle>
      <PolicySection>
        <PolicyText>
          At FootsypopEvents, we strive to provide the best possible service to
          our customers. We understand that sometimes cancellations are
          necessary and have a process in place for refunds and cancellations.
        </PolicyText>
        <PolicyText>
          <span className="font-bold">Cancellation Policy:</span> Customers can
          cancel their booking up to 72 hours before the scheduled event.
          Cancellation requests received less than 72 hours before the event
          will not be accepted.
        </PolicyText>
        <PolicyText>
          <span className="font-bold">Refund Policy:</span> If a customer
          cancels their booking within the 72-hour window, FootsypopEvents will
          provide a full refund. Refunds will be processed within 7-10 business
          days.
        </PolicyText>
        <PolicyText>
          If FootsypopEvents cancels a booking due to unforeseen circumstances
          or the unavailability of resources, we will provide a full refund.
        </PolicyText>
        <PolicyText>
          <span className="font-bold">Changes to Bookings:</span> If a customer
          needs to change their booking, they can do so up to 72 hours before
          the scheduled event. Changes requested less than 72 hours before the
          event will not be accepted.
        </PolicyText>
        <PolicyText>
          FootsypopEvents reserves the right to change or cancel a booking due
          to unforeseen circumstances or the unavailability of resources. In
          such cases, we will work with the customer to find a suitable
          alternative or provide a full refund.
        </PolicyText>
      </PolicySection>
      <PolicyText>
        If you have any questions or concerns about our Refund and Cancellation
        Policy, please contact us at{" "}
        <a href="mailto:contact@footsypopevents.com">
          contact@footsypopevents.com
        </a>
        .
      </PolicyText>
    </PolicyContainer>
  );
};

export default RefundPolicyCon;
