import React from "react";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/outline";

function ContactUsCont() {
  return (
    <div>
      <section className="section pt-10 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="card border-0 text-center features feature-primary feature-clean flex flex-col items-center justify-center">
              <div className="icons mx-auto">
                <PhoneIcon className="h-12 w-12 text-indigo-500" />
              </div>
              <div className="content mt-4">
                <h5 className="font-bold text-lg mb-2">Phone</h5>
                <p className="text-gray-500">
                  Start working with Footsypop that can provide everything for
                  an event.
                </p>
                <a
                  href="tel:+91 89044 09695"
                  className="read-more text-indigo-500"
                >
                  +91 89044 09695 / +91 89044 09694
                </a>
              </div>
            </div>

            <div className="card border-0 text-center features feature-primary feature-clean flex flex-col items-center justify-center">
              <div className="icons mx-auto">
                <EnvelopeIcon className="h-12 w-12 text-indigo-500" />
              </div>
              <div className="content mt-4">
                <h5 className="font-bold text-lg mb-2">Email</h5>
                <p className="text-gray-500">
                  Ping us on email our sales team will contact you and will help
                  you conducting a successful event.
                </p>
                <a
                  href="mailto:contact@example.com"
                  className="read-more text-indigo-500"
                >
                 footsypopevents@gmail.com
                </a>
              </div>
            </div>

            <div className="card border-0 text-center features feature-primary feature-clean flex flex-col items-center justify-center">
              <div className="icons mx-auto">
                <MapPinIcon className="h-12 w-12 text-indigo-500" />
              </div>
              <div className="content mt-4">
                <h5 className="font-bold text-lg mb-2">Location</h5>
                <p className="text-gray-500">
                  Kasavanahalli, Hosa Road Bangalore, India- 560035
                </p>
                <a
                  href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.1563100076637!2d77.677197!3d12.8976687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13c59b7ce8e3%3A0x747497691d804b53!2sFootsypop%20Events!5e0!3m2!1sen!2sin!4v1671213299397!5m2!1sen!2sin"
                  data-type="iframe"
                  className="video-play-icon read-more lightbox text-indigo-500"
                >
                  View on Google map
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container-fluid mt-10 mb-0">
        <div class="row h-500">
          <div class="col-12 p-0 h-500">
            <div class="card map border-0 h-500">
              <div class="card-body p-0 h-500">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3889.1563100076637!2d77.677197!3d12.8976687!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae13c59b7ce8e3%3A0x747497691d804b53!2sFootsypop%20Events!5e0!3m2!1sen!2sin!4v1671213299397!5m2!1sen!2sin"
                  class="w-full h-screen"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsCont;
