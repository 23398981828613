import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ Title, title, description, keywords }) => {
  return (
    <Helmet>
      <title>{Title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Add other necessary meta tags for SEO here */}
    </Helmet>
  )
}

export default SEO
