import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { GoogleLoginButton } from './GoogleLogin';
import { forceLongPolling, get, push, ref,set, update } from 'firebase/database';
import {  onValue, getDatabase } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';
import { connectStorageEmulator } from 'firebase/storage';
import Alert from './Alert';
import axios from "axios";
import Button from 'react-bootstrap-button-loader';
import MobileLoginOverlay from './MobileLoginOverlay';
import LoginToConitue from './LoginContinue';
import { getFirestore, collection, doc, addDoc, getDoc, setDoc } from 'firebase/firestore';




const firestore = getFirestore(); // Assuming you have initialized Firebase Firestore
const auth = getAuth();


function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}-${month}-${year}`;
}




const Checkout = ({orderKey}) => {

  
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('Bangalore');
  const [postalCode, setPostalCode] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [alternateNumber, setAlternateNumber] = useState('');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('razor');
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [productImage, setProductImage ] = useState('');
  const [productPrice, setProductPrice] =useState(0);
  const [productSave, setProductSave] = useState(0);
  const [productName, setProductName] = useState('');
  const [error,setError] = useState('');
  const [orderFetched, setOrderFetched] = useState(false);
  const [productKey, setProductKey] = useState('');
  const [loadingBtn,setloadingBtn] = useState(false);
  const [orderId, setOrderId] = useState("");



  const app = initializeApp(firebaseConfig);
  const database = getDatabase();

  useEffect(() => {
    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        setUserEmail(user.email);
      } else {
        setLoggedIn(false);
        setUserEmail('');
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);

   useEffect(() => {
    // Include the Razorpay SDK script in the document head
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!orderFetched) {
      console.log(orderFetched)
      const orderRef = ref(database, `orders/${orderKey}`);
  
      get(orderRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const orderData = snapshot.val();
            // Do something with the order data
            console.log('Order Data:', orderData);
            const { name, phoneNumber, eventDate,  productKey, zipCode, orderId } = orderData;
            console.log(eventDate);
            setProductKey(productKey);
            if(name){
              setName(name);
              setPhoneNumber(phoneNumber);
              setEventDate(new Date(eventDate))
              setPostalCode(zipCode);
              setOrderId(orderId);
            }
       
  

            
            const productRef =  doc(getFirestore(), 'products',productKey );
            getDoc(productRef).then((snapshot) => {
              const productData = snapshot.data();
              console.log(productData);
              const { name, originalPrice, price, downloadUrls } = productData;
              setProductName(name);
              setProductPrice(price);
              setProductSave(originalPrice - price);
              console.log(productData);
              setProductImage(generateThumbnailUrl(downloadUrls[0], 200, 200));
            });
          } else {
            console.log('Order does not exist.');
          }
          setOrderFetched(true); // Set the orderFetched state to true after fetching the order
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error retrieving order:', error);
        });
    }
  }, [orderKey, orderFetched]);
  

  const formatPrice = (price) =>{
    const num = Number(price)
    return num.toLocaleString();
  }

  const generateThumbnailUrl = (imageUrl, width, height) => {
    const thumbnailUrl = `${imageUrl}?alt=media&width=${width}&height=${height}`;
  
    return thumbnailUrl;
  };


  const handleRazor = async() => {
    try {
      const response = await axios.post('https://flamesrazrorpay.azurewebsites.net/api/HttpTrigger1?code=Hq7XEiKFvfH6S35JKkYeM3JzVH9HS8UXByu1-68kavupAzFub7ZiaA==',{
          price: productPrice,
        });
      const RazorOrderIdorderKey = response.data.orderId;
      console.log("Order ID:", RazorOrderIdorderKey);

      // Call the Razorpay checkout function
      const options = {
        key: "rzp_live_LYHyKNT0ZiQLF5", // Replace with your Razorpay API key
        amount: productPrice*100, // Amount in paise (e.g., 50000 paise = ₹500)
        currency: "INR",
        name: "Footsypop Events",
        description: productName,
        id: RazorOrderIdorderKey,
        handler: function (response) {
          console.log("Payment ID:", response.razorpay_payment_id);
  
          // Update the status of the order to "success" in the database
          const database = getDatabase();
          const orderRef = ref(database, `orders/${orderKey}`);
          update(orderRef, { status: 'success' })
            .then(async() => {
              console.log('Order status has been updated to "success".');
              const response = await axios.post('https://yjtkaxtx1k.execute-api.us-east-1.amazonaws.com/dev/',{
            orderId :orderId,
            userName : name,
            userEmail : userEmail,
            userPhone : phoneNumber,
            userAddress : address + " "+ apartment + " "+ city +" "+ postalCode,
            productImage : productImage,
            productName : productName,
            productPrice : productPrice,
            eventDate: ""+eventDate,
            eventTime: timeSlots
        });
              window.location.href = '/Ordersuccessful';

              // Additional actions after updating the status
            })
            .catch((error) => {
              console.error('Error updating order status:', error);
              // Handle the error condition
              setloadingBtn(false);
            });
        },
        prefill: {
          name: name,
          email: userEmail,
          contact: phoneNumber,
        },
        modal: {
          ondismiss: function () {
            setloadingBtn(false);
            // Handle modal close event
            console.log("Razorpay modal closed by the user");
            // Additional actions when the modal is closed
          },
        },
      };

      const razorpayCheckout = new window.Razorpay(options);
      razorpayCheckout.open();
    } catch (error) {
      console.error("Error creating order:", error);
      setloadingBtn(false);
    }
  }


  const handleFormSubmit = async() => {

    console.log('submitted')
  
    // Check if any required fields are empty
    if (!phoneNumber || !eventDate || !name || !address || !city || !postalCode || !selectedTimeSlot || !selectedPaymentMethod) {
      setError('Please fill in all required fields.');
      setTimeout(()=>{
        setError('');
      }, 2000)
      return;
    }

    if(!loggedIn){
      setError('Please enter the email address by google login');
      return;

    }

    setloadingBtn(true);
    const currentUser = auth.currentUser;
    const userId = currentUser.uid;

    const orderData = {
      name,
      phoneNumber,
      alternateNumber,
      userEmail,
      address,
      apartment,
      city,
      postalCode,
      eventDate,
      selectedTimeSlot,
      status: 'pending',
      productPrice,
      productImage,
      productName,
      productKey: productKey,
      selectedPaymentMethod,
      orderKey,
      userId,      
      orderId
    };


    
        if(selectedPaymentMethod=="razor"){
          handleRazor();
        }else{
          try{
            const db = getFirestore();
            const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
            const currentYear = String(new Date().getFullYear());
            console.log(currentMonth,currentYear)
            const orderRef = collection(db,"orders" ,currentYear,currentMonth); // Assuming orderId is the unique identifier for the order document
            addDoc(orderRef, orderData)
            .then(() => {
              console.log('Order data has been successfully set in Firestore.');
              window.location.href = '/Ordersuccessful';
            })
            .error((e)=>{
              console.log(e)
            })  
            }
            catch(e){
              console.log(e);
            }
  }
};
         

        //   const response = await axios.post('https://yjtkaxtx1k.execute-api.us-east-1.amazonaws.com/dev/',{
        //     orderId :orderId,
        //     userName : name,
        //     userEmail : userEmail,
        //     userPhone : phoneNumber,
        //     userAddress : address + " "+ apartment + " "+ city +" "+ postalCode,
        //     productImage : productImage,
        //     productName : productName,
        //     productPrice : productPrice,
        //     eventDate: formatDate(eventDate),
        //     eventTime: selectedTimeSlot
        // });

        
    
        // const addOrderToFirestore = async (orderData) => {
        //   try {
        //     const userDoc = await getDoc(userRef);
            
        //     if (userDoc.exists()) {
        //       const newOrderRef = await addDoc(myOrderRef, orderData);
        //       console.log('Order added with ID:', newOrderRef.id);
        //       window.location.href = '/Ordersuccessful';
        //     } else {
        //       await setDoc(userRef, {}); // Create an empty user document
        //       const newOrderRef = await addDoc(myOrderRef, orderData);
        //       console.log('User document created. Order added with ID:', newOrderRef.id);
        //       window.location.href = '/Ordersuccessful';

        //     }
        //   } catch (error) {
        //     console.error('Error adding order:', error);
        //   }
        // };
        
        //   addOrderToFirestore(orderData);

       

  const isMobileDevice = () => {
    const mobileBreakpoint = 640; // Set the breakpoint for mobile devices
    return window.innerWidth <= mobileBreakpoint;
  };

  const handleLogin = () => {
    console.log("Hello");
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };


  const timeSlots = [
    { id: 'slot1', label: '9 AM - 12 PM' },
    { id: 'slot2', label: '12 PM - 4 PM' },
    { id: 'slot3', label: '4 PM - 8 PM' },
  ];

  const paymentMethods = [
    // { id: 'razor', label: 'Razor Pay' },
    { id: 'pl', label: 'Pay later' },

  ];
  return (
    
    <>
  {/* Your component code */}

      {error && <Alert type={"error"} message={error} />}
      <div className="min-h-screen bg-gray-100 mt-0 mb-50 flex items-center justify-center">
        <div className="bg-grey-100">
          <div className="max-w-4xl w-full mx-4">
            <h2 className="text-2xl font-bold mt-10 mb-4">Checkout</h2>
            {!loggedIn &&
            <div className='sm:grid-cols-2 gap-8 sm:grid-cols-1'>
             <div className="mb-8 sm:grid-cols-2 gap-8 sm:grid-cols-1">
              <h3 className="text-lg font-semibold mb-2">Login</h3>
              {/* Form fields for login */}
              <GoogleLoginButton  onClick={handleLogin}  ></GoogleLoginButton>

            </div>


            <div className="flex items-center mb-8 sm:grid-cols-2 gap-8 sm:grid-cols-1">
              <hr className="flex-grow border-t-2 border-gray-300" />
              <span className="mx-4 text-gray-500">or</span>
              <hr className="flex-grow border-t-2 border-gray-300" />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2 "> Continue as Guest</h3>
              {/* Form fields for guest checkout */}
            </div>


            </div>
       

            }
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 sm:grid-cols-1">
              <div>
             
              <div className=" sm:hidden">
                  <div className="bg-white rounded-md p-6 shadow-md mb-8 " style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                  <h3 className="text-lg font-semibold mb-4">Order Details</h3>
                  <div className="flex items-center mb-4">
                    <img
                      src={productImage}
                      alt="event planners in bangalore"
                      className="w-16 h-16 rounded-md mr-4"
                    />
                    <div>
                      <p className="font-semibold">{productName}</p>
                      <p className="text-gray-500">Price: ₹{formatPrice(productPrice)}</p>
                      <p className="text-gray-500">
                        Total Savings: ₹{formatPrice(productSave)}
                      </p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <p className="font-semibold">Order Total: ₹{formatPrice(productPrice)}</p>
                  </div>
                </div>
                <div className="bg-white rounded-md p-6 shadow-md mb-8 " style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                  <h3 className="text-lg font-semibold mb-4">Contact Details</h3>
                  <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block mb-1">
                      Email id *
                    </label>
                    {loggedIn ? (
                      <input
                        type="email"
                        id="email"
                        value={userEmail}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    ) : (
                      <>
                    <input
                        type="email"
                        id="email"
                        value={userEmail}
                        onChange={(e)=>setUserEmail(e.target.value)}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />                      </>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block mb-1">
                      Phone Number *
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      maxLength="10"
                      value={phoneNumber}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/\D/g, ''); // Remove non-numeric characters
                        const formattedInput = numericInput.slice(0, 10); // Limit the input to 10 characters
                    
                        setPhoneNumber(formattedInput);
                      }}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="alternateNumber" className="block mb-1">
                      Alternate Number
                    </label>
                    <input
                      type="text"
                      id="alternateNumber"
                      value={alternateNumber}
                      onChange={(e) => {
                        const input = e.target.value;
                        const numericInput = input.replace(/\D/g, ''); // Remove non-numeric characters
                        const formattedInput = numericInput.slice(0, 10); // Limit the input to 10 characters
                    
                        setAlternateNumber(formattedInput);
                      }}   
                      maxLength="10"                   
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    />
                  </div>
                </div>
                <div className="bg-white rounded-md p-6 shadow-md mb-8 " style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                  <h3 className="text-lg font-semibold mb-4">Event Details</h3>
                  <div className="mb-4">
                    <label htmlFor="eventDate" className="block mb-1">
                      Event Date *
                    </label>
                    <DatePicker
                      id="eventDate"
                      selected={eventDate}
                      onChange={(date) => setEventDate(date)}
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    />
                  </div>
                  <h3 className="text-lg font-semibold mb-4">Time Slot *</h3>
                  <div className="flex items-center space-x-4">
                    {timeSlots.map((slot) => (
                      <label
                        key={slot.id}
                        htmlFor={slot.id}
                        className="flex items-center"
                      >
                        <input
                          type="radio"
                          id={slot.id}
                          value={slot.label}
                          checked={selectedTimeSlot === slot.label}
                          onChange={() => setSelectedTimeSlot(slot.label)}
                          className="mr-2"
                          required
                        />
                        {slot.label}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="bg-white rounded-md p-6 shadow-md mb-8 " style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                  <h3 className="text-lg font-semibold mb-4">
                    Venue Information
                  </h3>
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-1">
                      Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="apartment" className="block mb-1">
                      Apartment / Suite *
                    </label>
                    <input
                      type="text"
                      id="apartment"
                      value={apartment}
                      onChange={(e) => setApartment(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="address" className="block mb-1">
                      Address *
                    </label>
                    <input
                      type="text"
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="city" className="block mb-1">
                        City *
                      </label>
                      <input
                      type="text"
                      id="city"
                      value={city}
                      readOnly
                      className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                      required
                    />
                    </div>
                    <div>
                      <label htmlFor="postalCode" className="block mb-1">
                        Postal Code *
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
                        required
                      />
                    </div>
                  </div>
                </div>



                <div className="bg-white rounded-md p-6 shadow-md mb-8 " style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                <h3 className="text-lg font-semibold mb-4">Payment Details</h3>
                <div className="flex items-center space-x-4">
                  {paymentMethods.map((method) => (
                    <label key={method.id} htmlFor={method.id} className="flex items-center">
                      <input
                        type="radio"
                        id={method.id}
                        value={method.id}
                        checked={selectedPaymentMethod === method.id}
                        onChange={() => setSelectedPaymentMethod(method.id)}
                        className="mr-2"
                        required
                      />
                      {method.label}
                    </label>
                  ))}
                </div>
                </div>




              </div>
              <div>
                <div className="bg-white rounded-md p-6 shadow-md mb-8  sm:block" style={{ maxWidth: isMobileDevice() ? '20rem' : 'none' }}>
                  <h3 className="text-lg font-semibold mb-4">Order Details</h3>
                  <div className="flex items-center mb-4">
                    <img
                      src={productImage}
                      alt="event planners in bangalore"
                      className="w-16 h-16 rounded-md mr-4"
                    />
                    <div>
                      <p className="font-semibold">{productName}</p>
                      <p className="text-gray-500">Price: ₹{formatPrice(productPrice)}</p>
                      <p className="text-gray-500">
                        Total Savings: ₹ {formatPrice(productSave)}
                      </p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <p className="font-semibold">Order Total: ₹{formatPrice(productPrice)}</p>
                </div>
                
              </div>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                loading={loadingBtn}
                onClick={() => handleFormSubmit()}
                className="px-8 mb-10 py-4 bg-indigo-500 text-white rounded-lg font-semibold"
              >
                Place Order
              </Button>

             
            </div>
          </div>
        </div>
      </div>
  </>
  );

     }
  
  
export default Checkout;
