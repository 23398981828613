import React, { useEffect, useState } from 'react';
import {
    getFirestore,
    doc,
    updateDoc,
    collection,
    onSnapshot,
    Timestamp,
} from 'firebase/firestore';

const EditLeadModal = ({ isOpen, onRequestClose, leadData, onLeadUpdated, month, year }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [editedData, setEditedData] = useState(leadData);
    console.log(leadData.name)
    
    const [assigneesData, setAssigneeData] = useState([]);




    useEffect(() => {
        fetchAssignees();
    }, []);

    const fetchAssignees = () => {
        const db = getFirestore();
        const assigneesCollectionRef = collection(db, 'assignees');

        onSnapshot(assigneesCollectionRef, (snapshot) => {
            const assigneesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            // Update your state or assignees list with the fetched data
            setAssigneeData(assigneesData);
        });
    };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "eventDate") {
      console.log(value)
      const dateParts = value.split('-'); // Split the date string
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) ; // Month is 0-based
      const day = parseInt(dateParts[2], 10);
      console.log(year)
      console.log(month)
      console.log(day)
    
      const timestamp = new Date(year, month-1, day).getTime(); // Convert to timestamp
      console.log(timestamp)
      const timestampObj = Timestamp.fromMillis(timestamp);

    
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
        timestamp: timestampObj
      }));
    }else{
      setEditedData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  };

    const handleAddComment = () => {
        const { newComment } = editedData;
        if (newComment.trim() !== '') {
            setEditedData((prevData) => ({
                ...prevData,
                comments: [...prevData.comments, newComment],
                newComment: '',
            }));
        }
    };

    const onUpdateLead = async () => {
        try {


            const db = getFirestore();
            const leadRef = doc(db, `leads/${year}/All`, leadData.key);


            await updateDoc(leadRef, editedData);

            console.log('Lead updated successfully!');
            setIsSuccess(true);

            // Pass the updated lead data to the parent component
            onLeadUpdated(editedData);

            // Close the modal
            onRequestClose();
        } catch (error) {
            // Handle errors, show an error message, or perform error handling
            console.error('Error updating lead:', error);
        }
    };

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay fixed inset-0 bg-black opacity-50" />
                    <div className="modal absolute bg-white p-6 rounded-lg shadow-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 max-h-[80vh] overflow-y-auto">
                        <button
                            onClick={onRequestClose}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        >
                            Close
                        </button>
                        <h2 className="text-2xl font-bold mb-4">Edit Lead</h2>
                        <form>
                            {/* Input fields for editing lead data */}
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={editedData.name}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Event Type:
                                </label>
                                <input
                                    type="text"
                                    name="event"
                                    value={editedData.event}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Event Date:
                                </label>
                                <input
                                    type="date"
                                    name="eventDate"
                                    value={editedData.eventDate}
                                    onChange={handleChange}
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Phone:
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={editedData.phone}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Lead Source:
                                </label>
                                <select
                                    name="leadSource"
                                    value={editedData.leadSource}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                >
                                    <option value="">Select Lead Source</option>
                                    <option value="Organic">Organic</option>
                                    <option value="Referal">Referal</option>
                                    <option value="Google">Google</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Justdial">Justdial</option>
                                    <option value="Justdial">Others</option>

                                    {/* Add more lead sources as needed */}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Assignee:
                                </label>
                                <select
                                    name="assignee"
                                    value={editedData.assignee}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"

                                >
                                    <option value="">Select Assignee</option>
                                    {assigneesData.map((assignee) => (
                                        <option key={assignee.id} value={assignee.name}>
                                            {assignee.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Status:
                                </label>
                                <select
                                    name="status"
                                    value={editedData.status}
                                    onChange={handleChange}
                                    required
                                    className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                                >
                                    <option value="">Select Status</option>
                                    <option value="FollowingUp">Following Up</option>
                                    <option value="InTouch">In Touch</option>
                                    <option value="SharedQuote">Shared Quote</option>
                                    <option value="Confirmed">Confirmed</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Dropped">Dropped</option>
                                    {/* Add more statuses as needed */}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Comments:
                                </label>
                                <div className="mb-4">
                                    <div className="mb-2">
                                        <ul className="mb-2">
                                            {editedData.comments.map((comment, index) => (
                                                <li
                                                    key={index}
                                                    className="ml-4 w-50 p-2 rounded-md list-disc"
                                                    style={{ maxWidth: '400px' }}
                                                >
                                                    {comment}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="flex">
                                        <textarea
                                            type="text"
                                            name="newComment"
                                            value={editedData.newComment}
                                            onChange={handleChange}
                                            placeholder="Add a new comment"
                                            className="border rounded-md px-4 py-2 w-full"
                                            style={{ resize: 'vertical', minHeight: '40px' }}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleAddComment}
                                            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <button
                                type="button"
                                onClick={onUpdateLead}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                            >
                                Save Changes
                            </button>
                        </form>
                        {isSuccess && (
                            <div className="bg-green-200 text-green-800 border border-green-600 rounded-md p-2 mt-2">
                                Lead updated successfully!
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default EditLeadModal;
