import React, { useState, useEffect, useRef } from 'react';
import Card from './Product';
import './vProducts.css';
import { getFirestore, collection, setDoc, doc, getDoc, limit,where ,query ,deleteDoc, onSnapshot } from 'firebase/firestore';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig);
const firestore = getFirestore();

const CardRow = ({ cat, link }) => {
  const [products, setProducts] = useState([]);
  const cardRowRef = useRef(null);

  useEffect(() => {
    const productsCollection = collection(firestore, 'products');
    const categoryQuery = query(productsCollection, where('category', '==', cat), limit(7));
  
    const unsubscribe = onSnapshot(categoryQuery, (snapshot) => {
      const formattedProducts = snapshot.docs.map((doc) => {
        const product = doc.data();
        return {
          key: doc.id,
          // Requesting a 240x240 sized image from the original imageUrl
          imageUrl: `${product.downloadUrls[0]}&w=240&h=240`,
          name: product.name,
          price: `${product.price}`,
          originalPrice: product.originalPrice,
          // Access other properties using product.propertyName
        };
      });
  
      setProducts(formattedProducts);
    });
  
    return () => {
      unsubscribe();
    };
  }, [cat, firestore]);
  

  const scrollLeft = () => {
    cardRowRef.current.scrollTo({
      left: cardRowRef.current.scrollLeft - 200,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    cardRowRef.current.scrollTo({
      left: cardRowRef.current.scrollLeft + 200,
      behavior: 'smooth',
    });
  };

  const handleViewMore = () => {
    window.location.href = link;
    // Handle the "View More" button click
  };

  return (
    <div className="flex z-10 flex-col bg-white m-auto p-auto relative">
      <div className="flex items-center justify-between mb-2 ml-8">
        <h2 className="text-2xl font-bold  mt-10">{cat}</h2>
        <button
          type="button"
          className="text-sm text-blue-500  mr-8 mt-10 hover:underline cursor-pointer"
          onClick={handleViewMore}
        >
          View More
        </button>
      </div>
      <div className="overflow-x-scroll hide-scroll-bar" ref={cardRowRef}>
        <div className="flex flex-nowrap space-x-4 p-4">
          {products.map((product, index) => (
            <div key={index}>
              <Card
                imageUrl={product.imageUrl}
                name={product.name}
                price={product.price}
                id={product.key}
                op={product.originalPrice}
              />
            </div>
          ))}
        </div>
      </div>
      {cardRowRef.current && (
        <>
          <button
            type="button"
            className="scroll-button left"
            onClick={scrollLeft}
          >
            <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
              <span className="hidden">Previous</span>
            </span>
            
              </button>
          <button
            type="button"
            className="scroll-button right"
            onClick={scrollRight}
          >
          <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
              <svg
                className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 12h13M12 5l7 7-7 7"
                ></path>
              </svg>
              <span className="hidden">Next</span>
            </span> 
           </button>
        </>
      )}
    </div>
  );
};

export default CardRow;
