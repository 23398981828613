import React, { useEffect, useState } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminSlider from "./Components/SliderAdmin";
import { get, getDatabase, onValue, ref, remove } from "firebase/database";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const AdminSliderPage = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [sliderData, setSliderData] = useState();
  const [showAddSlide, setShowAddSlide] = useState(false);

  const [menu,setmenu ] = useState(false);
  const [Role,setRole] = useState(null);


  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            // window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const sliderRef = ref(db, 'sliderData');

    const handleSnapshot = (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const SlideData = Object.keys(data).map((key, index) => {
          return {
            key: key,
            imageUrl: data[key],
            id: index + 1
          };
        });
        setSliderData(SlideData);
      } else {
        // If no data is available, reset the state or show a message accordingly
        setSliderData([]);
      }
    };

    // Set up the listener for changes on the slider data reference
 onValue(sliderRef, handleSnapshot);

    // Clean up the listener when the component unmounts
    
  }, []);

  const handleDelete = (key) => {
    console.log(key)
    const db = getDatabase();
    remove(ref(db, "sliderData/" + key))
      .then(() => {
        console.log("Deleted the Slide");
      })
      .catch((error) => {
        console.error("Error deleting the slide:", error);
      });
  };
  

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setmenu} />
      <div className="flex-1 xl:ml-56">
      <AdminHeader setMenuOpen={setmenu} menu={menu} />
        {showAddSlide && <AdminSlider showAddSlide={showAddSlide} setShowAddSlide={setShowAddSlide}></AdminSlider>}
        <div className="p-6">
            <div className="flex justify-between ">
                <h2 className="text-2xl font-bold mb-4">Slider</h2>
                <div>
                    <button
                    onClick={()=> setShowAddSlide(true)}
                    className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2"
                    >
                        Add Slide
                    </button>
                </div>

            </div>
          
        </div>
        <>
        <table>
            <thead>
            <tr className="bg-gray-300 border-collapse ">

                <th className="py-3 px-6 text-gray-700 border"> Index</th>
                <th className="py-3 px-6 text-gray-700 border">Image</th>
                <th className="py-3 px-6 text-gray-700 border">Action</th>
            </tr>

            </thead>
          
        <tbody>
        {sliderData &&
            sliderData.map((slide) => {
            return (
                <tr  key={slide.id}>
                <td className="px-8 py-3 border">{slide.id}</td>
                <td className="px-8 py-3 border">
                    <img
                    alt="event planners in bangalore"
                    src={slide.imageUrl}
                    ></img>
                    
                    </td>
                <td className="px-10 py-3 border w-1/7">
                    <button onClick={() => handleDelete(slide.key)} className="px-6 py-3 bg-red-500 text-white rounded-md hover:bg-red-600">Delete</button>
                </td>
                </tr>
            );
            })}
        </tbody>
        
        </table>
        {/* <AdminSlider></AdminSlider> */}
        </>
      </div>
    </div>
  );
};

export default AdminSliderPage;
