import React from 'react'

const clientData = [
  {
    name: 'Magic Bricks',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fmagicbricks.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Intel',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fintel.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Titan',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Ftitan.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Deloitte',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fdeloitte.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Majorel',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fmajorel.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Shoppers Stop',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2FShoppers%20stop.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Manipal',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fmanipal.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'TMF',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Ftmf.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Zolo',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2Fzolo.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
  {
    name: 'Prestige Group',
    logo: 'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/clients%2FPrestige%20group.jpeg?alt=media&token=0c8e4051-7216-454f-8b71-da07c90602ba',
  },
];


const ClientList = () => {
  return (
    <div className="bg-white py-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center mb-10">
          <h2 class="text-xl text-gray-600 font-bold mb-4 ml-4 mb-1">
            Our Clients
          </h2>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {clientData.map((client) => (
            <div key={client.name} className="flex items-center justify-center">
              <img
                className="w-40 h-40 object-contain"
                src={client.logo}
                alt={client.name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ClientList
