import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import { getDatabase, ref, push, set } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';
import Button from 'react-bootstrap-button-loader';



import 'react-datepicker/dist/react-datepicker.css';
import 'react-time-picker/dist/TimePicker.css';

function createOrderId() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const date = String(currentDate.getDate()).padStart(2, '0');

  // Generate 5 random digits
  const randomDigits = Math.floor(10000 + Math.random() * 90000);

  // Combine date, month, year, and random digits to create the order ID
  const orderId = `${date}${month}${year}${randomDigits}`;

  return orderId;
}

const BookingModal = ({ isOpen, onClose, onSubmit, productKey }) => {
  const [zipCode, setZipCode] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingBtn,setloadingBtn] = useState(false);

  const app = initializeApp(firebaseConfig);



  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Create a new order object with the provided data

     // Validation checks
  if (!zipCode || !name || !phoneNumber || !eventDate) {
    setSuccessMessage('Please complete all fields.');
    return;
  }

  if (phoneNumber.length !== 10 || isNaN(phoneNumber)) {
    setSuccessMessage('Please enter a valid 10-digit phone number.');
    return;
  }

  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (eventDate < tomorrow) {
    setSuccessMessage('Please select a date from tomorrow onwards.');
    return;
  }

  if (!zipCode.startsWith('560')) {
    setSuccessMessage('Please enter a valid Bangalore zip code.');
    return;
  }

  setloadingBtn(true);
    const orderId = createOrderId();
  
const newOrder = {
  orderId: orderId, // Placeholder for orderId
  zipCode,
  name,
  phoneNumber,
  eventDate: ""+eventDate,
  status: 'lead',
  productKey: productKey,
  orderKey: ''

};

// Get a reference to the 'orders' collection in the database
const db = getDatabase();
const ordersRef = ref(db, 'orders');

// Push the new order to the database
push(ordersRef, newOrder)
  .then((newOrderRef) => {
    // Get the generated orderId from the newOrderRef
    const key = newOrderRef.key;
    // Update the orderId in the newOrder object
    newOrder.orderKey = key;
    // Update the order in the database with the generated orderId
    set(newOrderRef, newOrder)
      .then(() => {

        window.location.href = `/Checkout/${key}`;
        // Clear form fields
        setZipCode('');
        setName('');
        setPhoneNumber('');
        setEventDate(null);
        // Close the modal
        // setSuccessMessage('Order successful! We will contact you soon.');
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error updating order:', error);
        setloadingBtn(false)
      });
  })
  .catch((error) => {
    // Handle any errors
    console.error('Error adding order:', error);
    setloadingBtn(false)

  });
  };
  

  const isBangaloreZipCode = zipCode.startsWith('560');

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Book Now</h2>
        <form onSubmit={handleFormSubmit}>
          {/* Form fields */}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-1">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="zipCode" className="block mb-1">Zip Code (Only Bengaluru)</label>
            <input
  type="text"
  id="zipCode"
  value={zipCode}
  onChange={(e) => {
    const input = e.target.value;
    const regex = /^[0-9]{0,6}$/; // Regular expression to match 0 to 6 numeric characters
    if (regex.test(input)) {
      setZipCode(input);
    }
  }}
  maxLength="6"
  title="Please enter a 6-digit zip code."
  className={`w-full border ${isBangaloreZipCode ? 'border-gray-300' : 'border-red-500'} rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2`}
/>
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block mb-1">Phone Number</label>
            <input
  type="text"
  id="phoneNumber"
  value={phoneNumber}
  onChange={(e) => {
    const input = e.target.value;
    const numericInput = input.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedInput = numericInput.slice(0, 10); // Limit the input to 10 characters

    setPhoneNumber(formattedInput);
  }}
  maxLength="10"
  title="Please enter a 10-digit phone number."
  className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
/>

          </div>
          <div className="mb-4">
            <label htmlFor="eventDate" className="block mb-1">Event Date</label>
            <DatePicker
            id="eventDate"
            selected={eventDate}
            onChange={(date) => setEventDate(date)}
            minDate={new Date()} // Set min date to tomorrow
            dateFormat="dd-MM-yyyy"
            className="w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 p-2"
          />
          </div>
          
          {/* Buttons */}
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="mr-2 px-4 py-2 text-gray-500 border border-gray-300 rounded-md">Cancel / Close</button>
            <Button  type="submit"    loading={loadingBtn} className="px-4 py-2 text-white bg-indigo-500 rounded-md">Checkout</Button>
          </div>
        </form>
        
        {/* Success message */}
        {successMessage && (
        <div className="text-red-500 text-center mt-4">{successMessage}</div>
      )}  
      
      </div>
    </div>
  );
  
};

export default BookingModal