import React from 'react';
import 'tailwindcss/tailwind.css';

const ExpertiseStats = () => {
  return (
    <div className="bg-cover bg-center bg-opacity-50" style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/Capture.PNG?alt=media&token=ca6daa65-0d4d-4c9a-824b-17c78daa0e82')" }}>
      <div className="bg-gray-900 bg-opacity-75 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-1/3 text-center p-4">
              <div className="mb-4 mx-auto w-24 h-24 bg-white rounded-full flex items-center justify-center">
                <div className="text-3xl font-semibold text-blue-900">3+</div>
              </div>
              <p className="text-xl font-semibold text-white mt-4">Years of Experience</p>
            </div>
            <div className="w-full md:w-1/3 text-center p-4">
              <div className="mb-4 mx-auto w-24 h-24 bg-white rounded-full flex items-center justify-center">
                <div className="text-3xl font-semibold text-blue-900">130+</div>
              </div>
              <p className="text-xl font-semibold text-white mt-4">Clients Serviced</p>
            </div>
            <div className="w-full md:w-1/3 text-center p-4">
              <div className="mb-4 mx-auto w-24 h-24 bg-white rounded-full flex items-center justify-center">
                <div className="text-3xl font-semibold text-blue-900">300+</div>
              </div>
              <p className="text-xl font-semibold text-white mt-4">Events Organized</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertiseStats;
