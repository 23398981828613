import React, { useState } from 'react';

const Alert = ({ type, message }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed top-10 left-1/2 transform -translate-x-1/2">
        <div
          className={`bg-${type === 'success' ? 'green' : 'red'}-500 text-white px-4 py-2 text-center relative`}
        >
          <p className="mr-8">{message}</p>
          <button
            className="absolute top-1/2 transform -translate-y-1/2 right-2 text-white font-bold"
            onClick={handleClose}
          >
            X
          </button>
        </div>
      </div>
    )
  );
};

export default Alert;
