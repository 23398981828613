import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyPolicyCon from "./components/privacypolicycon";
import RefundPolicyCon from "./components/refundPolicyCon";
import ShippingPolicyCon from "./components/shippingpolicycon";
import TermsConditionCon from "./components/termsndcondition";
function ShippingPolicy() {
  return (
    <div className="App">
        <Header></Header>
        <ShippingPolicyCon ></ShippingPolicyCon>
        <Footer></Footer>
    </div>
  );
}

export default ShippingPolicy;
