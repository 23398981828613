import React from 'react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <svg
        className="animate-spin h-10 w-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <defs>
          <linearGradient id="gradient1" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#F56565" />
            <stop offset="45%" stopColor="#ED8936" />
            <stop offset="95%" stopColor="#ECC94B" />
          </linearGradient>
          <linearGradient id="gradient2" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#4299E1" />
            <stop offset="45%" stopColor="#38B2AC" />
            <stop offset="95%" stopColor="#48BB78" />
          </linearGradient>
        </defs>
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="url(#gradient1)"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="url(#gradient2)"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm10 0l3 1.647A7.962 7.962 0 0120 12h-4c0 3.042-1.135 5.824-3 7.938zM12 20a8 8 0 100-16 8 8 0 000 16z"
        ></path>
      </svg>
    </div>
  );
};

export default Loading;
