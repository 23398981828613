import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Button from 'react-bootstrap-button-loader';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

const AdminLogin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if(user.email == "footsypop.events@gmail.com"){
          setLoggedIn(true);
        }
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    setLoading(true);
    const auth = getAuth();
  
    // Sign in with email and password
    signInWithEmailAndPassword(auth, email, password)
      .then(async(userCredential) => {
        const user = userCredential.user;


        if (user) {
          try {
            const db = getFirestore();
            const userDataRef = doc(db, 'adminusers', user.uid);
            const userDataSnapshot = await getDoc(userDataRef);
      
            if (userDataSnapshot.exists()) {
              window.location.href = '/admin/home';
            } else {
              setError('You are not authorized to access the admin panel.');
              setLoading(false);            }
          } catch (error) {
            // Handle any errors that may occur during data retrieval
            console.error(error);
          }
        } else {
          setLoading(false);
          setError('Login failed: ' + error.message);  
        
        }
        
        // Check if the user has the specific email address
       
      })
      .catch((error) => {
        setLoading(false);
        setError('Login failed: ' + error.message);
      });
  };

  if (loggedIn) {
    window.location.href = '/admin/home';
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h2 className="text-2xl font-bold mb-4">Admin Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="mb-2 px-4 py-2 rounded-md"
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mb-2 px-4 py-2 rounded-md"
      />
      {error && <p className="text-red-500 mb-2">{error}</p>}
      <Button
         loading={loading}
        onClick={handleLogin}
        className="px-8 mb-10 py-4 bg-indigo-500 text-white rounded-lg font-semibold"
        >
        Login
      </Button>
    </div>
  );
};

export default AdminLogin;
