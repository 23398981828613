import Footer from "./components/Footer";
import Header from "./components/Header";
import SEO from "./components/Seo";
import Slider from "./components/Slider";
import ContactUsCont from "./components/contactcon";
function Contact() {
  return (
    <div className="App">
      <SEO
        title="Contact Us"
        description="Footsypop Events believe in working closely with all our clients. Contact us for more information, we are always here to answer any questions or concerns you may have."
        keywords="event organizers,birthday party organizers,event planning companies,wedding event planner,event decorator,corporate event planner,sports event management company,outdoor party planners,wedding reception planner"
      />
        <Header></Header>
        <Slider></Slider>
        <div className="text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 mt-10 sm:text-4xl">
            Contact Us
        </h2>
        </div>
        <ContactUsCont ></ContactUsCont>
        <Footer></Footer>
    </div>
  );
}

export default Contact;
