
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import ProductViewCard from "./PViewCard";


const SearchComp = () =>{
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState("");
    const [cartItemsNo, setCartItemsNo] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const inputRef = useRef(null);
  
  
    useEffect(() => {
      inputRef.current.focus();
    }, []);

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  
  
    const searchProducts = async (searchText) => {
      const firestore = getFirestore();
      const productsRef = collection(firestore, 'products');
  
      try {
        // Create a query to search for products based on the itemName field
        const nameQuery = where('name', '>=', capitalizeFirstLetter(searchText));
    
        // Combine the queries with the "OR" operator using the query function
        const combinedQuery = query(productsRef, nameQuery);
        const querySnapshot = await getDocs(combinedQuery);
        const productsData = querySnapshot.docs.map((doc) => { 
          const pd = {
            ...doc.data(),
            id: doc.id
          };
          return pd
        });
        setSearchResults(productsData);
      } catch (error) {
        console.error('Error searching products:', error);
      }
    };
  
    const handleSearch = (e) => {
      setSearchQuery(e.target.value)
      // Call the searchProducts function with the searchQuery as the argument
        searchProducts(searchQuery);
    };
  
  
    const handleGoBack = () => {
      history.goBack();// Go back in the history
    };
  


    return(
        <>
       <div className="fixed inset-0 bg-white z-50 overflow-y-auto flex flex-col ">

      {/* Header */}
      <div className="sticky top-0 bg-gray-900 w-full p-4 flex items-center z-50">
        <button onClick={handleGoBack}  className="focus:outline-none cursor-pointer">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-white"
            viewBox="0 0 21 22"
          >
            <path
              fill="currentColor"
              d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
            ></path>
          </svg>
        </button>
      
        <div className="px-2 w-full flex bg-gray-100 items-center rounded-xl border border-gray-900 relative ml-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-gray-400 absolute top-1/2 transform -translate-y-1/2 left-2 pointer-events-none"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="m16.86 15.607-2.783-2.76a6.75 6.75 0 1 0-1.042 1.042l2.76 2.76a.75.75 0 0 0 1.065 0 .75.75 0 0 0 0-1.042Zm-8.033-1.718a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 0 1 0 10.5Z"
              fill="#000"
              stroke="#000"
              strokeWidth="0.5"
            />
          </svg>
          <input
            ref={inputRef}
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            className="ml-4 px-4 py-2 bg-gray-100 focus:outline-none focus:border-blue-500 rounded-md"
            placeholder="Search..."
          />
        </div>

        
      </div>

      {/* Main Content */}
      <div className="flex-grow p-4 mt-5">
     

        {/* Search Results */}
        {searchResults.length > 0 ? (
          <ul className="z-40">
           {searchResults.map((product, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-1">
              <ProductViewCard
                imageUrl={product.downloadUrls[0]}
                name={product.name}
                price={product.price}
                id={product.id}
                op={product.originalPrice}
              />
            </div>
          ))}
          </ul>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>        
        
        
        </>
  
    )
}

export default SearchComp;
