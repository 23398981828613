import Footer from "./components/Footer";
import Header from "./components/Header";
import SEO from "./components/Seo";
import AboutUsCont from "./components/aboutcon";
function About() {
  return (
    <div className="App">
      <SEO
        title="About Us | Footsypop Events"
        description="We are a full-service Event planners in Bangalore. We specialize in a wide range of events, including wedding, corporate functions, birthday parties, cultural celebrations and many more."
        keywords="event planners in bangalore,party planners,wedding event management,decoration event management,birthday party planner,anniversary event planner,corporate event management companies,sports event management,dj event planner,concert management,outdoor event planning,catering event management"
      />
        <Header></Header>
        <AboutUsCont></AboutUsCont>
        <Footer></Footer>
    </div>
  );
}

export default About;
