import Footer from "./components/Footer";
import Header from "./components/Header";
import OrderSuccessMessage from "./components/OrderSuccessMessage"

const OrderComplete = () =>{

    return(

        <>
         <Header></Header>
         <OrderSuccessMessage></OrderSuccessMessage>
        <Footer></Footer>
        
        </>
    )
}

export default OrderComplete;