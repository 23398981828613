import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Baby Shower Decorations',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBaby%20Shower%2Fdbf8f784ebac1ac8.png?alt=media&token=52d7f7a7-dfe6-4e0e-ba61-ae6a2e5eae5f',
  },
  {
    title: 'Baby Shower Decorations',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBaby%20Shower%2FWhatsApp%20Image%202024-03-25%20at%2011.31.53_539e2336.jpg?alt=media&token=c53fcacd-3df9-44e9-a4eb-cd7f15769bb0',
  },
  {
    title: 'Baby Shower Decorations',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/originals/4c/cd/47/4ccd47920a4325cf48ad4b16689728f8.jpg',
  },
  {
    title: 'Baby Shower Decorations',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/originals/ee/f2/9f/eef29ffc6b38526fe233a2333a7dbbb3.jpg',
  },
]

function BabyShower() {
  return (
    <div className="App">
      <Helmet>
        <title>Baby Shower Decorations in Bangalore</title>
        <meta
          name="title"
          content="Baby Shower Decorations in Bangalore"
        />
        <meta
          name="description"
          content="Transform your baby shower into an unforgettable celebration with charming decorations! Welcome the little one in style by contacting footsypop events."
        />
        <meta
          property="og:title"
          content="Baby Shower Decorations in Bangalore"
        />
        <meta
          property="og:description"
          content="Transform your baby shower into an unforgettable celebration with charming decorations! Welcome the little one in style by contacting footsypop events."
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 px-2 font-bold mb-4">
          Baby Shower Decorations in Bangalore
        </h1>
      </div>
      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l font-semibold text-gray-600 leading-7">
          One of the most crucial aspects of planning a memorable baby shower is
          choosing the perfect decorations. In Bangalore, a vibrant city known
          for its rich culture and diverse community, finding the right baby
          shower decorations can truly make the event shine. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>
        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Baby Shower Decorations"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>
        <p class="text-l font-semibold text-gray-600 leading-7">
          Planning a baby shower is an exciting milestone in any expectant
          parent's journey. It's a time to celebrate the impending arrival of a
          new life and shower the parents-to-be with love and support. As
          seasoned experts in event planning and decoration, we specialize in
          crafting unforgettable experiences that capture the essence of your
          unique vision.
          <br />
          <br />
          From whimsical themes to elegant decor, we're dedicated to turning
          your baby shower dreams into reality. Join us on a journey of
          creativity, innovation, and above all, love, as we explore the
          ultimate guide to baby shower decorations in Bangalore.
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 pb-4 pt-4 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBaby%20Shower%2FWhatsApp%20Image%202024-03-26%20at%2009.53.24_526c859d.jpg?alt=media&token=524124c7-db3e-4c57-a1b2-0abe39617253"
              alt="Baby Shower Decorations"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              Elevate Your Baby Shower Experience with Footsypop Events
            </h2>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Experience and Expertise</h3>
              <p>
                At Footsypop Events, we understand the importance of creating a
                memorable and meaningful baby shower experience. With years of
                experience in the event planning industry, our team brings a
                wealth of knowledge and expertise to every celebration. From
                intimate gatherings to extravagant affairs, we have the skills
                and resources to turn your vision into reality.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Personalized Touch</h3>
              <p>
                We believe that every baby shower is unique, just like the
                parents-to-be themselves. That's why we offer personalized
                service tailored to your individual preferences and style.
                Whether you envision a whimsical garden party or a sophisticated
                soiree, we work closely with you to bring your dreams to life
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Attention to Detail</h3>
              <p>
                At Footsypop Events, we believe that it's the little details
                that make a big difference. From the moment you contact us until
                the last guest departs, we pay meticulous attention to every
                aspect of your baby shower decorations. Our goal is to ensure
                that every element is thoughtfully curated to create a cohesive
                and visually stunning experience.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Seamless Execution</h3>
              <p>
                On the day of your baby shower, you can relax and enjoy the
                celebration knowing that every detail is being taken care of by
                our experienced team. From set-up to tear-down, we handle every
                aspect of the event with professionalism and precision, allowing
                you to focus on making memories with your loved ones.
              </p>
            </div>
            <h2 class="text-3xl font-bold mb-5">
              Our Service for Baby Shower Decorations
            </h2>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Custom Theme Development for Baby Shower Decorations</h3>
              <p>
                Whether you're dreaming of a whimsical fairy tale theme or a
                chic modern aesthetic, our creative team can bring your vision
                to life. We work closely with you to develop a theme that
                reflects your personal style and sets the tone for your baby
                shower.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Venue Styling</h3>
              <p>
                Transform your chosen venue into a breathtaking space with our
                expert venue styling services. From elegant drapery to stunning
                floral arrangements, we create a cohesive look that wows your
                guests and sets the stage for an unforgettable celebration.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Customized Baby Shower Decorations
              </h3>
              <p>
                From personalized signage to custom favors, we offer a wide
                range of customizable decor options to add a special touch to
                your baby shower. Let us help you create a unique and memorable
                experience for you and your guests.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Customized Cake Decorations
              </h3>
              <p>
                Welcome to the world, little one! Celebrate the joyous arrival
                with our adorable specially designed cake, adorned with soft
                pastel colors, whimsical baby booties, and a sprinkle of love.
                We can customize the cake according to client requirements!
              </p>
            </div>
            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          As you embark on the journey of planning your baby shower in
          Bangalore, let Footsypop Events be your trusted partner every step of
          the way. From conceptualization to execution, our experienced team is
          here to ensure that your baby shower decorations surpass your
          expectations and create unforgettable memories for you and your loved
          ones. With our personalized service, attention to detail, and creative
          vision, we will transform your dreams into reality and curate a baby
          shower experience that is truly one-of-a-kind.
          <br />
          <br />
          Let us handle the logistics and intricacies of event planning while
          you focus on savoring every moment of this joyous occasion. Contact
          Footsypop Events today to schedule a consultation and let us help you
          create a baby shower that will be cherished for years to come. Your
          journey towards a magical celebration starts here.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default BabyShower
