import React from 'react';
import { GoogleLoginButton } from './GoogleLogin';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig);
const MobileLoginOverlay = ({ setLoginOpen }) => {

  const handleLogin = () => {
    console.log("Hello");
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Sign in with Google popup
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoginOpen(false);
        // Redirect to admin home after successful login
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });
  };



  return (
    <div className="fixed inset-0 bg-gray-900 z-50 flex flex-col justify-center items-center">
      {/* Back button */}
      <button
        onClick={() => setLoginOpen(false)}
        className="absolute top-0 left-0 p-4 focus:outline-none cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-white"
          viewBox="0 0 21 22"
        >
          <path
            fill="currentColor"
            d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
          ></path>
        </svg>
      </button>

      {/* Footsypop logo */}
      <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/website%2FFootsypop-Events-logo-light.png?alt=media&token=3a81f8a6-bddb-4f83-91e9-da781c4985f7"
          className="h-10"
          alt="Footsypop Logo"
        />
      </div>

      {/* Card */}
      <div className="fixed inset-x-8 flex justify-center items-center">
        <div className="bg-white rounded-lg p-10 w-full max-w-md">
          <h1 className=" font-bold text-center mb-4 select-none">Login / Signup</h1>
          <div className="h-0.5 bg-gray-300 my-4"></div>
          {/* Additional login form elements go here */}
          <GoogleLoginButton onClick={handleLogin}  ></GoogleLoginButton>
        </div>
      </div>
      
    </div>
  );
};

export default MobileLoginOverlay;
