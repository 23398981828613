import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Birthday planners in bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2Fringbaby.jpg?alt=media&token=b0406cf5-68bf-4744-b045-19d6b3440e98&w=240&h=240',
  },
  {
    title: 'Birthday planners in bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FWhatsApp%20Image%202023-07-20%20at%209.32.25%20AM%20(1).jpeg?alt=media&token=05eb9f93-ae0f-432d-a874-358463868984&w=240&h=240',
  },
  {
    title: 'Birthday planners in bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2Fgoldgrenn.jpg?alt=media&token=e6a65698-5e64-4434-98fd-64c364d0aa3e&w=240&h=240',
  },
  {
    title: 'Birthday planners in bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2Funicorn3.jpg?alt=media&token=3aa98638-93db-413a-987f-e61cb54d2d90&w=240&h=240',
  },
]

function BirthdayPlanners() {
  return (
    <div className="App">
      <Helmet>
        <title>Birthday Planners in Bangalore | Footsypop Events</title>
        <meta
          name="title"
          content="Birthday Planners in Bangalore | Footsypop Events"
        />
        <meta
          name="description"
          content="As the leading birthday planners in Bangalore, FootsyPop Events has established itself as the go-to choice for stress-free & creative decorations."
        />
        <meta
          property="og:title"
          content="Birthday Planners in Bangalore | Footsypop Events"
        />
        <meta
          property="og:description"
          content="As the leading birthday planners in Bangalore, FootsyPop Events has established itself as the go-to choice for stress-free & creative decorations."
        />
        <meta
          property="og:url"
          content="https://www.footsypopevents.com/BirthdayPlanners"
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold">
          Birthday Planners in Bangalore
        </h1>
      </div>
      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l mb-5 font-semibold text-gray-600 leading-7">
          As the leading birthday planners in Bangalore, FootsyPop Events has
          established itself as the go-to choice for those seeking a stress-free
          and creatively curated birthday decorations. Whether you're planning a
          large-scale event or an intimate gathering, relying on the expertise
          of FootsyPop Events is the key to making every birthday a cherished
          memory. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>
        <br />
        <p class="text-l font-semibold text-gray-600 leading-7">
          In the bustling city, where every moment counts,
          selecting a birthday planner in bangalore like FootsyPop Events becomes not just a
          practical but a popular choice. These professionals seamlessly
          streamline the entire planning process, saving you from the hassle and
          stress associated with organizing an event. From selecting the perfect
          theme to coordinating decorations, catering, and entertainment,
          FootsyPop Events, as the top birthday planners in Bangalore, ensures
          every detail is meticulously handled, allowing you to focus on
          enjoying the celebration and leaving the birthday decorations part to
          us.
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Birthday planners in bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l font-semibold text-gray-600 leading-7">
          Our dedicated team of experienced birthday planners is here to take
          care of every aspect of your celebration, from conceptualization to
          flawless execution. We work closely with you to understand your
          vision, preferences, and aspirations, ensuring that your birthday
          party reflects your style and leaves a lasting impression on both you
          and your guests. When you choose Footsypop as your birthday planners
          in bangalore, you can expect meticulous attention to detail, creative
          ideas, and personalized service. We take pride in our ability to
          transform any venue into a captivating space that perfectly captures
          the essence of your celebration. Whether you desire an elegant and
          sophisticated affair, a whimsical themed extravaganza, or a
          high-energy party filled with entertainment, our team will bring your
          vision to life.
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBirthdayPlanners%2FWhatsApp%20Image%202024-03-04%20at%2012.25.54_3d2a7175.jpg?alt=media&token=5b9812c1-3f1a-4599-84f7-11383c9ab6bb&w=240&h=240"
              alt="Birthday planners in bangalore"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              Why Choose Footsypop Events as birthday planners in bangalore:
            </h2>
            <p class="text-lg mb-5">
              When it comes to services, FootsyPop Events sets the bar high as
              the top birthday planners in Bangalore. They offer a comprehensive
              suite of services, ensuring a seamless and enjoyable celebration.
              From helping you choose the perfect theme to managing catering and
              entertainment, their experts, recognized as the top birthday
              planners in Bangalore, handle every detail, providing a
              personalized touch that sets your celebration apart.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                How to Choose the Right Planner for Your Birthday planners in
                Bangalore?
              </h3>
              <p>
                Choosing the right birthday planner involves various
                considerations, such as budget, event scale, and specific
                requirements. Reviews and recommendations play a crucial role in
                making an informed decision, and FootsyPop Events, recognized as
                the top birthday planners in Bangalore, stands out in this
                aspect with a track record of satisfied clients and positive
                feedback.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Professional Planning vs. Self-Organizing in Birthday
                Decorations:
              </h3>
              <p>
                In considering the pros and cons of professional planning versus
                self-organizing, FootsyPop Events, the top birthday planners in
                Bangalore, exemplifies the advantages of professional planning.
                Their expertise ensures a stress-free experience, leaving you to
                enjoy the celebration without worrying about the intricate
                details.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Unique Birthday Party or Decoration Ideas in Bangalore
              </h3>
              <p>
                Explore trendy themes and innovative concepts to elevate your
                celebration, with FootsyPop Events, the premier birthday
                planners in Bangalore, leading the way in bringing fresh and
                exciting options. They specialize in turning ordinary birthdays
                into extraordinary experiences, making them the go-to choice for
                birthday planners in Bangalore.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Ensuring a Smooth Event Day with Birthday Planners in Bangalore
              </h3>
              <p>
                Effective communication with FootsyPop Events, the top birthday
                planners in Bangalore, ensures a smooth event day. Learn how to
                coordinate with their planners and establish contingency plans
                for unforeseen circumstances, ensuring your celebration is as
                seamless as possible in Bangalore.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                New Trends in Birthday Celebrations with Birthday Planners in
                Bangalore
              </h3>
              <p>
                In the dynamic landscape of birthday planning, staying updated
                on the latest trends is crucial for creating an event that
                stands out. FootsyPop Events, the top birthday planners in
                Bangalore, prides itself on being at the forefront of these
                trends, offering clients access to the most innovative and
                stylish ideas. From interactive digital invitations to immersive
                theme experiences, discover how staying in tune with new trends
                can elevate your celebration to new heights in Bangalore.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                On-site Coordination by birthday planners in bangalore:
              </h3>
              <p>
                On the day of your birthday party, our dedicated event
                coordinators will be present to oversee every aspect of the
                event, ensuring a smooth flow and handling any last-minute
                details or adjustments. This allows you to relax and fully enjoy
                the festivities while we take care of the logistics.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Event Production and Logistics - birthday planners in bangalore:
              </h3>
              <p>
                Planning and executing a birthday party involves handling
                numerous logistics. Footsypop takes care of all the event
                production aspects, including audiovisual setup, stage design,
                seating arrangements, and coordination with vendors. Our goal is
                to ensure a seamless and stress-free experience for you and your
                guests.
              </p>
            </div>
            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <p class="text-l font-semibold text-gray-600 leading-7">
          Make Every Birthday Unforgettable with Birthday Planners in Bangalore
          - FootsyPop Events In conclusion, the trend of hiring birthday
          planners in Bangalore is not just a convenience but a necessity for
          those seeking a stress-free, creatively curated celebration. FootsyPop
          Events, recognized as the top birthday planners in Bangalore, offers
          unmatched expertise, turning dreams into reality. Whether it's a
          large-scale event or an intimate gathering, the services provided
          ensure that every birthday celebration becomes a cherished memory.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default BirthdayPlanners
