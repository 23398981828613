import React from 'react';
import 'tailwindcss/tailwind.css';

const reasons = [
  {
    title: 'Unmatched Expertise',
    description: '15+ Years of Event Mastery, Tailored Experiences. With years of experience in the industry, our team brings unparalleled expertise to every project. From intimate corporate gatherings to large-scale conferences and exhibitions, we have the knowledge and skills to turn your vision into reality.',
    color: 'bg-blue-200',
  },
  {
    title: 'Proven Track Record',
    description: 'Worked with 40+ reputed companies. Over the years, we have built a reputation for excellence in the industry, earning the trust and loyalty of clients across Bangalore. Our long list of satisfied clients and successful events speaks volumes about our commitment to delivering results.',
    color: 'bg-green-200',
  },
  {
    title: 'Tailored Solutions',
    description: 'We understand that every event is unique, which is why we offer customized solutions tailored to meet your specific requirements and objectives. Whether you need assistance with venue selection, event branding, logistics management, or digital marketing, we have you covered.',
    color: 'bg-purple-200',
  },
  {
    title: 'Attention to Detail',
    description: 'At Footsypop Events, we believe that success lies in the details. From the initial planning stages to the final execution, we pay meticulous attention to every aspect of your event to ensure flawless execution and exceed your expectations.',
    color: 'bg-yellow-200',
  },
  {
    title: 'Seamless Execution',
    description: 'Our streamlined approach to event management ensures seamless execution from start to finish. With a dedicated team overseeing every aspect of your event, you can trust us to handle all the logistics, leaving you free to focus on what matters most – enjoying the event.',
    color: 'bg-pink-200',
  },
  {
    title: 'Exceptional Customer Service',
    description: 'Customer satisfaction is our top priority, and we go above and beyond to ensure every client receives the highest level of service. From the moment you contact us to the final post-event wrap-up, we are committed to providing a stress-free and enjoyable experience.',
    color: 'bg-orange-200',
  },
 
];

const WhyChooseUs = () => {
  return (
    <div className="max-w-7xl mx-auto p-6 bg-white">
      <h1 className="text-4xl font-bold mb-4 text-indigo-600">
        Why Choose Footsypop Events
      </h1>
      <div className="w-16 h-1 bg-indigo-600 mb-6"></div>
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        The Best Event Management Company in Bangalore
      </h2>
      <p className="text-lg font-medium text-gray-700 leading-relaxed mb-8">
        When it comes to planning and executing exceptional events, Footsypop Events stands out as the premier choice in Bangalore. As the leading event management company in the region, we pride ourselves on our unparalleled expertise, attention to detail, and commitment to delivering memorable experiences for our clients. Here’s why you should choose us for all your event management needs:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {reasons.map((reason, index) => (
          <div key={index} className={`p-6 border rounded-lg shadow-lg ${reason.color}`}>
            <div className="mb-4">
              <div className="h-16 w-16 flex items-center justify-center rounded-full bg-white text-gray-800 text-2xl font-bold">
                {index + 1}
              </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">{reason.title}</h3>
            <p className="text-gray-700">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
