import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductNavigation from "../components/ProductNavigation";
import Slider from "../components/Slider";
import ProductList from "./ProductList";




function KidsBirthdays() {
  return (
    <div className="App">
        <Header></Header>
        {/* <Slider></Slider> */}
        <ProductNavigation category={"KidsBirthday"}></ProductNavigation>
        <ProductList name={"Kids Birthday"}></ProductList>
        <Footer></Footer>
    </div>
  );
}

export default KidsBirthdays;
