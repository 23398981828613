import { useEffect, useState } from 'react'
import CatComponent from './components/CatComponent'
import Catogory from './components/Catogory'
import ClientList from './components/ClientList'
import Footer from './components/Footer'
import Header from './components/Header'
import MobileLoginOverlay from './components/MobileLoginOverlay'
import Slider from './components/Slider'
import TestiList from './components/TestiList'
import CardRow from './components/verticalProducts'
import MobileCartView from './components/MobileCartView'
import SEO from './components/Seo'
import FloatingCallButton from './components/FloatingCall'
import CategoryScroll from './components/CatComp'
import ServicesOffered from './components/ServiceOffered'
import IntroComponent from './components/IntroComponent'
import WhyChooseUs from './components/Expertise'
import ExpertiseStats from './components/ExpetiseSlot'
import ContactUsCont from './components/contactcon'
import GoogleReviewComponent from './components/GoogleReviews'
function Home() {
  const [isLoginOpen, setLoginOpen] = useState(false)
  const [isCartOpen, setCartOpen] = useState(false)
  const [cardRowsInView, setCardRowsInView] = useState([])

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // If the component is in view, add its category to the array
        setCardRowsInView((prevInView) => [
          ...prevInView,
          entry.target.dataset.category,
        ])
      }
    })
  }

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5, // When 50% of the component is in view
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    // Observe each CardRow element
    const cardRowElements = document.querySelectorAll('.lazy-card-row')

    cardRowElements.forEach((element) => {
      observer.observe(element)
    })

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect()
  }, [])

  return (
    <div className="App">
      <SEO
        title="Event Planners in Bangalore"
        description="Elevate your events with best event planners in Bangalore. Browse from Birthday decorations, housewarming, wedding, baby shower and many more!"
      />
      {isCartOpen && (
        <MobileCartView setCartOpen={setCartOpen}></MobileCartView>
      )}
      <Header></Header>
      <Slider></Slider>
      {/* <CategoryScroll></CategoryScroll> */}
      {/* <Catogory></Catogory> */}

      <IntroComponent></IntroComponent>
      <ServicesOffered></ServicesOffered>
      {/* <CatComponent></CatComponent> */}

      {/* <CatComponent></CatComponent> */}
      <FloatingCallButton></FloatingCallButton>
      <ExpertiseStats></ExpertiseStats>


      {/* <CardRow
        cat={'Birthdays'}
        link={'/products/Birthdays'}
        className={
          cardRowsInView.includes('Birthdays') ? 'lazy-card-row' : 'hidden'
        }
      ></CardRow>
      <CardRow
        cat={'Kids Birthday'}
        link={'/products/KidsBirthday'}
        className={
          cardRowsInView.includes('Kids Birthday') ? 'lazy-card-row' : 'hidden'
        }
      ></CardRow>
      <CardRow
        cat={'Naming Ceromony'}
        link={'/products/NamingCermony'}
        className={
          cardRowsInView.includes('NamingCeromony')
            ? 'lazy-card-row'
            : 'hidden'
        }
      ></CardRow> */}
      {/* <CardRow cat={"Birthdays"}></CardRow>
      <CardRow cat={"Kids Birthday"}></CardRow> */}
      {/* <CardRow cat={"Decor"} from={41} to={50}></CardRow> */}
      {/* <TestiList></TestiList> */}
      <WhyChooseUs></WhyChooseUs>
      <ClientList></ClientList>
      <GoogleReviewComponent></GoogleReviewComponent>
      <ContactUsCont ></ContactUsCont>

      <Footer></Footer>
    </div>
  )
}

export default Home
