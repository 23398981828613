import React from 'react';
import { getAuth, signOut } from 'firebase/auth';

const AdminHeader = ({ setMenuOpen, menu }) => {
  
  const handleLogout = () => {
    const auth = getAuth();

    // Sign out the user
    signOut(auth)
      .then(() => {
        window.location.href = '/admin';
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });


  };

  const hs = () =>{
    setMenuOpen(true)
    console.log(menu)
  }



  return (
    <header className="flex items-center justify-between bg-white px-6 py-3 shadow">
      <div className="flex items-center">
        <button className="text-3xl sm:hidden mr-2" onClick={() => hs()}>
          ☰
        </button>
        <h1 className="text-xl font-bold text-gray-800">Footsypop Admin Panel</h1>
      </div>
      <div className="flex items-center">
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Logout
        </button>
      </div>
    </header>
  );
};

export default AdminHeader;
