import React, { useEffect, useRef } from 'react';

const SidePanel = ({Role, menu, setMenuOpen }) => {
  console.log(Role)
  const panelRef = useRef();

  const closeMenu = (e) => {
    if (menu && panelRef.current && !panelRef.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  

  return (
<>

{menu && (
        <div
          className="fixed z-40 inset-0  bg-black opacity-50"
          onClick={()=> setMenuOpen(false)}
        ></div>
      )}


        <div
      ref={panelRef}
      className={`fixed top-0 left-0 bg-transparent z-50 w-56 h-screen overflow-y-auto flex flex-col ${
        menu ? 'translate-x-0' : '-translate-x-full'
      } sm:translate-x-0 transition-transform ease-in-out duration-300`}
    >
      <nav className="bg-gray-800 text-white flex-grow">
        <ul className="py-4">
          <li className="pl-6 py-3 text-lg font-bold">
            <a href="/admin/home" onClick={() => setMenuOpen(false)}>Dashboard</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/home" onClick={() => setMenuOpen(false)}>Home</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/products" onClick={() => setMenuOpen(false)}>Products</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/orders" onClick={() => setMenuOpen(false)}>Orders</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/leadstracker" onClick={() => setMenuOpen(false)}>Leads-Tracker</a>
          </li>
          {Role=="admin"&&
          <>
           <li className="pl-6 py-3">
            <a href="/admin/slider" onClick={() => setMenuOpen(false)}>Slider</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/Customer" onClick={() => setMenuOpen(false)}>Customer</a>
          </li>
          <li className="pl-6 py-3">
            <a href="/admin/QuickAccess" onClick={() => setMenuOpen(false)}>QuickAccess</a>
          </li>      
            <li className="pl-6 py-3">
            <a href="/admin/usermanagement" onClick={() => setMenuOpen(false)}>User Management</a>
          </li>
          </>    
          }
          
        
        </ul>
      </nav>  
      <div className="flex-shrink-0 mt-auto bg-gray-800 px-6 py-3">
        <p className="text-sm text-white">&copy; 2023 Footsypop Events</p>
      </div>
    </div>




</>

  
    
    );
  };
  
  export default SidePanel;