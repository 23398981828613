import { deleteDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import { useState } from "react";

const OrderModal = ({ orderData, closeModal, currentYear, currentMonth, id }) => {
  const [selectedStatus, setSelectedStatus] = useState(orderData.status);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    updateStatusInFirestore(newStatus);
  };

  const handleDeleteOrder = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    if (confirmDelete) {
      try {
        const db = getFirestore();
        const orderRef = doc(db, 'orders', currentYear ,currentMonth, id);
        console.log('Order deleted successfully');
        closeModal();
      } catch (error) {
        console.error('Error deleting order:', error);
      }
    }
  };

  const updateStatusInFirestore = async (newStatus) => {
    const db = getFirestore();
    const orderRef = doc(db, 'orders', currentYear ,currentMonth, id);

    try {
      await updateDoc(orderRef, { status: newStatus });
      console.log('Status updated successfully in Firestore');
    } catch (error) {
      console.error('Error updating status in Firestore:', error);
    }
  };

    const renderTableCell = (label, value) => {
      return (
        <tr>
          <td className="py-2 pr-4">{label}</td>
          <td className="py-2">{value || 'NA'}</td>
        </tr>
      );
    };
  
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
        <div className="bg-white rounded-lg p-8 max-w-screen-lg relative">
          <h2 className="text-2xl font-bold mb-6">Order Details</h2>
          <div className="overflow-y-auto max-h-96">
            <table className="w-full mb-6">
              <tbody>
                {renderTableCell('Name', orderData.name)}
                {renderTableCell('Address', orderData.address)}
                {renderTableCell('Phone Number', orderData.phoneNumber)}
                {renderTableCell('City', orderData.city)}
                {renderTableCell('Alternate Number', orderData.alternateNumber)}
                {renderTableCell('Apartment', orderData.apartment)}
                {renderTableCell('Postal Code', orderData.postalCode)}
                {renderTableCell('Selected Payment Method', orderData.selectedPaymentMethod)}
                {renderTableCell('Selected Time Slot', orderData.selectedTimeSlot)}
                <tr>
                  <td className="py-2 pr-4">Status</td>
                  <td className="py-2">
                    <select value={selectedStatus} onChange={handleStatusChange}>
                      <option value="pending">Pending</option>
                      <option value="eventConfirmed">Event Confirmed</option>
                      <option value="canceled">Canceled</option>
                      <option value="completed">Completed</option>
                    </select>
                  </td>
              </tr>                {renderTableCell('User Email', orderData.userEmail)}
                {renderTableCell('Order ID', orderData.orderId)}
                {renderTableCell('Order Key', orderData.orderKey)}
                <tr>
                  <td className="py-2 pr-4">Product Image</td>
                  <td className="py-2">
                    {orderData.productImage ? (
                      <img src={orderData.productImage} alt="Product" className="w-24 h-24" />
                    ) : (
                      'NA'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 pr-4">Product Price</td>
                  <td className="py-2">{orderData.productPrice || 'NA'}</td>
                </tr>
                <tr>
                  <td className="py-2 pr-4">Product Name</td>
                  <td className="py-2">{orderData.productName || 'NA'}</td>
                </tr>
              </tbody>
            </table>
          </div>
         

          <div className="flex justify-between">
          <button
            className="bg-red-500 mt-4 hover:bg-red-600 text-white px-6 py-3 border rounded"
            onClick={handleDeleteOrder}
          >
            Delete Order
          </button>
          <button
            className="bg-blue-500 mt-4 hover:bg-blue-600 text-white px-6 py-3 border rounded"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
        </div>
      </div>
    );
  };
  
  export default OrderModal;
  