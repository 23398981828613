import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import NotFound from '../NotFound';
import Loading from './Loading';

const MyOrders = ({ isLoggedIn , userId }) => {
  const [orders, setOrders] = useState([]);



  useEffect(() => {
    const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2,"0"); 
    if (isLoggedIn) {
      const firestore = getFirestore(); // Assuming you have initialized Firebase Firestore
      const myOrderRef = collection(firestore, 'orders', currentYear.toString(), currentMonth);

      const fetchOrders = async () => {
        try {
          // Use 'query' to add a filter for 'userId' field
          const q = query(myOrderRef, where('userId', '==', userId));
          const querySnapshot = await getDocs(q);
          const ordersData = querySnapshot.docs.map((doc) => doc.data());
          setOrders(ordersData);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      };

      fetchOrders();
    }
  }, [isLoggedIn, userId]);

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };


  if (!isLoggedIn) {
    return <> <Loading></Loading></>
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">My Events</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {orders.map((order) => (
          <div key={order.orderId} className="bg-white shadow-md p-4 rounded-lg">
            <img
              src={order.productImage}
              alt={`Order ${order.orderId}`}
              className="h-60 md:h-80 lg:h-96 w-full object-cover mb-2 rounded-lg"
            />
            <h3 className="text-lg font-semibold mb-2">Order #{order.orderId}</h3>
            <p className="text-gray-600 mb-2">Product Name: {order.productName}</p>
            <p className="text-gray-600 mb-2">Total: ₹{order.productPrice}</p>
            <p className="text-gray-600 mb-2">Date: {formatDate(order.eventDate)}</p>
            <p className="text-gray-600 mb-2">Time Slot: {order.selectedTimeSlot}</p>
            <p className="text-gray-600 mb-2" >Address: {order.apartment} {order.address} {order.city} {order.postalCode}</p>

            <p className="text-gray-600">Status: {order.status}</p>
            {/* <p className="text-gray-600">Name: {order.name}</p>
            <p className="text-gray-600">Phone Number: {order.phoneNumber}</p> */}



          </div>
        ))}
      </div>
    </div>
  );
};

export default MyOrders;
