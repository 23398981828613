import React, { useState, useEffect } from 'react';
import AddCustomerModal from './Components/AddCustomerModal';
import SidePanel from './Components/SidePanel';
import AdminHeader from './Components/Header';
import DatePicker from 'react-datepicker'; // Import the date picker component
import 'react-datepicker/dist/react-datepicker.css'; // Import date picker styles
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ViewCustomerModal from './Components/ViewCustomerModal';

const CustomerData = () => {
  const [customers, setCustomers] = useState([]);
  const [isAddCustomerOpen, setAddCustomerOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const [Role, setRole] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedCustomer,setSelectedCustomer] = useState();
  const [viewCustomerOpen,setViewCustomerOpen] = useState();
  const [monthRevenue,setMonthRevenue] = useState(0);
  const [monthProfit,setMonthProfit] = useState(0);
  


  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);

          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role);
            if (userData.role === "admin") {
              console.log(userData.role);
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const fetchCustomers = async (year, month) => {
      try {
        const db = getFirestore();
  
        // Construct the Firestore collection reference based on the selected year and month
        const customerCollectionRef = collection(db, `customers/${year}/${month}`);
  
        // Fetch the customer data
        const querySnapshot = await getDocs(customerCollectionRef);
  
        const customerData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          let totalCost = 0;
          let totalProfit =0;
          data.customerData.forEach((e)=>{
            totalCost = totalCost+e.totalCost;
            totalProfit = totalProfit+e.serviceProfit;
          })
          // Add customer data to the array
         

          customerData.push({
            name: data.customerName,
            data: data.customerData,
            totalCost: totalCost,
            totalProfit: totalProfit
            // Add other fields as needed
          });
        });

        
  
        console.log(customerData)
        let p =0,r =0 ;
        customerData.forEach((c)=>{
          p= p+c.totalProfit;
          r=r+c.totalCost;
        })
        setMonthProfit(p);
        setMonthRevenue(r);
        // Set the customer data in the state
        setCustomers(customerData);

      } catch (error) {
        // Handle any errors that may occur during data retrieval
        console.error('Error fetching customer data:', error);
      }
    };
  
    // Call the fetchCustomers function with the selected year and month
    fetchCustomers(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);


  function formatIndianPrice(price) {
    // Check if the input is a valid number
    if (isNaN(price)) {
      return 'Invalid Price';
    }
  
    // Convert the number to Indian formatted string
    const formattedPrice = price.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
    });
  
    return formattedPrice;
  }

  const addCustomer = (customer) => {
    // Add a new customer to the database and update the state
    // Example:
    // addCustomerToDatabase(customer).then(() => {
    //   setCustomers([...customers, customer]);
    // });
  };

  const openAddCustomerModal = () => {
    setAddCustomerOpen(true);
  };

  const handleDeleteCustomer = (index) => {
    // Handle the deletion of a customer
    // Example:
    // deleteCustomerFromDatabase(customers[index]).then(() => {
    //   const updatedCustomers = [...customers];
    //   updatedCustomers.splice(index, 1);
    //   setCustomers(updatedCustomers);
    // });
  };


  
  const openViewModal = (customer) => {
    console.log(customer)
    setSelectedCustomer(customer)
    setViewCustomerOpen(true)
    // Add code here to open your edit modal or form
    // You can create a separate EditLeadModal component
  };
  

  const handleDateChange = (date) => {
    // Update the selectedYear and selectedMonth based on the date picker selection
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    setSelectedYear(year);
    setSelectedMonth(month);
  };

  return (
    <>
      <div>
        <SidePanel Role={Role} menu={menu} setMenuOpen={setMenu} />
        <div className="flex-1 xl:ml-56">
          <AdminHeader setMenuOpen={setMenu} menu={menu} />

          {viewCustomerOpen && (
        <ViewCustomerModal
        customer={selectedCustomer}
          onClose={setViewCustomerOpen}
        />)}

          <h2 className="text-2xl ml-5 font-bold mb-4">Customer Data</h2>
          <div className=" flex ml-10 mb-10 gap-3 mb-2 md:mb-0">
            <DatePicker
              selected={new Date(selectedYear, selectedMonth - 1, 1)}
              onChange={handleDateChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="px-2 py-1 border rounded w-full"
            />

          <h1> Month Profit : {formatIndianPrice(monthProfit)}</h1>
          <h1> Month Revenue : {formatIndianPrice(monthRevenue)}</h1>
          </div>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                  Customer Name
                </th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                  Total Cost
                </th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                  Total Profit
                </th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={index}>
                  <td className="px-6 py-3 border">{customer.name}</td>
                  <td className="px-6 py-3 border">{formatIndianPrice(customer.totalCost)}</td>
                  <td className="px-6 py-3 border">{formatIndianPrice(customer.totalProfit)}</td>
                  <td className="px-6 py-3 border">
                   
                    <button
                          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-2"
                          onClick={() => openViewModal(customer)}
                        >
                          View
                        </button>
                    {/* You can add an "Edit" button here to edit customer details */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isAddCustomerOpen && (
            <AddCustomerModal onClose={setAddCustomerOpen} />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerData;
