import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { useState } from "react";

const CreateUser = ({ isOpen, setClose}) => {


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [role, setRole] = useState("employee");


    const handleCreateUser = () => {
        const auth = getAuth();
    
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const user = userCredential.user;
            const authUserId = user.uid;
    
            const db = getFirestore();
            const usersCollection = collection(db, "adminusers");
    
            const userData = {
              name: name,
              email: email,
              role: role,
            };
    
            setDoc(doc(usersCollection, authUserId), userData)
              .then(() => {
                // After successfully creating the user and storing data in Firestore, you can handle success (e.g., show a success message).
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // Handle the error (e.g., display an error message to the user).
              });
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // Handle the error (e.g., display an error message to the user).
          });

          setClose();
      };

    return(

        <>
         {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay fixed inset-0 bg-black opacity-50" />
                    <div className="modal absolute bg-white p-6 rounded-lg shadow-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 max-h-[80vh] overflow-y-auto">
                    <button
                            onClick={()=> setClose(false)}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        >
                            Close
                        </button>

                             

<div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg">
<h2 className="text-2xl font-semibold mb-4">Create User</h2>
<div className="mb-4">
  <label className="block mb-2">Name:</label>
  <input
    type="text"
    value={name}
    onChange={(e) => setName(e.target.value)}
    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:border-blue-500"
  />
</div>
<div className="mb-4">
  <label className="block mb-2">Email:</label>
  <input
    type="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:border-blue-500"
  />
</div>
<div className="mb-4">
  <label className="block mb-2">Password:</label>
  <input
    type="password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:border-blue-500"
  />
</div>
<div className="mb-4">
  <label className="block mb-2">Role:</label>
  <select
    value={role}
    onChange={(e) => setRole(e.target.value)}
    className="w-full px-4 py-2 rounded-lg border focus:outline-none focus:border-blue-500"
  >
    <option value="employee">Employee</option>
    <option value="admin">Admin</option>
  </select>
</div>
<button
  onClick={handleCreateUser}
  className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover-bg-blue-600"
>
  Create User
</button>
</div>
                     
                     
                      </div>
                      </div>
                  )   }

        
        
        
        </>
    )
}

export default CreateUser;
