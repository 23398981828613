import React, { useState } from "react";
import BookingModal from "./components/BookingModal";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProductImage from "./components/ProductImage";
import Reviews from "./components/Reviews";
import SimilarProducts from "./components/SimilarProducts";
import Slider from "./components/Slider";
import CardRow from "./components/verticalProducts";
import ReviewModal from "./components/ReviewModal";
import Alert from "./components/Alert";
import ProductNavigation from "./components/ProductNavigation";

function Productpage({ match }) {
  const [bookingshow, setBookingShow] = useState(false);
  const [showReviewModal, setReviewModal] = useState(false);
  const [ReviewSuccess, setReviewSuccess] = useState(false);
  const [ReviewFailure, setReviewFailure] = useState(false);
  const [productN, setProductN] = useState("");
  const [productCategory, setProductCategory] = useState("");



  const productName = match.params.productName;

  return (
    <div className="App">
      <Header></Header>
      {/* <Slider></Slider> */}
      <ProductNavigation category={productCategory}  productName={productN} ></ProductNavigation>
      {bookingshow && <BookingModal isOpen={bookingshow} onClose={() => setBookingShow(false)} productKey={productName} onSubmit={(zipCode, phoneNumber, eventDate, eventTime) => {
        // Handle the form submission here
      }} />}
      <ProductImage productN={setProductN} productCategory={setProductCategory} booking={() => setBookingShow(true)} productKey={productName}></ProductImage>
      <Reviews setReviewModal={setReviewModal} productKey={productName} ></Reviews>
     
      {ReviewSuccess && 
        <Alert type="success" message="Review submitted successful" />
       }

       {ReviewFailure &&
        <Alert type="failure" message="Error submitting review" />
        }
      <ReviewModal showReviewModal={showReviewModal} setReviewSuccess={setReviewSuccess} setReviewFailure={setReviewFailure} productKey={productName} setReviewModal={setReviewModal}></ReviewModal>
      <SimilarProducts productsKey={productName}></SimilarProducts>
      <Footer></Footer>
    </div>
  );
}

export default Productpage;