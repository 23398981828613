import React, { useState } from 'react';
import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  query,
  where,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import { useEffect } from 'react';

const AddLeadModal = ({ isOpen, onRequestClose }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    event: '',
    leadSource: '',
    phone: '',
    assignee: '',
    status: '',
    eventDate: '',
    comments: [],
    newComment: '',
  });
  const [assigneesData,setAssigneeData] = useState([]);



  
  useEffect(() => {
    fetchAssignees();
  }, []);

  const fetchAssignees = () => {
    const db = getFirestore();
    const assigneesCollectionRef = collection(db, 'assignees');
  
    onSnapshot(assigneesCollectionRef, (snapshot) => {
      const assigneesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      // Update your state or assignees list with the fetched data
      setAssigneeData(assigneesData);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "eventDate") {
      console.log(value)
      const dateParts = value.split('-'); // Split the date string
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) ; // Month is 0-based
      const day = parseInt(dateParts[2], 10);
      console.log(year)
      console.log(month)
      console.log(day)
    
      const timestamp = new Date(year, month-1, day).getTime(); // Convert to timestamp
      console.log(timestamp)
      const timestampObj = Timestamp.fromMillis(timestamp);

    
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        timestamp: timestampObj
      }));
    }else{
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  };

 

  const handleAddComment = () => {
    const { newComment } = formData;
    if (newComment.trim() !== '') {
      setFormData((prevData) => ({
        ...prevData,
        comments: [...prevData.comments, newComment],
        newComment: '',
      }));
    }
  };

  const onAddLead = async (leadData) => {
    try {
      // Get the current date
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Convert to two-digit format
  
      // Get a Firestore instance
      const db = getFirestore();
  
      // Create a reference to the leads collection for the current year and month
      const leadsRef = collection(db, `leads/${currentYear}/All`);
  
      // Add the lead data to Firestore
      await addDoc(leadsRef, leadData);
  
      console.log('Lead added successfully!');
      setIsSuccess(true);
  
      // Reset the success message after a few seconds (optional)
      setTimeout(() => setIsSuccess(false), 3000);
    } catch (error) {
      // Handle errors, show an error message or perform error handling
      console.error('Error adding lead:', error);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddLead(formData);
    setFormData({
      name: '',
      event: '',
      leadSource: '',
      phone: '',
      assignee: '',
      status: '',
      eventDate: '',
      comments: [],
      newComment: '',
    });
    onRequestClose();
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed inset-0 bg-black opacity-50" />
          <div className="modal absolute bg-white p-6 rounded-lg shadow-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 max-h-[80vh] overflow-y-auto">
            <button
              onClick={onRequestClose}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              Close
            </button>
            <h2 className="text-2xl font-bold mb-4">Add Lead</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Event Type:
                </label>
                <input
                  type="text"
                  name="event"
                  value={formData.event}
                  onChange={handleChange}
                  required
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Event Date:
                </label>
                <input
                  type="date"
                  name="eventDate"
                  value={formData.eventDate}
                  onChange={handleChange}
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                />
              </div>
                            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Phone:
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Lead Source:
                </label>
                <select
                  name="leadSource"
                  value={formData.leadSource}
                  onChange={handleChange}
                  required
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Lead Source</option>
                  <option value="Organic">Organic</option>
                  <option value="Referal">Referal</option>
                  <option value="Google">Google</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Justdial">Justdial</option>
                  <option value="Justdial">Others</option>

                  {/* Add more lead sources as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Assignee:
                </label>
            <select
              name="assignee"
              value={formData.assignee}
              onChange={handleChange}
              required
              className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
              >
              <option value="">Select Assignee</option>
              {assigneesData.map((assignee) => (
                <option key={assignee.id} value={assignee.name}>
                  {assignee.name}
                </option>
              ))}
            </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Status:
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  required
                  className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
                >
                  <option value="">Select Status</option>
                  <option value="FollowingUp">Following Up</option>
                  <option value="InTouch">In Touch</option>
                  <option value="SharedQuote">Shared Quote</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Completed">Completed</option>
                  <option value="Dropped">Dropped</option>
                  {/* Add more statuses as needed */}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Comments:
                </label>
                <div className="mb-4">
                  <div className="mb-2">
                    <ul className="mb-2">
                      {formData.comments.map((comment, index) => (
                        <li
                          key={index}
                          className="ml-4 w-50 p-2 rounded-md list-disc"
                          style={{ maxWidth: '400px' }}
                        >
                          {comment}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex">
                    <textarea
                      type="text"
                      name="newComment"
                      value={formData.newComment}
                      onChange={handleChange}
                      placeholder="Add a new comment"
                      className="border rounded-md px-4 py-2 w-full"
                      style={{ resize: 'vertical', minHeight: '40px' }}
                    />
                    <button
                      type="button"
                      onClick={handleAddComment}
                      className="ml-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                    >
                      Add
                    </button>
                  </div>
                </div>

              </div>
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Add Lead
              </button>
            </form>
            {isSuccess && (
              <div className="bg-green-200 text-green-800 border border-green-600 rounded-md p-2 mt-2">
                Lead added successfully!
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddLeadModal;
