import React, { useEffect, useState } from 'react'
import CircleCard from './CircularCard'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { collection, getDocs, getFirestore } from 'firebase/firestore'

const Category = () => {
  // const categories = [
  //   {
  //     name: 'Birthdays',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2Ficon-balloon-decorations-min.png?alt=media&token=8dcc6f46-6269-4edf-b756-bf0e87406904',
  //     src: '/products/Birthdays',
  //   },
  //   {
  //     name: 'Wedding',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2F1.png?alt=media&token=02fc54d5-ceda-44b9-9e94-7299297be017',
  //     src: '/WeddingPlanners',
  //   },
  //   {
  //     name: 'Corporate',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2F2.png?alt=media&token=c5c64d24-3a92-42ed-94b1-4992d6fbc718',
  //     src: '/Corporate',
  //   },
  //   {
  //     name: 'Anniversary',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2Fcouple.png?alt=media&token=17071a04-1790-4553-b585-84db99e73930',
  //     src: '/products/Anninversary',
  //   },
  //   {
  //     name: 'Naming',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2F4.png?alt=media&token=ffad2a1c-40df-4754-944e-449eebadcce9',
  //     src: '/products/NamingCermony',
  //   },

  //   {
  //     name: 'DJ-Shows',
  //     imageSrc:
  //       'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/quickacess%2F3.png?alt=media&token=a7f2ebd9-8230-45a9-8537-e9ebd3aeeaba',
  //     src: '/StageEvents',
  //   },
  // ]


  
  const [quickaccess, setQuickAccess] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const db = getFirestore()
        const categoriesCollectionRef = collection(db, 'quickaccess')
        const categoriesSnapshot = await getDocs(categoriesCollectionRef)
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data())
        console.log(categoriesData)

        setQuickAccess(categoriesData)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }

    fetchCategories()
  }, [])

  return (
    <div className="flex flex-col hidden lg:block bg-white m-auto p-auto">
      {/* <div className="text-center">
        <p className="text-2xl mt-5 mb-2 font-semibold mb-4">Quick Access</p>
      </div> */}
      <div className="flex flex-wrap justify-center p-1 px-4 py-4">
        {quickaccess.map((category, index) => (
          <div
            className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 px-3 py-3"
            key={index}
          >
            <Link to={category.link}>
              <CircleCard name={category.name} imageSrc={category.imageUrl} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category
