import React, { useState } from 'react';
import { getDatabase, ref, push, set } from 'firebase/database';

const StickyBottomBar = ({ productId }) => {
  const [zipCode, setZipCode] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const [showSorryPopUp, setShowSorryPopUp] = useState(false);

  const handleCheckZipCode = () => {
    if (zipCode.startsWith('560')) {
      setShowPopUp(true);
      setShowSorryPopUp(false);
    } else {
      setShowPopUp(false);
      setShowSorryPopUp(true);
    }
  };

  function createOrderId() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const date = String(currentDate.getDate()).padStart(2, '0');
  
    // Generate 5 random digits
    const randomDigits = Math.floor(10000 + Math.random() * 90000);
  
    // Combine date, month, year, and random digits to create the order ID
    const orderId = `${date}${month}${year}${randomDigits}`;
  
    return orderId;
  }

  const handleContinueBooking = () => {
    const orderId = createOrderId();
    const newOrder = {
      orderId: orderId, // Placeholder for orderId
      status: 'lead',
      productKey: productId,
      orderKey: ''
    };

// Get a reference to the 'orders' collection in the database
const db = getDatabase();
const ordersRef = ref(db, 'orders');

// Push the new order to the database
push(ordersRef, newOrder)
  .then((newOrderRef) => {
    const key = newOrderRef.key;
    newOrder.orderKey = key;
    set(newOrderRef, newOrder)
      .then(() => {
        window.location.href = `/Checkout/${key}`;
        setShowPopUp(false); // Hide the pop-up after action
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error updating order:', error);
      });
      })
  };

  const handleClosePopUp = () => {
    setShowPopUp(false);
    setShowSorryPopUp(false);
  };

  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-white py-1 px-1 text-white text-center"
      style={{ zIndex: 100, boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)' }}
    >
      {(!showPopUp && !showSorryPopUp) && (
        <div className="flex p-1 justify-center">
          {/* Zip Code Input */}
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            maxLength={6}
            className="flex-1 bg-white text-black font-bold py-2 px-4 rounded-sm border border-black"
            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
            placeholder="Enter 6-digit ZIP Code"
          />

          {/* Check Zip Code Button */}
          <button
            onClick={handleCheckZipCode}
            className="flex-1 bg-gray-900 text-white font-bold py-2 px-4 rounded-sm ml-2"
          >
            Check
          </button>
        </div>
      )}

      {showPopUp && (
        <div className="flex flex-col items-center p-4 bg-white text-black rounded-md shadow-md">
          <button onClick={handleClosePopUp} className="self-end text-red-500">
            &#x2715;
          </button>
          <div className="flex items-center p-2 bg-green-500 rounded-md text-white mb-4">
            <span className="text-2xl mr-2">&#10003;</span>
            <p className="text-lg font-semibold">Decoration available in this area!</p>
          </div>
          <button
            onClick={handleContinueBooking}
            className="bg-gray-900 text-white font-bold py-2 px-4 rounded-sm shadow-md hover:bg-gray-800"
          >
            Proceed to Book
          </button>
        </div>
      )}

      {showSorryPopUp && (
        <div className="flex flex-col items-center p-4 bg-white text-black rounded-md shadow-md">
          <button onClick={handleClosePopUp} className="self-end text-red-500">
            &#x2715;
          </button>
          <div className="flex items-center p-2 bg-red-500 rounded-md text-white mb-4">
            <span className="text-2xl mr-2">&#x2717;</span>
            <p className="text-lg font-semibold">Sorry, decoration is not available at this location.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StickyBottomBar;
