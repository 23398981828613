import { useEffect, useState } from "react";
import firebaseConfig from "../firebaseconfig";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, orderByChild, child, query, orderByValue, equalTo, startAt, endAt, limitToFirst, limitToLast, onValue } from "firebase/database";
import { getFirestore, doc, collection, getDoc, getDocs,where, onSnapshot } from 'firebase/firestore';




  const app = initializeApp(firebaseConfig);
  const database = getDatabase();
  const firestore = getFirestore();



const SimilarProducts = ( {productsKey}) => {
  const [products, setProducts] = useState([]);
  
  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const productRef = doc(firestore, 'products', productsKey);
    const productsListRef = collection(firestore, 'products');
  
    getDoc(productRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const { price, category } = snapshot.data();
          const threshold = 1000;
  
          const categoryQuery = query(productsListRef, where('category', '==', category));
          const priceQuery = query(
            productsListRef,
            where('price', '>=', price - 6000),
            where('price', '<=', price + 6000)
          );
  
          getDocs(categoryQuery)
            .then((categorySnapshot) => {
              getDocs(priceQuery)
                .then((priceSnapshot) => {
                  const categoryProducts = categorySnapshot.docs.map((doc) => ({
                    productId: doc.id,
                    ...doc.data()
                  }));
                  const priceProducts = priceSnapshot.docs.map((doc) => ({
                    productId: doc.id,
                    ...doc.data()
                  }));
  
                  // Combine the data from both queries and filter for common results
                  const commonProducts = categoryProducts.filter((categoryProduct) => {
                    return priceProducts.some((priceProduct) => priceProduct.productId === categoryProduct.productId);
                  });
  
                  // Sort common products by price
                  commonProducts.sort((a, b) => a.price - b.price);
  
                  // Limit to 8 products
                  const limitedProducts = commonProducts.slice(0, 8);
  
                  console.log('Common products:', limitedProducts);
  
                  const products = limitedProducts.map((product) => {
                    return {
                      imageUrl: product.downloadUrls[0] || '',
                      name: product.name || '',
                      price: parseInt(product.price || 0),
                      originalPrice: parseInt(product.originalPrice || 0),
                      productId: product.productId
                    };
                  });
  
                  setProducts(products);
                });
            });
        } else {
          console.log('No data available');
        }
      });
  }, []);
  




  return (
    <div className="bg-white mt-12 mb-24">
      <h2 className="text-2xl ml-8 mb-6 font-bold tracking-tight text-gray-900">Customers also purchased</h2>
  
      <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
        {products.map((product) => (
          <div key={product.id} className="mx-auto w-80 transform overflow-hidden rounded-lg bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg">
            <a href={`/product/${product.productId}`} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  className="h-100 w-100 object-cover object-center group-hover:opacity-75"
                  style={{ height: "333.8px", width: "333.8px" }}
                />
              </div>
              <h3 className="ml-2 mt-2 text-sm text-white font-medium">{product.name}</h3>
              <div className="flex items-center ml-2 mt-1 mb-2">
                <p className="text-lg font-medium text-white">
                  ₹{formatPrice(product.price)}
                </p>
                <del className="ml-2 text-white text-sm">
                  ₹{formatPrice(product.originalPrice)}
                </del>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
  
  }

  export default SimilarProducts;
  