import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  onSnapshot,
  deleteDoc,
} from 'firebase/firestore';

const AssigneeManagementModal = ({ isOpen, onRequestClose }) => {
  const [assignees, setAssignees] = useState([]);
  const [newAssignee, setNewAssignee] = useState('');

  useEffect(() => {
    const db = getFirestore();
    const assigneesCollectionRef = collection(db, 'assignees');

    const unsubscribe = onSnapshot(assigneesCollectionRef, (snapshot) => {
      const assigneesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAssignees(assigneesData);
    });

    return () => unsubscribe();
  }, []);

  const handleAddAssignee = async () => {
    if (newAssignee.trim() !== '') {
      try {
        const db = getFirestore();
        const assigneesCollectionRef = collection(db, 'assignees');

        await addDoc(assigneesCollectionRef, {
          name: newAssignee,
        });

        setNewAssignee('');
      } catch (error) {
        console.error('Error adding assignee:', error);
      }
    }
  };

  const handleRemoveAssignee = async (assigneeId) => {
    try {
      const db = getFirestore();
      const assigneeRef = doc(db, 'assignees', assigneeId);

      await deleteDoc(assigneeRef);
    } catch (error) {
      console.error('Error removing assignee:', error);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed inset-0 bg-black opacity-50" />
          <div className="modal absolute bg-white p-6 rounded-lg shadow-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 max-h-[80vh] overflow-y-auto">
            <button
              onClick={onRequestClose}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              Close
            </button>
            <h2 className="text-2xl font-bold mb-4">Assignee Management</h2>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter Assignee Name"
                value={newAssignee}
                onChange={(e) => setNewAssignee(e.target.value)}
                className="px-4 py-2 mt-2 border rounded-lg w-full focus:outline-none focus:border-blue-500"
              />
              <button
                onClick={handleAddAssignee}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg mt-2"
              >
                Add Assignee
              </button>
            </div>
            <ul>
              {assignees.map((assignee) => (
                <li key={assignee.id} className="flex justify-between mb-2">
                  {assignee.name}
                  <button
                    onClick={() => handleRemoveAssignee(assignee.id)}
                    className="text-red-500 hover:text-red-600"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default AssigneeManagementModal;
