import React, { useEffect, useState } from 'react';
import SidePanel from './Components/SidePanel';
import AdminHeader from './Components/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  query,
  where,
  Timestamp,
  getDocs,
  updateDoc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AddLeadModal from './Components/AddLeadModal';
import EditLeadModal from './Components/EditLeadModal';
import AssigneeManagementModal from './Components/AssigneeMangaement';
import AddCustomerModal from './Components/AddCustomerModal';

const AdminLeadTracker = () => {
  const [leads, setLeads] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [leadSource, setLeadSource] = useState('');
  const [assignee, setAssignee] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    String(new Date().getMonth() + 1).padStart(2, '0')
  );
  const [isAddCustomerOpen, setAddCustomerOpen] = useState(false);
  const [isAddleadOpen, setAddLeadOpen] = useState(false);
  const [editLeadData, setEditLeadData] = useState(null);
  const [isEditLeadOpen, setEditLeadOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [isAssigneeModalOpen, setAssigneeModalOpen] = useState(false);
  const [assigneesData,setAssigneeData] = useState([]);

  const [menu,setmenu ] = useState(false);
  const [Role,setRole] = useState(null);



  useEffect(() => {
    fetchAssignees();
  }, []);

  const fetchAssignees = () => {
    const db = getFirestore();
    const assigneesCollectionRef = collection(db, 'assignees');
  
    onSnapshot(assigneesCollectionRef, (snapshot) => {
      const assigneesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      
      // Update your state or assignees list with the fetched data
      setAssigneeData(assigneesData);
    });
  };

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin")
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);


  const db = getFirestore();


  useEffect(() => {
    const firestoreDb = getFirestore();
    const leadsCollectionRef = collection(
      firestoreDb,
      `leads/${currentYear}/All`
    );

    // Create a base query for leads

    const currentMonthFirstDay = new Date(currentYear, currentMonth-1, 1);
    const currentMonthLastDay = new Date(currentYear, currentMonth, 0);
   
    let leadsQuery = query(
      leadsCollectionRef,
      where('timestamp', '>=', Timestamp.fromDate(currentMonthFirstDay)),
      where('timestamp', '<=', Timestamp.fromDate(currentMonthLastDay))
    );

    if (leadSource) {
      leadsQuery = query(leadsQuery, where('leadSource', '==', leadSource));
    }
    
    if (assignee) {
      leadsQuery = query(leadsQuery, where('assignee', '==', assignee));
    }
    
    if (status) {
      leadsQuery = query(leadsQuery, where('status', '==', status));
    }

    const unsubscribe = onSnapshot(leadsQuery, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));

      console.log(data)

      data.sort((a, b) => a.eventDate.localeCompare(b.eventDate));

      console.log(data);

      setLeads(data);
    });

    return () => unsubscribe();
  }, [leadSource, assignee, status, currentYear, currentMonth]);

  const openEditLeadModal = (leadData) => {
    setSelectedLead(leadData);
    setEditLeadOpen(true);
  };


  // Function to open the Assignee Management modal
  const openAssigneeModal = () => {
    setAssigneeModalOpen(true);
  };

  // Function to close the Assignee Management modal
  const closeAssigneeModal = () => {
    setAssigneeModalOpen(false);
  };


  const deleteLead = (leadKey) => {
    const firestoreDb = getFirestore();
    const leadRef = doc(
      firestoreDb,
      `leads/${currentYear}/${currentMonth}`,
      leadKey
    );

    deleteDoc(leadRef)
      .then(() => {
        console.log('Lead deleted successfully');
      })
      .catch((error) => {
        console.log('Error deleting lead:', error.message);
      });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Get current leads for pagination
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  const onClose = () => {
    setAddLeadOpen(!isAddleadOpen);

  }

  const handleLeadUpdated = (updatedLead) => {
    const updatedLeads = leads.map((lead) =>
      lead.key === updatedLead.key ? updatedLead : lead
    );
    setLeads(updatedLeads);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentYear(date.getFullYear());
    setCurrentMonth(String(date.getMonth() + 1).padStart(2, '0'));
  };


  const openEditModal = (lead) => {
    console.log(lead)
    setSelectedLead(lead)
    setEditLeadOpen(true)
    // Add code here to open your edit modal or form
    // You can create a separate EditLeadModal component
  };
  const openAddCustomer = (lead) => {
    console.log(lead)
    setSelectedLead(lead)
    setAddCustomerOpen(true)
    // Add code here to open your edit modal or form
    // You can create a separate EditLeadModal component
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setmenu} />
      <AddLeadModal isOpen={isAddleadOpen} onRequestClose={onClose} />
      {isEditLeadOpen && (
        <EditLeadModal
          isOpen={isEditLeadOpen}
          onRequestClose={() => setEditLeadOpen(false)}
          leadData={selectedLead}
          onLeadUpdated={handleLeadUpdated}
          month={currentMonth}
          year={currentYear}
        />
      )}
       {isAddCustomerOpen && (
        <AddCustomerModal
          lead={selectedLead}
          onClose={setAddCustomerOpen}
        />
      )}
      <AssigneeManagementModal isOpen={isAssigneeModalOpen} onRequestClose={closeAssigneeModal} />
      <div className="flex-1 xl:ml-56">
        <AdminHeader setMenuOpen={setmenu} menu={menu} />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Lead Tracker</h2>
          <div className="mb-4 flex flex-col md:flex-row items-center">
            <div className="md:w-1/4 mb-2 md:mb-0">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => handleDateChange(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="px-2 py-1 border rounded w-full"
              />
            </div>
            <div className="md:w-1/4 mb-2 md:mb-0">
            <select
              value={leadSource}
              onChange={(e) => {
                setLeadSource(e.target.value)
                setCurrentPage(1)
              }
             
              }
              className="px-2 py-1 border rounded ml-2"
            >
              <option value="">Select Lead Source</option>
              <option value="Organic">Organic</option>
              <option value="Referal">Referal</option>
              <option value="Google">Google</option>
              <option value="Facebook">Facebook</option>
              <option value="Justdial">Justdial</option>
              <option value="Justdial">Others</option>

              {/* Add more lead sources as needed */}
            </select>
            </div>
            <div className="md:w-1/4 mb-2 md:mb-0">
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setCurrentPage(1);
                }}
                className="px-2 py-1 border rounded w-full"
              >
                 <option value="">All Status</option>
              <option value="FollowingUp">Following Up</option>
              <option value="InTouch">In Touch</option>
              <option value="SharedQuote">Shared Quote</option>
              <option value="Confirmed">Confirmed</option>
              <option value="Completed">Completed</option>
              <option value="Dropped">Dropped</option>
              </select>
            </div>
            <div className="md:w-1/4">
              <select
                value={assignee}
                onChange={(e) => {
                  setAssignee(e.target.value);
                  setCurrentPage(1);
                }}
                className="px-2 py-1 border rounded w-full"
              >
                <option value="">Select Assignee</option>
                {assigneesData.map((assignee) => (
                  <option key={assignee.id} value={assignee.name}>
                    {assignee.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 flex flex-col md:flex-row">
            <button
              onClick={() => setAddLeadOpen(true)}
              className="w-full md:w-auto px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mb-2 md:mb-0 md:mr-2"
            >
              Add Lead
            </button>
            <button
              onClick={openAssigneeModal}
              className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
            >
              Manage Assignees
            </button>
          </div>
          {currentLeads.length > 0 ? (
            <div>
              <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Name
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Event Type
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Date
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Phone
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Assignee
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Source
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Status
                    </th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentLeads.map((lead) => (
                    <tr key={lead.key}>
                      <td className="px-6 py-3 border">{lead.name}</td>
                      <td className="px-6 py-3 border">{lead.event}</td>
                      <td className="px-6 py-3 border">{lead.eventDate}</td>
                      <td className="px-6 py-3 border">{lead.phone}</td>
                      <td className="px-6 py-3 border">{lead.assignee}</td>
                      <td className="px-6 py-3 border">{lead.leadSource}</td>
                      <td className="px-6 py-3 border">{lead.status}</td>
                      <td className="px-6 py-3 border w-1/4">
                        {Role=="admin"&&
                        
                        <button
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                        onClick={() => deleteLead(lead.key)}
                      >
                        Delete
                      </button>
                        }
                       

                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-2"
                          onClick={() => openEditModal(lead)}
                        >
                          Edit
                        </button>
                        <button
                          className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-blue-600 ml-2"
                          onClick={() => openAddCustomer(lead) }
                        >
                          Convert
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              </div>
              <div className="mt-4">
                <Pagination
                  leadsPerPage={leadsPerPage}
                  totalLeads={leads.length}
                  paginate={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
            </div>
          ) : (
            <p>No leads found.</p>
          )}
        </div>
      </div>
    </div>
  );

   }

   const Pagination = ({ leadsPerPage, totalLeads, paginate, currentPage }) => {
    const pageNumbers = [];
  
    for (let i = 1; i <= Math.ceil(totalLeads / leadsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    return (
      <ul className="flex justify-center space-x-2">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`${number === currentPage ? 'text-blue-500 font-bold' : ''
              } cursor-pointer`}
            onClick={() => paginate(number)}
          >
            {number}
          </li>
        ))}
      </ul>
    );
  };

export default AdminLeadTracker;
