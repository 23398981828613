import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyPolicyCon from "./components/privacypolicycon";
import TermsConditionCon from "./components/termsndcondition";
function TermsandCondition() {
  return (
    <div className="App">
        <Header></Header>
        <TermsConditionCon ></TermsConditionCon>
        <Footer></Footer>
    </div>
  );
}

export default TermsandCondition;
