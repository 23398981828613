import React, { useState, useEffect } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import AddProductModal from "./Components/AddProductsModel";
import AddCategory from "./Components/AddCategory";
import EditProductModal from "./Components/EditProductModel";
import { initializeApp } from "firebase/app";
import { getDatabase, off,push,ref, set, onValue, remove, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, setDoc, doc, getDoc,where ,query ,deleteDoc, onSnapshot } from 'firebase/firestore';
import firebaseConfig from "../firebaseconfig";

const AdminProducts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [products, setProducts] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentProductKey, setCurrentProductKey] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [menu,setmenu ] = useState(false);
  const [Role,setRole] = useState(null);

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            // window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  const app = initializeApp(firebaseConfig);
  const database = getDatabase();
  const firestore = getFirestore(app);

  useEffect(() => {
    const categoriesRef = ref(database, "categories");
    const unsubscribe = onValue(categoriesRef, (snapshot) => {
      const categoriesData = snapshot.val();
      const categoriesArr = Object.keys(categoriesData).map((key) => ({
        id: key,
        name: categoriesData[key].name,
      }));
      setCategories(categoriesArr);
    });
  
    return () => {
      off(categoriesRef, 'value', unsubscribe);
    };
  }, [database]);
  
  

  useEffect(() => {
    const productsCollection = collection(firestore, "products");
    let q = productsCollection;
    if (selectedCategory) {
      q = query(productsCollection, where("category", "==", selectedCategory));
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const productsArr = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArr);
    });

    return () => {
      unsubscribe();
    };
  }, [firestore, selectedCategory]);

  const handleAddCategory = (category) => {
    setShowAddCategory(false);
  };

  const handleCancelAddCategory = () => {
    setShowAddCategory(false);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = async (productId) => {
    try {
      const productRef = doc(firestore, "products", productId);
      const productDoc = await getDoc(productRef);
      if (productDoc.exists()) {
        const productData = productDoc.data();
        setCurrentProduct(productData);
        setCurrentProductKey(productId);
        setShowEditModal(true);
      }
    } catch (error) {
      console.error(`Error editing product with ID ${productId}:`, error);
    }
  };

  const handleAddProduct = () => {
    setShowAddModal(true);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const productRef = doc(firestore, "products", productId);
      await deleteDoc(productRef);
      console.log("Product deleted successfully");
    } catch (error) {
      console.log("Error deleting product:", error.message);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(products.length / productsPerPage);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setmenu} />
      <div className="flex-1 xl:ml-56">
        <AdminHeader setMenuOpen={setmenu} menu={menu} />
        <div className="p-6">
          <div className="flex flex-col lg:flex-row items-center justify-between mb-4">
            <h2 className="text-2xl font-bold mb-4 lg:mb-0">
              Products
            </h2>
            <div className="mb-4">
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="px-2 py-1 border rounded"
              >
                <option value="">All</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4 lg:mb-0">
              <button
                onClick={handleAddProduct}
                className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 mr-2"
              >
                Add Product
              </button>
              <button
                onClick={() => setShowAddCategory(true)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Add Category
              </button>
            </div>
          </div>
          <p className="mb-4">Total Products: {products.length}</p>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  {/* <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                    ID
                  </th> */}
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                    Name
                  </th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                    Price(₹)
                  </th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                    Category
                  </th>
                  <th className="px-6 py-3 bg-gray-200 text-gray-700 border">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentProducts.map((product) => (
                  <tr key={product.id}>
                    {/* <td className="px-6 py-3 border">{product.id}</td> */}
                    <td className="px-6 py-3 border">{product.name}</td>
                    <td className="px-6 py-3 border">{product.price}</td>
                    <td className="px-6 py-3 border">{product.category}</td>
                    <td className="px-6 py-3 border w-1/6">
                      <button
                        className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 mr-2"
                        onClick={() => handleEditClick(product.id)}
                      >
                        Edit
                      </button>
                      {Role=="admin"&&
                       <button
                       className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                       onClick={() => handleDeleteProduct(product.id)}
                     >
                       Delete
                     </button>
                      }
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <ul className="flex mt-4">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  key={index}
                  className={`px-3 py-1 border ${
                    currentPage === index + 1
                      ? "bg-gray-200"
                      : "hover:bg-gray-200"
                  }`}
                  onClick={() => handlePageClick(index + 1)}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default AdminProducts;
