import { data } from 'autoprefixer';
import { Timestamp, collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

const UpcomingEvents = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    String(new Date().getMonth() + 1).padStart(2, '0')
  );
  const [upcomingEvents,setupcomingEvents] = useState(null);
  // Mock data for upcoming events
  


  useEffect(() => {
    const firestoreDb = getFirestore();
    const leadsCollectionRef = collection(
      firestoreDb,
      `leads/${currentYear}/All`
    );

    // Create a base query for leads

    const currentMonthFirstDay = new Date(currentYear, currentMonth-1, 1);
    const currentMonthLastDay = new Date(currentYear, currentMonth, 0);
   
    let leadsQuery = query(
      leadsCollectionRef,
      where('timestamp', '>=', Timestamp.fromDate(currentMonthFirstDay)),
      where('timestamp', '<=', Timestamp.fromDate(currentMonthLastDay))
    );

    leadsQuery = query(leadsQuery, where('status', '==', 'Confirmed'));

   

    const unsubscribe = onSnapshot(leadsQuery, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
      }));

      console.log(data)

      data.sort((a, b) => a.eventDate.localeCompare(b.eventDate));

      console.log(data);

      setupcomingEvents(data);
    });

    return () => unsubscribe();
  }, [currentYear, currentMonth]);

  if(upcomingEvents==null){
    return <></>
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Upcoming Events</h2>
      <div className="bg-white p-4 lg:w-1/3 rounded-lg border border-gray-300 shadow-md">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <th className="border-b p-2">Customer Name</th>
              <th className="border-b p-2">Event Date</th>
              <th className="border-b p-2">Handled By</th>
            </tr>
          </thead>
          <tbody>
            {upcomingEvents.map((event) => (
              <tr key={event.id}>
                <td className="border-b p-2">{event.name}</td>
                <td className="border-b p-2">{event.eventDate}</td>
                <td className="border-b p-2">{event.assignee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpcomingEvents;
