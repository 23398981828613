import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyPolicyCon from "./components/privacypolicycon";
function PrivacyPolicy() {
  return (
    <div className="App">
        <Header></Header>
        <PrivacyPolicyCon ></PrivacyPolicyCon>
        <Footer></Footer>
    </div>
  );
}

export default PrivacyPolicy;
