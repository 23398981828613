import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import firebaseConfig from '../firebaseconfig';
import ProductViewCard from '../components/PViewCard';

const app = initializeApp(firebaseConfig);
const db = getFirestore();

export default function ProductList({ name }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('');

 

  const sortLowToHigh = () => {
    setProducts((prevProducts) => [...prevProducts].sort((a, b) => parseFloat(a.price) - parseFloat(b.price)));
    setSortOrder('lowToHigh');
  };
  
  const sortHighToLow = () => {
    setProducts((prevProducts) => [...prevProducts].sort((a, b) => parseFloat(b.price) - parseFloat(a.price)));
    setSortOrder('highToLow');
  };


  useEffect(() => {
    const productsCollection = collection(db, 'products');
    const categoryQuery = query(productsCollection, where('category', '==', name));

    const unsubscribe = onSnapshot(
      categoryQuery,
      (snapshot) => {
        const formattedProducts = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            key: doc.id,
            imageUrl: data.downloadUrls[0],
            name: data.name,
            price: `${data.price}`,
            op: data.originalPrice,
          };
        });
        setProducts(formattedProducts);
        setLoading(false);
        setError(null);
      },
      (error) => {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    );

    return () => unsubscribe();
  }, [name, db]);

  return (
    <div className="bg-white mt-5 mb-24">
        <div className="text-center">
      <h2 className="text-2xl mt-5 mb-5 font-semibold mb-4">{name}</h2>
    </div>
    <div className="text-center mb-4">
      <button
        className={`mr-4 px-4 py-2 border rounded focus:outline-none ${
          sortOrder === 'lowToHigh' ? 'bg-gray-300' : 'bg-white'
        }`}
        onClick={sortLowToHigh}
      >
        Price Low to High
      </button>
      <button
        className={`px-4 py-2 border rounded focus:outline-none ${
          sortOrder === 'highToLow' ? 'bg-gray-300' : 'bg-white'
        }`}
        onClick={sortHighToLow}
      >
        Price High to Low
      </button>
    </div>
      {loading ? (
        <div className="text-center py-4">Loading...</div>
      ) : error ? (
        <div className="text-center py-4 text-red-500">{error}</div>
      ) : (
        <div className="mt-6 grid grid-cols-2 gap-y-5 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-1">
              <ProductViewCard
                imageUrl={product.imageUrl}
                name={product.name}
                price={product.price}
                id={product.key}
                op={product.op}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
