import React from 'react';

function AboutUsCont() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Us
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            We are a full-service event planners in Bangalore, specializing in creating one-of-a-kind experiences for our clients.
          </p>
        </div>
        <div className="mt-20">
            <div>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
                Our Passion for Bringing People Together
              </h3>
              <p className="text-lg text-gray-500 mb-6">
                At Footsypop Events, we are passionate about bringing people together and celebrating life's special moments. Whether you are looking to plan a corporate function, a milestone birthday party, or a cultural celebration, our team of creative artists is here to help you bring your vision to life.
              </p>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
                Our Wide Range of Event Planning Services
              </h3>
              <p className="text-lg text-gray-500 mb-6">
                We specialize in a wide range of events, including corporate functions, birthday parties, cultural celebrations, and more. No matter what type of event you are planning, our team has the expertise and resources to make it a success.
              </p>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
                Our Customizable Packages and Personal Approach
              </h3>
              <p className="text-lg text-gray-500 mb-6">
                We understand that planning an event can be a daunting task, which is why we offer a range of customizable packages to suit your needs and budget. Our experienced event professionals will work closely with you to understand your vision and help you to create a truly unique and memorable experience.
              </p>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
                Our Partners and Resources
              </h3>
              <p className="text-lg text-gray-500 mb-6">
                We have established strong partnerships with the best vendors in the industry, ensuring that we can provide our clients with top-quality services and products. From catering to entertainment, we have all the resources you need.
              </p>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
                Our Event Design and Decoration Services
              </h3>
              <p className="text-lg text-gray-500 mb-6">
              In addition to our event planning services, we also offer event design and decoration services to help you create a cohesive and visually stunning event space. Our team of talented designers will work with you to create a cohesive theme and bring your vision to life with custom decorations, lighting, and other finishing touches.
              </p>
              <h3 className="text-2xl font-extrabold text-gray-900 mb-4">
              Thank You for Considering Footsypop Events
              </h3>
              <p className="text-lg text-gray-500 mb-6">
              Thank you for considering Footsypop Events for your next occasion. We can't wait to work with you to create a truly unforgettable event that will leave you and your guests with memories to last a lifetime. Contact us today to learn more about our services and to start planning your next event.
              </p>
             </div>
             </div>
             </div>
             </div>
             )
            
    };

export default AboutUsCont