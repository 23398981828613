import React, { useEffect, useState } from 'react';
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getDatabase, ref, onValue, update, orderByChild, equalTo, get, query } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import OrderModal from './Components/OrderModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, where } from 'firebase/firestore';

const app = initializeApp(firebaseConfig);




const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState('pending');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedorder, setSelectedorder] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(String(new Date().getFullYear()));
  const [currentMonth, setCurrentMonth] = useState(
    String(new Date().getMonth() +1 ).padStart(2, '0')
  );
  const [statusFilter, setStatusFilter] = useState('');
  const [allOrder, setAllOrders] = useState([]);

  const [menu,setmenu ] = useState(false);
  const [Role,setRole] = useState(null);






    // Function to open the modal
    const openModal = () => {
      setModalOpen(true);
    };
  
    // Function to close the modal
    const closeModal = () => {
      setModalOpen(false);
    };

    
  const generateThumbnailUrl = (imageUrl, width, height) => {
    const thumbnailUrl = `${imageUrl}?alt=media&width=${width}&height=${height}`;
  
    return thumbnailUrl;
  };
  const viewOrderDetails = (orderData) => {
    setSelectedorder(orderData.id)
    setOrderData(orderData);
    openModal();
  };

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            // window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);



  useEffect(() => {
    const firestoreDb = getFirestore();
    console.log(currentMonth)
    const ordersCollectionRef = collection(firestoreDb, 'orders', currentYear.toString(), currentMonth);

    const q = query(ordersCollectionRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setOrders(data);
      setAllOrders(data);
      handleFilterChange(statusFilter, data, currentPage); // Apply filter after data is fetched
    });

    return () => unsubscribe();
  }, [statusFilter, currentPage, currentYear, currentMonth]);
  
  

  

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCurrentYear(date.getFullYear());
    setCurrentMonth(String(date.getMonth() + 1).padStart(2, '0'));
  };


 

  // Get current orders for pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle filter change
  const handleFilterChange = (status, orders, currentPage) => {
    const filteredOrders = orders.filter((order) => {
      // Check if the order's status matches the selected status
      if (status) {
        return order.status === status;
      } else {
        return true;
      }
    });

    setOrders(filteredOrders);
    setCurrentPage(currentPage);
  };
  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setmenu} />
      <div className="flex-1 xl:ml-56">
        <AdminHeader setMenuOpen={setmenu} menu={menu} />
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">Orders</h2>
          <div className="mb-4 flex flex-col lg:flex-row items-center">
            <div className="mb-2 lg:mb-0">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="px-2 py-1 border rounded w-full lg:w-auto"
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="eventConfirmed">Event Confirmed</option>
                <option value="canceled">Canceled</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div className="mb-2 lg:mb-0">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="px-2 py-1 border rounded w-full lg:w-auto"
              />
            </div>
            <div className="mb-2 lg:mb-0">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded w-full lg:w-auto ml-2"
                onClick={() => handleFilterChange(statusFilter, allOrder, 1)}
              >
                Apply Filter
              </button>
            </div>
          </div>
  
          {currentOrders.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="w-full table-auto bg-gray-100">
                <table className="table-auto w-full bg-gray-100">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Order Id</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Customer Name</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Product Name</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Zip Code</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Status</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentOrders.map((order) => (
                    <tr key={order.id}>
                      <td className="px-6 py-3 border whitespace-no-wrap">{order.orderId}</td>
                      <td className="px-6 py-3 border whitespace-no-wrap">{order.name}</td>
                      <td className="px-6 py-3 border whitespace-no-wrap">{order.productName}</td>
                      <td className="px-6 py-3 border whitespace-no-wrap">{order.postalCode}</td>
                      <td className="px-6 py-3 border whitespace-no-wrap">{order.status}</td>
                      <td className="px-6 py-3 border whitespace-no-wrap flex items-center justify-center h-full">
                        <div className="flex">
                          <button
                            onClick={() => {
                              viewOrderDetails(order);
                            }}
                            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 border rounded"
                          >
                            View
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>                </table>
              </div>
              <div className="mt-4">
                <Pagination
                  ordersPerPage={ordersPerPage}
                  totalOrders={orders.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      </div>
      {modalOpen && (
        <OrderModal orderData={orderData} closeModal={closeModal} currentYear={currentYear} currentMonth={currentMonth} id={selectedorder} />
      )}
    </div>
  );
  
};

const Pagination = ({ ordersPerPage, totalOrders, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalOrders / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="flex justify-center space-x-2">
      {pageNumbers.map((number) => (
        <li
          key={number}
          className={`${
            number === currentPage ? 'text-blue-500 font-bold' : ''
          } cursor-pointer`}
          onClick={() => paginate(number)}
        >
          {number}
        </li>
      ))}
    </ul>
  );
};


export default Orders;
