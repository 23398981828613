import React, { useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, push } from 'firebase/database';

const app = getStorage();

const AddSlides = ({ showAddSlide, setShowAddSlide }) => {
  const [images, setImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages([...images, file]);
    }
  };

  const handleImageCancel = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleUpload = async () => {
    if (images.length === 0) return;

    try {
      // Start the upload process
      setSuccess(false);
      setError(null);

      const storage = getStorage(); // Get the Firebase Storage object
      const db = getDatabase();
      const storageRef = ref(storage, 'slider_images'); // Create a reference to the storage bucket

      // Loop through all the images and upload them one by one
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const imageRef = ref(storageRef, image.name); // Create a reference to the image in the bucket
        const uploadTask = uploadBytesResumable(imageRef, image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error('Error uploading image:', error);
            setError('Error uploading image');
            setUploadProgress(0);
          },
          async () => {
            // Image uploaded successfully
            const imageUrl = await getDownloadURL(imageRef);
            console.log(imageUrl);
            const sliderRef = dbRef(db, 'sliderData');
            push(sliderRef, imageUrl);

            // Reset progress and show success message after all images are uploaded
            setUploadProgress(0);
            setSuccess(true);
          }
        );
      }

      // Clear the selected images
      setImages([]);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Error uploading image');
      setUploadProgress(0);
    }
  };
  return (
    <>
      <button
        onClick={() => setShowAddSlide(true)}
        className="bg-blue-500 text-white px-4 py-2 rounded-md"
      >
        Open Slider Modal
      </button>
  
      {showAddSlide && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center">
         
          <div className="bg-white  w-96">
            <div className='flex justify-between px-4  py-3 bg-gray-300 text-black font-bold border'>
            <div className="px-4 py-3 text-xl" >
              Add Slides
            </div>
            <button
            onClick={() => setShowAddSlide(false)}
            className="px-4 py-3 rounded bg-red-500 hover:bg-red-700"
          >
            Close 
          </button>
            </div>
          


            <div className="flex  p-8 flex-wrap">
              {images.map((image, index) => (
                <div key={index} className="relative w-24 h-24 mx-4 my-2">
                  <button
                    type="button"
                    className="absolute top-0 right-0 text-gray-700 hover:text-red-500"
                    onClick={() => handleImageCancel(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414L11.414 10l2.293 2.293a1 1 0 01-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 01-1.414-1.414L8.586 10 6.293 7.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <img
                    src={URL.createObjectURL(image)}
                    alt="event management bangalore"
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
              {images.length < 4 && (
                <div className="relative m-2">
                  <label
                    htmlFor="image"
                    className="h-24 w-24 rounded-md border-dashed border-2 border-gray-400 flex items-center justify-center cursor-pointer"
                  >
                    <input
                      type="file"
                      id="image"
                      onChange={handleImageChange}
                      className="sr-only"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-10 w-10 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15 8a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1V9a1 1 0 011-1h1V6a2 2 0 012-2h4a2 2 0 012 2v1h1zm-7-2a1 1 0 00-1 1v1h2V7a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </label>
                </div>
              )}
            </div>
  
            {uploadProgress > 0 && (
              <div className="mt-2">
                <progress className="w-full h-2" value={uploadProgress} max="100" />
              </div>
            )}
  
            {images.length > 0 && (
              <div className='px-6 py-3'>
                 <button
                onClick={handleUpload}
                className="mt-2  px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Upload {images.length} Image{images.length > 1 ? 's' : ''}
              </button>
               
               
                </div>
             
            )}
  
            {success && (
              <div className="text-green-500 mt-2">
                {images.length} Image{images.length > 1 ? 's' : ''} uploaded successfully!
              </div>
            )}
  
            {error && <div className="text-red-500">Error: {error}</div>}
  
           
          </div>
        </div>
      )}
    </>
  );
}
  
  export default AddSlides;
