import React from 'react';

const ProductViewCard = ({ imageUrl, name, price, id, op }) => {
  console.log(id);

  const getCardSize = () => {
    if (window.innerWidth <= 640) {
      return {
        width: '333.9px',
        height: '250px'
      };
    } else {
      return {
        width: '333.8px',
        height: '300.8px'
      };
    }
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  

  const cardSize = getCardSize();

  return (
    <div className="mx-auto w-30  sm:w-64 lg:w-80  transform overflow-hidden rounded-lg bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg" >
      <a href={`/product/${id}`} className="group">
        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
          <img
            src={imageUrl}
            alt={name}
            className="h-full w-full object-cover object-center group-hover:opacity-75"
            style={cardSize}
          />
        </div>
        <h3 className="ml-2 mt-2 text-sm sm:text-base text-white font-medium">{name}</h3>
        <div className="flex mb-2 items-center justify-between ml-2 mt-1">
          <div className="flex items-center">
            <p className="text-lg sm:text-base font-medium text-white">₹{formatPrice(price)}</p>
            <del className="ml-2 text-white text-sm sm:text-base">₹{formatPrice(op)}</del>
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProductViewCard;
