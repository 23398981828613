import { useState } from "react";
import {initializeApp} from 'firebase/app';
import {getDatabase,ref,push}  from 'firebase/database';
import firebaseConfig from "../../firebaseconfig";


function AddCategory({ showModal, setShowModal }) {
  const [category, setCategory] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);


  const app = initializeApp(firebaseConfig);

  const handleSubmit = (e) => {
    e.preventDefault();
     // Get a reference to the database
  const db = getDatabase();

  // Push category data to the database
  push(ref(db, "categories"), {
    name: category,
  }).then(() => {
    console.log("Category added successfully!");
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
    setError(null);
    setCategory("");
  }).catch((error) => {
    console.error("Error adding category: ", error);
    setSuccess(false);
    setError(error.message);
    setTimeout(() => {
      setError(null);
    }, 3000);
  });
    setCategory("");

  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-full flex items-center justify-center ${
        showModal ? "" : "hidden"
      }`}
    >
          <div className="bg-gray-100 w-1/2 shadow-lg rounded-lg">
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-2">Add Category</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="category"
              className="block text-gray-700 font-bold mb-2"
            >
              Category Name
            </label>
            <input
              type="text"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="border rounded-md px-4 py-2 w-full"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 mr-2"
              onClick={() => setShowModal(false)}
              >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            >
              Add
            </button>
          </div>
        </form>
      </div>
      {success && <div className="bg-green-200 text-green-700 p-2 rounded-md my-2">Category added successfully!</div>}
      {error && <div className="bg-red-200 text-red-700 p-2 rounded-md my-2">Error: {error}</div>}

    </div>


    </div>
  
  );
}
 

export default AddCategory