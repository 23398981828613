import React, { useState } from 'react';
import { ref,push, onValue, getDatabase } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const ReviewModal = ({showReviewModal, setReviewModal, productKey ,setReviewSuccess, setReviewFailure}) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [name, setName] = useState('');

  const openModal = () => {
    setReviewModal(true);
  };

  const closeModal = () => {
    setReviewModal(false);
    setRating(0);
    setComment('');
    setName('');
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create a review object with the necessary data
    const review = {
      rating,
      comment,
      name,
    };
   
    // Get a reference to the reviews list under the product key
    const reviewsRef = ref(database,`products/${productKey}/reviews`);
    console.log(reviewsRef);
    // Push the review object to the reviews list
    push(reviewsRef,review)
      .then(() => {
        closeModal();
        setReviewSuccess(true);
        // Optionally, you can perform any additional actions after the review is added to the database
      })
      .catch((error) => {
        closeModal();
        setReviewFailure(true);
        console.log('Error adding review:', error);
        // Handle the error, if necessary
      });
  };


  return (
    <div className="relative">

      {showReviewModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10">
          <div className="absolute inset-0 bg-gray-800 opacity-50" />

          <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-8 relative z-10">
            <h2 className="text-2xl font-bold mb-4">Write a Review</h2>

            <form onSubmit={handleSubmit}>
              <div className="flex items-center mb-4">
                <span className="mr-2">Rating:</span>
                <div>
                  {Array.from({ length: 5 }, (_, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => handleRatingChange(index + 1)}
                      className={`${
                        index < rating ? 'text-yellow-400' : 'text-gray-300'
                      } focus:outline-none`}
                    >
                      ★
                    </button>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-2" htmlFor="name">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2" htmlFor="comment">
                  Comment:
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  value={comment}
                  onChange={handleCommentChange}
                  className="w-full h-20 px-3 py-2 border border-gray-300 rounded focus:outline-none"
                  required
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeModal}
                  className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewModal;
