import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getFirestore, setDoc, query, onSnapshot, getDoc } from "firebase/firestore";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import Modal from "react-modal"; // Import the Modal component
import CreateUser from "./Components/CreateUser";

Modal.setAppElement("#root"); // Set the app element for accessibility

const UserMangement = () => {
 
  const [menu, setMenu] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false); // Track modal visibility
  const [loggedIn, setLoggedIn] = useState(false);
  const [Role,setRole] = useState(null);


  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            // window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getFirestore();
    const usersCollection = collection(db, "adminusers");

    const unsubscribe = onSnapshot(usersCollection, (snapshot) => {
      const users = [];
      snapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      setAdminUsers(users);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);



  return (
    <div className="flex h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setMenu} />
      <div className="flex-1 xl:ml-56">
        <AdminHeader setMenuOpen={setMenu} menu={menu} />
        <div className="p-6">
          <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg mb-4">
            <h2 className="text-2xl font-semibold mb-4">Create User</h2>
            <button
              onClick={() => setShowCreateUserModal(true)}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Create User
            </button>
          </div>
          {showCreateUserModal &&   <CreateUser isOpen={showCreateUserModal} setClose={setShowCreateUserModal}/>}

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold p-4">Admin Users</h2>
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Name</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Email</th>
                    <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {adminUsers.map((user) => (
                    <tr key={user.id}>
                      <td className="px-6 py-3 border">{user.name}</td>
                      <td className="px-6 py-3 border">{user.email}</td>
                      <td className="px-6 py-3 border">{user.role}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserMangement;
