import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://videogiri.com/wp-content/uploads/2020/02/house-warming-invitation.jpg',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://www.nobroker.in/blog/wp-content/uploads/2022/07/Flower-Vases-Bouquet.jpg',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/originals/2e/da/d5/2edad51092b4ca8c23b1e4b791a368c2.jpg',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/550x/98/25/67/9825674b7221a655a3e40498bb49cf3a.jpg',
  },
]

function Housewarming() {
  return (
    <div className="App">
      <Helmet>
        <title>Housewarming Ceremony Planners</title>
        <meta name="title" content="Housewarming Ceremony Planners" />
        <meta
          name="description"
          content="A housewarming event is an important milestone in your life, At Footsypop Events, we offer a range of services to help you create an unforgettable event."
        />
        <meta property="og:title" content="Housewarming Ceremony Planners" />
        <meta
          property="og:description"
          content="A housewarming event is an important milestone in your life, At Footsypop Events, we offer a range of services to help you create an unforgettable event."
        />
        <meta
          property="og:url"
          content="https://www.footsypopevents.com/HouseWarming"
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold">
          Housewarming Ceremony Planners
        </h1>
      </div>

      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l font-semibold text-gray-600 leading-7">
          Congratulations on your new home! A housewarming event is an important
          milestone in your life, and at Footsypop Events, we understand how
          much it means to you. It's a time when you invite your friends and
          family to celebrate this special occasion with you, and we're here to
          help you make it a truly memorable event. We believe that every
          housewarming ceremony should reflect your unique style and
          personality. That's why we offer a range of services to help you
          create a personalized and unforgettable event. From the entrance decor
          to the mantapa decor, lighting, catering, and event planning and
          management, we've got you covered. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Housewarming Ceremony Planners"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l  font-semibold text-gray-600 leading-7">
          Our team of experienced event planners and designers will work closely
          with you to understand your vision and help you bring it to life.
          We'll take care of all the details, so you can relax and enjoy your
          special day with your loved ones. Whether you're looking for a simple
          and elegant ceremony or a grand celebration, we have the expertise and
          resources to make it happen. Our team will ensure that every aspect of
          your housewarming event is carefully planned and executed to
          perfection. So why wait? Contact us today and let us help you create a
          truly unforgettable housewarming ceremony that you'll cherish for
          years to come.
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10  py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://i.pinimg.com/564x/1d/6e/38/1d6e385ca95e0d3a9a6324b7c9f5afed.jpg"
              alt="Housewarming ceremony"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              How We Can Help with Your Housewarming Event
            </h2>
            <p class="text-lg mb-5">
              At Footsypop Events, we understand that a housewarming event is an
              important milestone in your life. It is a time when you invite
              your friends and family to your new home to celebrate this special
              occasion with you. We are here to help you make your housewarming
              ceremony a memorable one.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Tailored Approach:</h3>
              <p>
                We understand that your housewarming ceremony is a significant
                milestone in your life. Our team of experienced housewarming
                ceremony planners takes a personalized approach, taking the time
                to understand your unique vision, preferences, and aspirations.
                We work closely with you to curate an event that reflects your
                individual style, making it an authentic representation of your
                new home and the journey you&#39;ve embarked upon.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Concept Development and Design:
              </h3>
              <p>
                At Footsypop, we believe in creating immersive experiences that
                captivate your senses and emotions. Our talented team of
                designers and decorators will conceptualize captivating themes
                and decor that infuse your housewarming ceremony with warmth,
                elegance, and a touch of your personal style. From luxurious and
                opulent to cozy and intimate, we bring your vision to life,
                transforming your space into a haven that reflects your taste
                and personality.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Guest Experience:</h3>
              <p>
                We believe that every guest should feel welcomed and appreciated
                at your housewarming ceremony. Our team goes above and beyond to
                ensure a seamless and memorable experience for your guests. From
                personalized invitations that set the tone for the event to
                thoughtful gestures and amenities, we leave no stone unturned in
                creating an atmosphere of warmth and hospitality. Our goal is to
                make your guests feel special and connected, fostering an
                environment of joy, celebration, and meaningful connections.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Culinary Excellence:</h3>
              <p>
                The culinary aspect of your housewarming ceremony is an
                essential component in creating a lasting impression. Our
                culinary experts will collaborate with you to curate a
                customized menu that tantalizes the taste buds and showcases the
                finest flavors. From delectable appetizers and main courses to
                decadent desserts and signature cocktails, we ensure that every
                culinary creation delights your guests and adds a memorable
                touch to the celebration.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Entertainment and Music:</h3>
              <p>
                We understand the power of music and entertainment in setting
                the mood and creating lasting memories. Our talented team of
                entertainment specialists will curate a lineup of talented
                musicians, DJs, or performers who will captivate your guests and
                elevate the ambiance of your housewarming ceremony. Whether you
                prefer live music that resonates with your taste or engaging
                performances that create an interactive experience, we have the
                expertise to make it happen.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Meaningful Rituals and Traditions:
              </h3>
              <p>
                Housewarming ceremonies often include rituals and traditions
                that symbolize blessings, good fortune, and prosperity in your
                new home. Our experienced planners are well-versed in various
                housewarming customs and can guide you in incorporating
                meaningful rituals into your ceremony. We will ensure that these
                traditions are respected and thoughtfully integrated, infusing
                your event with depth, significance, and a sense of cultural
                heritage.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Post-Ceremony Services:</h3>
              <p>
                Our commitment to your satisfaction extends beyond the ceremony
                itself. We can assist with post-ceremony tasks, such as
                coordinating house tours for guests, managing vendor payments,
                and providing recommendations for local services or home-related
                needs. We are here to support you even after the celebration,
                ensuring that your transition into your new home is as seamless
                as possible.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Event Planning and Management
              </h3>
              <p>
                Planning a housewarming ceremony can be a daunting task, but our
                experienced event planners are here to help. From choosing the
                right venue to coordinating all the details of your event, we
                will be with you every step of the way to ensure that your
                housewarming ceremony is a success.
              </p>
            </div>{' '}
            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        <p class="text-l mt-10 mb-10 font-semibold text-gray-600 leading-7">
          Choose Footsypop Events Management Company for Your Housewarming
          Ceremony Your housewarming ceremony is a momentous occasion that
          deserves to be celebrated in style. With Footsypop Events Management
          Company by your side, you can trust that every detail will be
          carefully planned and executed to perfection. We are dedicated to
          creating a housewarming ceremony that exceeds your expectations and
          creates lifelong memories for you and your guests. Contact us today to
          discuss your vision, and let our expert housewarming ceremony planners
          bring your dreams to life. Together, we will make your housewarming
          ceremony an unforgettable event filled with joy, warmth, and the
          promise of new beginnings.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default Housewarming
