import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Corporate Event Organisers',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/736x/0e/b4/b8/0eb4b8fe077c9dd8e6e3449234405ab2.jpg',
  },
  {
    title: 'Corporate Event Organisers',
    size: '3.9 MB',
    source:
      'https://www.jetsupholidays.com/wp-content/uploads/2019/12/Seminar-Jetsupholidays-scaled.jpg',
  },
  {
    title: 'Corporate Event Organisers',
    size: '3.9 MB',
    source:
      'https://wmevents.s3.ap-south-1.amazonaws.com/wp-content/uploads/2021/01/23132526/IMG_9323-scaled.jpg',
  },
  {
    title: 'Corporate Event Organisers',
    size: '3.9 MB',
    source:
      'https://cdn.greenvelope.com/blog/wp-content/uploads/AdobeStock_103067581.jpeg',
  },
]

function Corporate() {
  return (
    <div className="App">
      <Helmet>
        <title>Corporate Event Organisers in bangalore</title>
        <meta name="title" content="Corporate Event Organisers in bangalore | Footsypop Events" />
        <meta
          name="description"
          content="We are the best corporate event organizers in Bangalore for seamless planning & execution. Tailored services for conferences, seminars, and more."
        />
        <meta
          property="og:title"
          content="Corporate Event Organisers in bangalore | Footsypop Events"
        />
        <meta
          property="og:description"
          content="We are the best corporate event organizers in Bangalore for seamless planning & execution. Tailored services for conferences, seminars, and more."
        />
        <meta
          property="og:url"
          content="https://www.footsypopevents.com/Corporate"
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold">
          Corporate Event Organisers in bangalore
        </h1>
      </div>

      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          We are the best corporate event organisers in bangalore! Corporate
          events play a significant role in the business world, serving as
          opportunities for networking, team building, and brand promotion. In a
          bustling city like Bangalore, where the corporate landscape is vibrant
          and diverse, organizing successful corporate events requires
          meticulous planning and execution. This is where corporate event
          organizers in Bangalore come into the picture, offering their
          expertise and services to ensure that every corporate event is a
          resounding success. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>

        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          We understand that planning a corporate events can be overwhelming,
          especially if you have a busy schedule. That's why we're here to take
          the stress off your shoulders and handle everything from start to
          finish. Our team will work within your budget and timeline to create
          an event that exceeds your expectations. At Footsypop Events, we
          believe that every corporate party should leave a lasting impression
          on your guests. That's why we pay attention to every detail to ensure
          that your event is a success. Contact us today and let us help you
          plan a corporate party that your guests will remember for years to
          come.
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="corporate event organisers in bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l font-semibold text-gray-600 leading-7"></p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2"></div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              Why Choose Corporate Event Organisers in Bangalore?
            </h2>
            <p class="text-lg mb-5">
              Bangalore, often referred to as the Silicon Valley of India, is
              home to numerous multinational corporations, startups, and tech
              giants. With its thriving business ecosystem and cosmopolitan
              culture, the city hosts a wide range of corporate events,
              including conferences, seminars, product launches, and team
              outings.
            </p>
            <p class="text-lg mb-5">
              Organizing these events can be a daunting task, given the
              logistical challenges and the need for precision planning.
              Corporate event organisers in Bangalore specialize in managing all
              aspects of corporate events, from venue selection to event
              coordination, catering to the unique needs of businesses in the
              city.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Experience and Expertise - Corporate Event Organisers in
                bangalore
              </h3>
              <p>
                One of the primary reasons to choose corporate event organisers
                in Bangalore is their experience and expertise in the industry.
                With years of experience handling corporate events of all sizes
                and scales, these professionals have a deep understanding of the
                corporate landscape in Bangalore. They are well-versed in the
                latest trends and technologies in event management, ensuring
                that each event is executed flawlessly and leaves a lasting
                impression on attendees.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Customization and Personalization from Corporate Event
                Organisers in bangalore
              </h3>
              <p>
                Corporate events come in all shapes and sizes, each with its own
                objectives and requirements. Corporate event organisers in
                Bangalore understand the importance of customization and
                personalization in creating memorable corporate events. They
                work closely with their clients to understand their goals,
                preferences, and budget constraints, tailoring their services to
                meet their specific needs. Whether it's a formal conference, a
                team-building retreat, or a product launch, these organisers
                ensure that every detail is meticulously planned and executed to
                perfection.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Vendor Network within Corporate Event Organisers in bangalore
              </h3>
              <p>
                One of the key advantages of hiring corporate event organisers
                in Bangalore is their extensive network of vendors and
                suppliers. From venues and catering services to audio-visual
                equipment and entertainment options, these organisers have
                established relationships with trusted vendors across the city.
                This enables them to negotiate the best deals on behalf of their
                clients and ensure that every aspect of the event meets their
                expectations.
              </p>
            </div>
            <h2 class="text-3xl font-bold mb-5">
              Footsypop Events: Your Trusted Corporate Event Partner
            </h2>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Design and Branding</h3>
              <p>
                Creating a cohesive and visually appealing event is essential to
                making a lasting impression on attendees. we offer creative
                design and branding services, helping businesses create custom
                event themes and branding materials that reflect their corporate
                identity. From signage and banners to digital presentations and
                promotional materials, we ensure that every element of the event
                is professionally designed and aligned with the client's brand.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Entertainment and Engagement
              </h3>
              <p>
                Engaging attendees and keeping them entertained is key to the
                success of any corporate event. Corporate event organisers in
                Bangalore - Footsypop Events arrange a variety of entertainment
                options, from live music and interactive activities to keynote
                speakers and panel discussions. We work closely with their
                clients to understand their audience and objectives, curating
                entertainment options that are engaging, relevant, and
                memorable.
              </p>
            </div>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">On-the-Day Management</h3>
              <p>
                On the day of the event, everything must come together
                seamlessly to create a memorable experience for attendees.
                Corporate event organisers in Bangalore - Footsypop Events take
                charge of on-the-day management, overseeing setup, coordinating
                vendors, and handling any last-minute issues that may arise. Our
                calm and efficient handling of logistics ensures that the event
                unfolds smoothly from start to finish, leaving attendees
                impressed and satisfied.
              </p>
            </div>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Post-Event Support</h3>
              <p>
                Even after the event is over, corporate event organisers in
                Bangalore continue to provide support and assistance to their
                clients. We gather feedback from attendees, analyze event
                metrics, and provide valuable insights to help businesses
                evaluate the success of their event and identify areas for
                improvement. Their commitment to client satisfaction extends
                beyond the event itself, ensuring that businesses achieve their
                objectives and derive maximum value from their investment.
              </p>
            </div>

            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        <p class="text-l font-semibold text-gray-600 leading-7">
          Organizing a successful corporate event requires meticulous planning,
          attention to detail, and expertise. Corporate event organisers in
          Bangalore offer businesses the resources and support they need to plan
          and execute memorable events that achieve their objectives and leave a
          lasting impression on attendees.
          <br />
          <br />
          From venue selection to event coordination, these professionals handle
          every aspect of the event with professionalism and dedication,
          allowing businesses to focus on their goals without worrying about the
          logistics. Contact us today to embark on a journey of unparalleled
          event experiences that align with your brand vision and business
          objectives. Your success is our priority at Footsypop Events!
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default Corporate
