import React, { useEffect, useState } from "react";
import SidePanel from "./Components/SidePanel";
import AdminHeader from "./Components/Header";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { get, getDatabase, ref } from "firebase/database";
import UpcomingEvents from "./Components/UpcomingEvents";

const AdminHome = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [menu,setmenu ] = useState(false);
  const [Role,setRole] = useState(null);

  useEffect(() => {
    const checkAdminStatus = async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDataRef = doc(db, 'adminusers', user.uid);
          const userDataSnapshot = await getDoc(userDataRef);
    
          if (userDataSnapshot.exists()) {
            const userData = userDataSnapshot.data();
            console.log(userData.role)
            if (userData.role === "admin") {
              console.log(userData.role)
              setRole("admin");
              setLoggedIn(true);
            } else if (userData.role === "employee") {
              setRole("employee");
              setLoggedIn(true);
            }
          } else {
            // window.location.href = '/admin';
          }
        } catch (error) {
          // Handle any errors that may occur during data retrieval
          console.error(error);
        }
      } else {
        window.location.href = '/admin';
      }
    };
  
  
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      checkAdminStatus(user);
    });
  
    return () => unsubscribe();
  }, []);

  if (!loggedIn) {
    return null; // or return a loading/spinner component
  }

  return (
    <div className="flex h-screen">
      <SidePanel Role={Role} menu={menu} setMenuOpen={setmenu} />
      <div className="flex-1 xl:ml-56">
        <AdminHeader setMenuOpen={setmenu} menu={menu} />
        <div className="p-6">
          <UpcomingEvents/>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
