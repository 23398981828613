import React from "react";
import { Link } from "react-router-dom";

const ProductNavigation = ({ category, productName }) => {
  // Remove spaces and replace them with a hyphen in the category
  const categoryWithoutSpaces = category.replace(/\s+/g, "");

  return (
    <div className="product-navigation bg-white border-b border-gray-300 py-2 md:py-4 pl-4 md:pl-8">
      <Link to="/" className="text-blue-500 hover:text-blue-400">Home</Link> &gt;{" "}
      <Link to={`/products/${categoryWithoutSpaces}`} className="text-blue-500 hover:text-blue-400">{category}</Link>
      {productName && <span>&gt; {productName}</span>}
    </div>
  );
};

export default ProductNavigation;
