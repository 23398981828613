import React from 'react';

const ShippingPolicyCon = () => {
  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-4">Shipping Policy</h1>
      <p className="mb-4">
        We offer shipping for our event decor products to locations within Bangalore city limits. Shipping rates are calculated based on the size and weight of the package, and will be displayed at checkout.
      </p>
      <h2 className="text-xl font-bold mb-2">Shipping Timeline</h2>
      <p className="mb-4">
        Orders will be shipped within 2-3 business days of purchase. Delivery times may vary depending on the shipping destination and the shipping method selected at checkout.
      </p>
      <h2 className="text-xl font-bold mb-2">Delivery Options</h2>
      <p className="mb-4">
        We offer two delivery options for our event decor products:
      </p>
      <ul className="list-disc ml-8 mb-4">
        <li>Standard Shipping: Delivery within 3-5 business days</li>
        <li>Express Shipping: Delivery within 1-2 business days</li>
      </ul>
      <p className="mb-4">
        <strong>Shipping Restrictions:</strong> We currently only offer shipping within Bangalore city limits. We do not ship internationally or to locations outside of Bangalore.
      </p>
      <h2 className="text-xl font-bold mb-2">Shipping Confirmation</h2>
      <p className="mb-4">
        Once your order has been shipped, you will receive a shipping confirmation email with a tracking number.
      </p>
      <h2 className="text-xl font-bold mb-2">Lost or Damaged Packages</h2>
      <p className="mb-4">
        In the rare event that your package is lost or damaged during shipping, please contact us immediately. We will work with the shipping carrier to resolve the issue and either issue a replacement or refund.
      </p>
      <h2 className="text-xl font-bold mb-2">Contact Us</h2>
      <p>
        If you have any questions or concerns about our shipping policy, please contact us at <a href="mailto:support@footsypopevents.com" className="text-blue-600 hover:underline">support@footsypopevents.com</a>.
      </p>
    </div>
  );
};

export default ShippingPolicyCon;
