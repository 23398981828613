import React from "react";

const TermsConditionCon = () => {
  return (
    <div className="max-w-4xl mt-10 mb-10 mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
      <p className="mb-4">
        Welcome to FootsypopEvents! These Terms and Conditions ("Terms") govern
        your use of our website and services. By using our website and services,
        you agree to these Terms.
      </p>
      <h3 className="text-lg font-medium mb-2">
        Use of Our Website and Services
      </h3>
      <p className="mb-4">
        You may use our website and services only for lawful purposes and in
        accordance with these Terms. You agree not to use our website or
        services for any unlawful purpose or in any way that may infringe on the
        rights of others.
      </p>
      <h3 className="text-lg font-medium mb-2">Services Offered</h3>
      <p className="mb-4">
        FootsypopEvents offers event management services for corporate events,
        private parties, and weddings. Our services include event planning,
        event coordination, vendor management, and event production. We strive
        to provide our clients with exceptional service and an unforgettable
        event experience.
      </p>
      <h3 className="text-lg font-medium mb-2">
        Payment and Cancellation Policy
      </h3>
      <p className="mb-4">
        A deposit of 50% is required to secure your event date. The remaining
        balance is due 30 days prior to the event date. Cancellation of services
        must be made in writing at least 30 days prior to the event date to
        receive a refund of the deposit. Cancellations made within 30 days of
        the event date are non-refundable.
      </p>
      <h3 className="text-lg font-medium mb-2">Intellectual Property</h3>
      <p className="mb-4">
        All content on our website and services, including but not limited to
        text, graphics, logos, images, and software, is our property or the
        property of our licensors and is protected by copyright, trademark, and
        other intellectual property laws. You may not use any content on our
        website or services without our prior written consent.
      </p>
      <h3 className="text-lg font-medium mb-2">Privacy</h3>
      <p className="mb-4">
        We value your privacy and are committed to protecting your personal
        information. Please see our Privacy Policy for information about how we
        collect, use, and disclose your information.
      </p>
      <h3 className="text-lg font-medium mb-2">
        Third-Party Links and Content
      </h3>
      <p className="mb-4">
        Our website and services may contain links to third-party websites or
        services and may display content from third parties. We do not endorse
        or control these third-party websites or services and are not
        responsible for their content or privacy practices.
      </p>
      <h3 className="text-lg font-medium mb-2">Disclaimer of Warranties</h3>
      <p className="mb-4">
        Our website and services are provided "as is" and without warranty of
        any kind, either express or implied, including but not limited to, the
        implied warranties of merchantability, fitness for a particular purpose,
        or non-infringement. We do not warrant that our website or services will
        be uninterrupted, error-free, or secure.
      </p>
      <h3 className="text-lg font-medium mb-2">Limitation of Liability</h3>
      <p className="mb-4">
        To the extent permitted by law, FootsypopEvents will not be liable for
        any direct, indirect, incidental, consequential, or punitive damages
        arising out of or relating to your use of our website or services.
      </p>

      <h3 className="text-lg font-medium mb-2">Indemnification</h3>
      <p className="mb-4">
        You agree to indemnify and hold FootsypopEvents harmless from and
        against any claims, liabilities, damages, losses, and expenses,
        including without limitation, reasonable attorney's fees, arising out of
        or in any way connected with your use of our website or services or your
        violation of these Terms. This includes any claims or damages arising
        from your use of our event management services, including but not
        limited to any injury, illness, or other harm that may occur during an
        event organized by FootsypopEvents.
      </p>
      <h3 className="text-lg font-medium mb-2">
        Governing Law and Jurisdiction
      </h3>
      <p className="mb-4">
        These Terms and your use of our website and services are governed by the
        laws of the jurisdiction in which FootsypopEvents is located, without
        regard to its conflict of law provisions. Any legal action or proceeding
        arising out of or relating to these Terms or your use of our website or
        services shall be exclusively brought in the courts located in that
        jurisdiction.
      </p>
      <h3 className="text-lg font-medium mb-2">Changes to these Terms</h3>
      <p className="mb-4">
        FootsypopEvents may update these Terms from time to time. If we make
        material changes, we will notify you by email or by posting a notice on
        our website prior to the change becoming effective. Your continued use
        of our website or services after the effective date of the updated Terms
        constitutes your acceptance of the updated terms.
      </p>
    </div>
  );
};

export default TermsConditionCon;
