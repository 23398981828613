import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Wedding Planners in Bangalore',
    size: '3.9 MB',
    source:
      'https://cdn0.weddingwire.in/article/1979/3_2/1280/jpg/79791-the-experts-guide-to-planning-an-intimate-50-people-wedding-roma-ganesh-photography-lead-image.webp',
  },
  {
    title: 'Wedding Planners in Bangalore',
    size: '3.9 MB',
    source:
      'https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg',
  },
  {
    title: 'Wedding Planners in Bangalore',
    size: '3.9 MB',
    source:
      'https://t4.ftcdn.net/jpg/04/20/23/09/360_F_420230944_5zUmrpR5akRiCEZhhRtapMkpvd67Hw7C.jpg',
  },
  {
    title: 'Wedding Planners in Bangalore',
    size: '3.9 MB',
    source:
      'https://img.staticmb.com/mbcontent//images/uploads/2022/12/sangeet-decoration-ideas.jpg',
  },
]

function WeddingPlanners() {
  return (
    <div className="App">
      <Helmet>
        <title>Wedding Planners in Bangalore</title>
        <meta name="title" content="Wedding Planners in Bangalore" />
        <meta
          name="description"
          content="Expert wedding planners in Bangalore, We offer seamless coordination for your special day, ensuring a stress-free and memorable experience."
        />
        <meta property="og:title" content="Wedding Planners in Bangalore" />
        <meta
          property="og:description"
          content="Expert wedding planners in Bangalore, We offer seamless coordination for your special day, ensuring a stress-free and memorable experience."
        />
        <meta
          property="og:url"
          content="https://www.footsypopevents.com/WeddingPlanners"
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-10 font-bold mb-4">
          Wedding Planners in Bangalore
        </h1>
      </div>

      <div class="pt-5 pb-5 px-4 py-4">
        <p className="text-l  font-semibold text-gray-600 leading-7">
          As a best wedding planners in bangalore we know that Planning a
          wedding can be an overwhelming task, with countless decisions to make
          and details to organize. From choosing the perfect venue to selecting
          vendors and coordinating logistics, the process can quickly become
          daunting. This is where the expertise of a wedding planner comes into
          play. Wedding planners are professionals who specialize in managing
          every aspect of a wedding, ensuring that the day runs smoothly and
          according to plan. Get ready to remember this moment for the rest of
          your lives. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Wedding Planners in Bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l font-semibold text-gray-600 leading-7">
          At Footsypop Events, we understand that your wedding day is one of the
          most important and memorable days of your life. It&#39;s a day to
          celebrate your love and commitment to each other with your family and
          friends, and we&#39;re here to help you make it an unforgettable
          event. We believe that every wedding should be unique and reflect the
          couple&#39;s personal style and preferences. That&#39;s why we offer a
          range of services to help you create a personalized and magical
          wedding day. From the decor and lighting to the catering, music, and
          entertainment, we&#39;ve got you covered. <br /> <br />
          Our team of experienced wedding planners and designers will work
          closely with you to understand your vision and bring it to life.
          We&#39;ll take care of all the details, so you can relax and enjoy
          your special day with your loved ones. Whether you&#39;re planning an
          intimate wedding or a grand celebration, we have the expertise and
          resources to make it happen. So why wait? Contact us today and let us
          help you create a wedding day that you and your guests will cherish
          forever.{' '}
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://www.shaadidukaan.com/vogue/wp-content/uploads/2019/10/Wedding-Photography-Poses.jpg"
              alt="Wedding Planners in Bangalore"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              Why Choose a Wedding Planner in Bangalore?
            </h2>
            <p class="text-lg mb-5">
              Bangalore, known as the Silicon Valley of India, is not only a hub
              for technology but also a vibrant city bustling with cultural
              diversity and rich traditions. With its picturesque locations and
              exquisite venues, Bangalore has become a sought-after destination
              for weddings. However, navigating the bustling wedding industry in
              Bangalore can be challenging, which is why hiring a wedding
              planner is highly recommended.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Experience and Expertise - Wedding Planner in Bangalore
              </h3>
              <p>
                We bring a wealth of experience and expertise to the table. They
                understand the intricacies of planning a wedding in the city and
                have extensive knowledge of local vendors and suppliers. Their
                professionalism and attention to detail ensure that every aspect
                of the wedding is meticulously planned and executed to
                perfection.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Vendor Network - Wedding Planners in Bangalore
              </h3>
              <p>
                One of the key advantages of hiring a wedding planner in
                Bangalore is their extensive network of vendors and suppliers.
                From florists and photographers to caterers and decorators,
                wedding planners have established relationships with trusted
                professionals in the industry. This not only guarantees access
                to top-notch services but also enables them to negotiate the
                best deals on behalf of their clients, ensuring
                cost-effectiveness without compromising on quality.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Customization and Personalization
              </h3>
              <p>
                Every couple has their own unique vision for their wedding day,
                and we as an event planner understand the importance of
                personalization. We work closely with their clients to
                understand their preferences, style, and budget, and tailor
                their services accordingly. Whether it's a traditional South
                Indian wedding or a modern fusion ceremony, wedding planners
                ensure that every detail reflects the couple's personality and
                tastes.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Budget Management</h3>
              <p>
                Managing a wedding budget can be challenging, with expenses
                quickly adding up. Wedding planners in Bangalore excel in
                efficient budget allocation and management, helping couples make
                the most of their financial resources. By prioritizing expenses
                and identifying areas where costs can be minimized, they ensure
                that the wedding stays within budget without compromising on
                quality or elegance.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Venue Selection by wedding planners
              </h3>
              <p>
                Choosing the perfect venue is one of the most important
                decisions in wedding planning. We offer expert assistance in
                selecting a venue that matches the couple's vision and
                requirements. Whether it's a luxurious banquet hall, a
                picturesque outdoor setting, or an intimate rooftop garden, they
                help couples find the ideal location for their special day.
              </p>
            </div>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Logistics and Coordination</h3>
              <p>
                Coordinating the various aspects of a wedding requires
                meticulous planning and organization. We handle all logistics,
                from coordinating vendor deliveries to creating detailed
                timelines and schedules. Our efficient coordination ensures that
                everything runs smoothly on the day of the wedding, allowing the
                couple to relax and enjoy their celebration without any worries.
              </p>
            </div>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Design and Decor by wedding planners
              </h3>
              <p>
                Creating a stunning ambiance is essential to setting the mood
                for a wedding. We offer creative design concepts and themes that
                transform venues into magical settings. From elegant floral
                arrangements to stylish decor accents, they work closely with
                decorators and designers to bring the couple's vision to life,
                ensuring a memorable and visually stunning event.
              </p>
            </div>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Entertainment and Hospitality from wedding planners in bangalore
              </h3>
              <p>
                Entertainment plays a crucial role in keeping guests engaged and
                entertained throughout the wedding festivities. We arrange a
                variety of entertainment services, from live music and dance
                performances to interactive games and activities. Additionally,
                they oversee guest hospitality, ensuring that all attendees feel
                welcome and well-cared for throughout the celebration.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                On-the-Day Management as a wedding planner in bangalore
              </h3>
              <p>
                On the day of the wedding, everything must come together
                seamlessly to create a memorable experience for the couple and
                their guests. Footsypop Events as a Wedding planner in Bangalore
                take charge of on-the-day management, overseeing setup,
                coordinating vendors, and resolving any last-minute issues that
                may arise. Their calm and efficient handling of logistics
                ensures that the wedding day unfolds flawlessly from start to
                finish.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Post-Wedding Services from wedding planners in bangalore
              </h3>
              <p>
                Even after the celebration is over, we continue to provide
                support and assistance to their clients. Whether it's
                coordinating post-event cleanup, managing vendor payments, or
                collecting feedback from guests, they ensure that all loose ends
                are tied up smoothly. Our dedication to client satisfaction
                extends beyond the wedding day, ensuring a truly stress-free
                experience for the newlyweds.
              </p>
            </div>

            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          Hiring a wedding planner in Bangalore is the key to transforming your
          dream wedding into a reality. With their experience, expertise, and
          attention to detail, they take the stress out of wedding planning,
          allowing couples to focus on creating memories that will last a
          lifetime. From venue selection to on-the-day management, wedding
          planners in Bangalore handle every aspect of the wedding with
          professionalism and dedication, ensuring a flawless and unforgettable
          celebration.
          <br />
          <br /> At Footsypop Events Management Company, we believe that your
          wedding day should be an extraordinary experience filled with love,
          joy, and unforgettable memories. Trust us to bring your vision to life
          and create a celebration that exceeds your expectations. Contact us
          today to start planning your dream wedding. Let Footsypop be your
          trusted partner in crafting a wedding that will be cherished for a
          lifetime.Choose Footsypop Events Management Company as your wedding
          planner in bangalore and make your wedding an unforgettable
          celebration of love and togetherness.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default WeddingPlanners
