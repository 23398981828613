import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import Home from './Home'
import About from './About'
import Contact from './Contact'
import NotFound from './NotFound'
import Productpage from './Productpage'
import Birthdays from './ProductList/Birthdays'
import Anniversary from './ProductList/Anniversary'
import Decorations from './ProductList/Decorations'
import DinnerParties from './ProductList/DinnerParties'
import DjEvents from './ProductList/DjEvents'
import Festive from './ProductList/Festive'
import Corporate from './ProductList/Corporate'
import WeddingPlanners from './ProductList/WeddingPlanners'
import PrivacyPolicy from './PrivacyPolicy'
import TermsandCondition from './TermsandCondtion'
import RefundPolicy from './RefundPolicy'
import ShippingPolicy from './ShippingPolicy'
import Housewarming from './ProductList/Housewarming'
import BabyShower from './ProductList/BabyShower'
import BalloonDecorations from './ProductList/BalloonDecorationsinBangalore'
import CulturalEvents from './ProductList/CultralEvents'
import AdminHome from './admin/Home'
import AdminProducts from './admin/Products'
import KidsBirthdays from './ProductList/KidsBirthday'
import Orders from './admin/Orders'
import AdminLogin from './admin/AdminLogin'
import CheckoutPage from './CheckoutPage'
import OrderComplete from './OrderComplete'
import MyOrders from './components/MyOrder'
import MyOrderPage from './MyOrderPage'
import NamingCermonyBlog from './ProductList/NamingCeromonyBlog'
import NamingCermony from './ProductList/NamingCermony'
import SearchComp from './components/Search'
import BirthdayPlanners from './ProductList/BirthdayPlanners'
import AdminSliderPage from './admin/Slider'
import AdminCategories from './admin/QuickAccess'
import Leadstracker from './admin/LeadsTracker'
import QuickAccess from './admin/QuickAccess'
import AdminLeadTracker from './admin/LeadsTracker'
import UserMangement from './admin/UserManagement'
import CustomerData from './admin/Customer'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/search" component={SearchComp} />
        <Route exact path="/myOrders" component={MyOrderPage} />
        <Route path="/product/:productName" component={Productpage} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/products/Birthdays" component={Birthdays} />
        <Route path="/products/KidsBirthday" component={KidsBirthdays} />
        <Route path="/products/NamingCermony" component={NamingCermony} />

        <Route path="/products/Anninversary" component={Anniversary} />
        <Route path="/products/Decorations" component={Decorations} />
        <Route path="/products/DinnerParties" component={DinnerParties} />
        <Route path="/products/DjEvents" component={DjEvents} />
        <Route path="/products/Festive" component={Festive} />
        <Route path="/NamingCeremony" component={NamingCermonyBlog} />
        <Route path="/BirthdayPlanners" component={BirthdayPlanners} />
        <Route path="/Corporate" component={Corporate} />
        <Route path="/WeddingPlanners" component={WeddingPlanners} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/termsandcondition" component={TermsandCondition} />
        <Route path="/refundpolicy" component={RefundPolicy} />
        <Route path="/shippingpolicy" component={ShippingPolicy} />
        <Route path="/HouseWarming" component={Housewarming} />
        <Route path="/BabyShower" component={BabyShower} />
        <Route path="/BalloonDecorationsinBangalore" component={BalloonDecorations} />
        <Route path="/CulturalEvents" component={CulturalEvents} />
        <Route path="/Checkout/:orderId" component={CheckoutPage} />
        <Route path="/Ordersuccessful" component={OrderComplete} />

        <Route exact path="/admin" component={AdminLogin} />
        <Route exact path="/admin/home" component={AdminHome} />
        <Route path="/admin/products" component={AdminProducts} />
        <Route path="/admin/orders" component={Orders} />
        <Route path="/admin/slider" component={AdminSliderPage} />
        <Route path="/admin/quickaccess" component={QuickAccess} />
        <Route path="/admin/leadstracker" component={AdminLeadTracker} />

        <Route path="/admin/usermanagement" component={UserMangement}></Route>

        <Route path="/admin/customer" component={CustomerData} />

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
