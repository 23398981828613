import Header from "./components/Header";
function NotFound() {
  return (
    <div className="App">
        404 Not Found
    </div>
  );
}

export default NotFound;
