import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductList from "./ProductList";




function DjEvents() {
  return (
    <div className="App">
        <Header></Header>
        <ProductList name={"Dj Events"}></ProductList>
        <Footer></Footer>
    </div>
  );
}

export default DjEvents;
