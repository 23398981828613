import React from 'react';

const PrivacyPolicyCon = () => {
  return (
    <div className="max-w-4xl mt-10 mb-10 mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">
        At Footsypop Events, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your information when you visit our website or use our services.
      </p>
      <h3 className="text-lg font-medium mb-2">Information We Collect</h3>
      <p className="mb-4">
        We may collect personal information, such as your name, email address, phone number, and event details, when you contact us to inquire about our services or when you use our services. We may also collect non-personal information, such as your IP address and browsing history, when you visit our website.
      </p>
      <h3 className="text-lg font-medium mb-2">How We Use Your Information</h3>
      <p className="mb-4">
        We use your personal information to provide you with our services, communicate with you about your event, and improve our services. We may also use your information for marketing purposes, such as sending you promotional emails or newsletters. You may opt-out of receiving marketing emails from us at any time.
      </p>
      <h3 className="text-lg font-medium mb-2">Disclosure of Your Information</h3>
      <p className="mb-4">
        We may share your personal information with our partners and vendors to provide you with our services. We may also disclose your information if required by law or to protect our rights or the rights of others.
      </p>
      <h3 className="text-lg font-medium mb-2">Security of Your Information</h3>
      <p className="mb-4">
        We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee the security of your information.
      </p>
      <h3 className="text-lg font-medium mb-2">Third-Party Links</h3>
      <p className="mb-4">
        Our website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these websites or services.
      </p>
      <h3 className="text-lg font-medium mb-2">Changes to Our Privacy Policy</h3>
      <p className="mb-4">
        We may update our Privacy Policy from time to time. If we make material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective. Your continued use of our website or services after the effective date of the updated Privacy Policy constitutes your acceptance of the updated policy.
      </p>
      <h3 className="text-lg font-medium mb-2">Contact Us</h3>
      <p>
        If you have any questions or concerns about our Privacy Policy or our use of your personal information, please contact us at <a href="mailto:contact@footsypopevents.com" className="underline">contact@footsypopevents.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicyCon;
