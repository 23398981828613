import { addDoc, collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';

const AddCustomerModal = ( {onClose, lead} ) => {
  const [leadKey,setLeadKey] = useState(lead.key);
  const [leadName,setLeadName] = useState(lead.name);
  const [leadTimesnapshot,setLeadTimesnapshot] = useState(lead.timestamp);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLead, setSelectedLead] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [serviceName, setServiceName] = useState('');
  const [vendorCost, setVendorCost] = useState(0);
  const [serviceProfit, setServiceProfit] = useState(0);


  const handleSearch = async (searchText) => {
    const db = getFirestore();
    const leadsCollectionRef = collection(db, 'leads','2023','All');
  
    try {
      const querySnapshot = await getDocs(
        query(
          leadsCollectionRef,
          where('name', '>=', 'Deep'), // Filter by name
          where('status', '==', 'Completed'), // Filter by status
          limit(2)
        )
      );
        console.log(querySnapshot.docs);
      querySnapshot.forEach((doc) => {
        // Access the matching documents
        const data = doc.data();
        console.log('Matching document:', data);
      });
    } catch (error) {
      console.error('Error searching leads:', error);
    }
  };

  const handleAddRow = () => {
    // Add a new row to the customer data table
    setCustomerData((prevData) => [
      ...prevData,
      {
        serviceName: serviceName,
        vendorCost: vendorCost,
        serviceProfit: serviceProfit,
        totalCost: vendorCost + serviceProfit,
      },
    ]);

    // Clear input fields for the next row
    setServiceName('');
    setVendorCost(0);
    setServiceProfit(0);
  };

  const handleRemoveRow = (index) => {
    // Remove a row from the customer data table
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const handleSaveCustomer = async () => {
    const db = getFirestore();
    const timestamp = leadTimesnapshot; // No need to convert to a Date object

    const year = timestamp.toDate().getFullYear(); // Get the year
    const month = timestamp.toDate().getMonth() + 1;
    console.log(year,month);

    const customerCollectionRef = collection(db, `customers/${year}/${month}`);

  
    try {
      const newCustomerDocRef = await addDoc(customerCollectionRef, {
        leadKey: leadKey,
        customerName: leadName,
        customerData: customerData,
      });
  
      console.log('Customer data saved with ID: ', newCustomerDocRef.id);
    } catch (error) {
      console.error('Error saving customer data:', error);
    }
    onClose(false);
  };
  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-full lg:w-1/2">
        <h2 className="text-xl font-bold mb-4">Create Customer</h2>
        <div className="mb-4">
        <h3 className='text-l font-semibold'> Customer Name : {leadName}</h3> 
        </div>
        {selectedLead && (
          <div>
            {/* Display lead details from selectedLead */}
            <p>Lead Name: {selectedLead.name}</p>
            <p>Event Date: {selectedLead.eventDate}</p>
          </div>
        )}
        <div className="mb-4">
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Service Name</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Vendor Cost</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Service Profit</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Total Cost</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {customerData.map((row, index) => (
                <tr key={index}>
                  <td className="px-6 py-3 border">
                    <input
                      type="text"
                      value={row.serviceName}
                      onChange={(e) => handleEditServiceName(index, e.target.value)}
                      className="px-3 py-2 border rounded w-full"
                    />
                  </td>
                  <td className="px-6 py-3 border">
                    <input
                      type="number"
                      value={row.vendorCost}
                      onChange={(e) => handleEditVendorCost(index, e.target.value)}
                      className="px-3 py-2 border rounded w-full"
                    />
                  </td>
                  <td className="px-6 py-3 border">
                    <input
                      type="number"
                      value={row.serviceProfit}
                      onChange={(e) => handleEditServiceProfit(index, e.target.value)}
                      className="px-3 py-2 border rounded w-full"
                    />
                  </td>
                  <td className="px-6 py-3 border">{row.totalCost}</td>
                  <td className="px-6 py-3 border">
                    <button
                      onClick={() => handleRemoveRow(index)}
                      className="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
            <button
              onClick={handleAddRow}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Add Row
            </button>
          </div>
        </div>
        <div className="mb-4">
          <table className="w-full table-auto">
            <tbody>
              <tr>
                <td className="px-6 py-3 border">Total:</td>
                <td className="px-6 py-3 border">
                {customerData.reduce((total, row) => total + row.vendorCost, 0)}
                </td>
                <td className="px-6 py-3 bg-green-400 border">
                {customerData.reduce((total, row) => total + row.serviceProfit, 0)}

                </td>
                <td className="px-6 py-3  border">
                  {customerData.reduce((total, row) => total + row.totalCost, 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <button
            onClick={()=>handleSaveCustomer()}
            className="bg-green-500 text-white px-4 py-2 rounded mr-2"
          >
            Save Customer
          </button>
          <button
            onClick={()=> onClose(false)}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  // Helper functions for handling row edits
  function handleEditServiceName(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].serviceName = value;
      return updatedData;
    });
  }

  function handleEditVendorCost(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].vendorCost = parseFloat(value);
      updatedData[index].totalCost =
        parseFloat(value) + updatedData[index].serviceProfit;
      return updatedData;
    });
  }

  function handleEditServiceProfit(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].serviceProfit = parseFloat(value);
      updatedData[index].totalCost =
        updatedData[index].vendorCost + parseFloat(value);
      return updatedData;
    });
  }
};

export default AddCustomerModal;
