import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MyOrders from "./components/MyOrder";
import { getAuth } from 'firebase/auth';
import firebaseConfig from './firebaseconfig';
import { initializeApp } from 'firebase/app';


const app = initializeApp(firebaseConfig);
const auth = getAuth();

function MyOrderPage() {
  
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState("");
  
  

  useEffect(() => {
    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
        const currentUser = auth.currentUser;
        const userId = currentUser.uid;
        setUserId(userId);
      } else {
        setLoggedIn(false);
      }
    });

    // Cleanup the auth state change listener
    return () => unsubscribe();
  }, []);



  return (
    <>
    <Header></Header>
    <MyOrders isLoggedIn={isLoggedIn} userId={userId}></MyOrders>
    <Footer></Footer>
    </>
  );
}

export default MyOrderPage;
