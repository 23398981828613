import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Balloon Decorations in Bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBalloon%20Decorations%2FBalloon%20Decorations%201.png?alt=media&token=79b212f8-d3b4-45d7-a3af-bd354c133cee',
  },
  {
    title: 'Balloon Decorations in Bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBalloon%20Decorations%2FBalloon%20Decorations%202.jpg?alt=media&token=da3ed191-36fe-4a73-b2fe-90c25ab8e94d',
  },
  {
    title: 'Balloon Decorations in Bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBalloon%20Decorations%2FBalloon%20Decorations%203.jpg?alt=media&token=8cd287f0-e346-4179-ab41-f5e94e4b2027',
  },
  {
    title: 'Balloon Decorations in Bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBalloon%20Decorations%2FBalloon%20Decorations%204.png?alt=media&token=c881087d-2470-4be9-b13d-436e7c7a778f',
  },
]

function BalloonDecorations() {
  return (
    <div className="App">
      <Helmet>
        <title>Balloon Decorations in Bangalore</title>
        <meta name="title" content="Balloon Decorations in Bangalore" />
        <meta
          name="description"
          content="Transform your celebration with exquisite balloon decorations in Bangalore! Elevate your birthday party with stunning balloon arrangements with style. "
        />
        <meta property="og:title" content="Balloon Decorations in Bangalore" />
        <meta
          property="og:description"
          content="Transform your celebration with exquisite balloon decorations in Bangalore! Elevate your birthday party with stunning balloon arrangements with style. "
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold mb-4">
          Balloon Decorations in Bangalore
        </h1>
      </div>
      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l font-semibold text-gray-600 leading-7">
          Balloon decorations have become an essential part of any event, adding
          vibrancy, color, and a touch of whimsy to various occasions. From
          birthday parties to corporate events and weddings, balloon decorations
          have the power to transform any space into a magical setting. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
          <br /> <br />
          Transform your celebration with exquisite balloon decorations in
          Bangalore! Elevate your birthday party with stunning balloon
          arrangements tailored to your theme and style. Discover expert balloon
          decoration services to make your special day unforgettable.
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Balloon Decorations in Bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l font-semibold text-gray-600 leading-7">
          Importance of Balloon Decorations for Events: Balloon decorations play
          a crucial role in setting the ambiance and theme of an event. Whether
          it's creating an enchanting entrance with a balloon arch or adding
          flair to tables with balloon centerpieces, these decorations instantly
          captivate guests and make the event memorable. Be it birthday party or
          corporate event, balloon decorations are necessary to make it vibrant
          and make more colorful!
        </p>
        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2FBalloon%20Decorations%2FBalloon%20Decorations.jpg?alt=media&token=13a06924-1af4-448a-adb1-a3f213e32bac"
              alt="Balloon Decorations in Bangalore"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-3">
              Benefits of Hiring a Professional for Balloon Decorations in Bangalore
            </h2>
            <p class="mb-3">
              While DIY balloon decorations may seem tempting, hiring a
              professional ensures flawless execution and attention to detail.
              Professionals like Footsypop Events have the expertise and
              creativity to bring your vision to life, creating stunning balloon
              decorations that wow guests and leave a lasting impression.
            </p>
            <h2 class="text-2xl font-bold mb-5">
              Types of Balloon Decorations in bangalore:
            </h2>
            <p class="mb-3">
              Balloons are incredibly versatile and can be used in various ways
              to enhance the ambiance of any event. Here are some popular types
              of balloon decorations
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Balloon Arches </h3>
              <p>
                Balloon arches are perfect for creating a grand entrance or
                highlighting key areas of the venue. They come in various styles
                and colors, allowing for customization to suit the theme of the
                event.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Balloon Columns</h3>
              <p>
                Balloon columns are versatile decorations that can be used to
                frame doorways, line walkways, or adorn stages. They add height
                and visual interest to any space, making them ideal for both
                indoor and outdoor events.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Balloon Centerpieces</h3>
              <p>
                Balloon centerpieces add a pop of color and whimsy to tables,
                serving as focal points for guests to admire. Whether it's a
                simple balloon bouquet or an elaborate centerpiece design, these
                decorations enhance the overall look of the event.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Balloon Bouquets</h3>
              <p>
                Balloon bouquets are versatile decorations that can be used to
                decorate various areas of the venue, from tables to ceilings.
                They add a playful touch to the event and can be customized to
                match any theme or color scheme.
              </p>
            </div>
            <h2 class="text-2xl font-bold mb-3">
              Footsypop Events: Your Ultimate Destination for Balloon
              Decorations in Bangalore
            </h2>
            <p class="mb-3">
              When it comes to balloon decorations in Bangalore, Footsypop
              Events stands out as a premier destination for exceptional decor
              and top-notch service. With years of experience and a passion for
              creativity, Footsypop Events has established itself as a leader in
              the industry, catering to a diverse range of events and clients.
            </p>
            <h2 class="text-2xl font-bold mb-3">
              Services Offered by Footsypop Events
            </h2>
            <p class="mb-3">
              At Footsypop Events, we offer a wide range of balloon decorations in bangalore
              to meet your needs and exceed your expectations. Whether
              you're planning a birthday party, corporate event, wedding, or any
              other special occasion, we have the expertise and resources to
              bring your vision to life.
            </p>

            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Birthday Party Decorations</h3>
              <p>
                Make your child's birthday party truly unforgettable with our
                bespoke balloon decorations. From themed decor to custom balloon
                sculptures, we'll create a magical setting that delights both
                children and adults alike.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Corporate Event Decorations
              </h3>
              <p>
                Impress clients, employees, and guests with our sophisticated
                balloon decorations for corporate events. Whether it's a product
                launch, conference, or office party, we'll design stunning decor
                that reflects your brand and leaves a lasting impression.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Wedding Decorations</h3>
              <p>
                Elevate your wedding day with our exquisite balloon decorations.
                From elegant arches to romantic centerpieces, we'll add a touch
                of whimsy and charm to your special day, creating beautiful
                memories that last a lifetime.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Custom Balloon Decorations</h3>
              <p>
                Looking for something unique? Our team specializes in custom
                balloon designs tailored to your specific needs and preferences.
                Whether it's a corporate logo, personalized message, or
                intricate sculpture, we'll bring your ideas to life with
                creativity and precision.
              </p>
            </div>
            <h2 class="text-2xl font-bold mb-3">
              Why Choose Footsypop Events as your balloon decorator in bangalore?
            </h2>
            <p class="mb-3">
              <strong>Expertise:</strong> Our team of experienced professionals
              has a keen eye for design and a passion for creativity, ensuring
              impeccable results every time.
            </p>
            <p class="mb-3">
              <strong>Quality:</strong> We use only the highest quality balloons
              and materials to ensure durability, longevity, and stunning visual
              impact.
            </p>
            <p class="mb-3">
              <strong>Customer Service:</strong> We prioritize customer
              satisfaction and go above and beyond to exceed expectations, from
              initial consultation to event execution.
            </p>
            <p class="mb-3">
              <strong>Creativity:</strong> We thrive on innovation and love
              pushing the boundaries of balloon art, creating one-of-a-kind
              designs that wow and inspire.
            </p>
            <p class="mb-3">
              <strong>Reliability:</strong> You can count on us to deliver on
              time and within budget, with meticulous attention to detail and
              seamless execution.
            </p>

            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>

        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          In conclusion, balloon decorations are not just accessories; they are
          storytellers that bring events to life with color, charm, and joy.
          Whether it's a birthday party, corporate gathering, or wedding
          celebration, the right balloon decor sets the tone and creates
          unforgettable memories for guests. At Footsypop Events, we understand
          the importance of these moments and strive to make every event
          extraordinary.
          <br /> <br />
          Our commitment to creativity, quality, and customer satisfaction sets
          us apart as the premier destination for balloon decorations in
          Bangalore. From custom designs to flawless execution, we go above and
          beyond to exceed your expectations and ensure a seamless, stress-free
          experience from start to finish. <br />
          <br />
          So why settle for ordinary when you can have extraordinary? Elevate
          your next event with Footsypop Events and let us transform your vision
          into reality. Contact us today to book your consultation and take the
          first step towards creating a truly magical event experience.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default BalloonDecorations
