import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';

// Service data array with images
const servicesData = [
  {
    name: 'Venue Management',
    description: 'We excel in sourcing, securing, and managing venues that perfectly align with the theme and requirements of your corporate event. From grand banquet halls to intimate meeting spaces, we ensure your event venue reflects the essence of your occasion.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_002-1024x1024.webp',
  },
  {
    name: 'Security Management',
    description: 'Safety is paramount in any event. Our team of experienced professionals ensures seamless security arrangements, from crowd control to emergency protocols, to guarantee a worry-free experience for all attendees.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_003-1024x1024.webp',
  },
  {
    name: 'Event Branding',
    description: 'Capture attention and leave a lasting impression with our comprehensive event branding solutions. From logo placement to customized signage, we elevate your brand visibility and ensure consistent messaging throughout your event.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_004-1024x1024.webp',
  },
  {
    name: 'Logistics Management',
    description: 'Leave the hassle of logistics to us. Our experts handle everything from transportation and accommodation to catering and equipment, ensuring smooth operations behind the scenes, so you can focus on your event objectives.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_006-1024x1024.webp',
  },
  {
    name: 'Event Production',
    description: 'Transform your vision into reality with our expert event production services. From stage design to audiovisual setups, we bring creativity and technical expertise together to create captivating experiences for your audience.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_003-2-1024x1024.webp',
  },
  {
    name: 'Onsite Registration',
    description: 'Streamline attendee registration and check-in processes with our efficient onsite registration solutions. We leverage technology and experienced staff to ensure a hassle-free entry experience for all attendees.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_005-1024x1024.webp',
  },
  {
    name: 'Budget Management',
    description: 'Stay within budget without compromising on quality. Our meticulous budget management services help you allocate resources wisely, making the most of your investment and delivering exceptional value for your event.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_003-3-1024x1024.webp',
  },
  {
    name: 'Technology Integration',
    description: 'Harness the power of technology to enhance your event experience. From interactive displays to live streaming, we leverage cutting-edge technology solutions to engage your audience and amplify your message.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_003-1-1024x1024.webp',
  },
  {
    name: 'Content Management',
    description: 'Deliver compelling content that resonates with your audience. Our content management services cover everything from scriptwriting to presentation design, ensuring your message is clear, impactful, and memorable.',
    image: 'https://zealintegrated.com/wp-content/uploads/2024/06/Image_003-3-1-1024x1024.webp',
  }
];

const ServicesOffered = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Mimicking an async fetch call
    const fetchServices = async () => {
      try {
        // Simulate a fetch request
        const fetchedServices = servicesData;
        setServices(fetchedServices);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white">
      <h1 className="text-3xl font-bold text-center mt-8 mb-4">Services Offered by Footsypop Events</h1>
      <p className="text-center text-lg mb-8 px-4">
        For Corporate Event Management in Bangalore, Footsypop Events understands the significance of flawless execution when it comes to corporate events. Our comprehensive range of services ensures that every aspect of your event is meticulously planned and executed to perfection. Here’s how we can make your corporate event in Bangalore a resounding success:
      </p>
      <div className="flex flex-wrap justify-center p-4">
        {services.map((service, index) => (
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-3 py-3" key={index}>
            <div className="border rounded-lg p-4 shadow-lg">
              <div className="h-48 bg-gray-200 flex items-center justify-center mb-4">
                <img src={service.image} alt={service.name} className="h-full w-full object-cover" />
              </div>
              <h2 className="text-xl font-semibold mb-2">{service.name}</h2>
              <p className="text-gray-700">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesOffered;
