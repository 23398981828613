import React from 'react';
import 'tailwindcss/tailwind.css';

export default function IntroComponent() {
  return (
    // <div className="max-w-10xl mx-auto p-6 bg-white">
      <div className="max-w-7xl mx-auto p-6 bg-white">
      <h1 className="text-4xl mt-3 mr-2 ml-2 font-bold mb-4 text-indigo-600">
        Welcome To Footsypop Events
      </h1>
      <div className="w-16 h-1 bg-indigo-600 mb-6"></div>

      <h2 className="text-2xl mt-1 mr-2 ml-2 font-semibold mb-6 text-gray-800">
        Best Event Management Company In Bangalore
      </h2>
      <div className="mb-6">
        <p className="text-lg font-medium text-gray-700 leading-relaxed">
          Welcome to Footsypop Events, the top event management company in Bangalore, specializing in crafting unforgettable experiences. We are recognized as the best corporate event management company for our innovative approach and meticulous attention to detail. Our mission is to transform your vision into a spectacular event that resonates and leaves a lasting impact.
        </p>
      </div>
      </div>
      
  
     
    // </div>
  );
}
