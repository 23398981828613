import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'

const files = [
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://www.raah.org.in/wp-content/uploads/2019/10/celebration-crowd-event-2283996.jpg',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://dypds.com/assets/images/gallery/casa-da-fiesta-2022/day-1/1.JPG',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://www.holidify.com/images/cmsuploads/compressed/namma_bengaluru1_newsk_17_20180430185214.jpeg',
  },
  {
    title: 'House Warming',
    size: '3.9 MB',
    source:
      'https://www.cheapflights.co.uk/news/wp-content/uploads/sites/138/2016/03/march-s-must-go-to-events-around-the-world-01.jpg',
  },
]

function CulturalEvents() {
  return (
    <div className="App">
      <Header></Header>
      <Slider></Slider>

      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold">Cultural Event Planners</h1>
      </div>
      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l  font-semibold text-gray-600 leading-7">
          At Footsypop Events, we understand the importance of cultural events
          in bringing people together and celebrating traditions. Whether you're
          planning a religious ceremony, a community festival, or a cultural
          program, we're here to help you create a truly memorable event that
          reflects your unique style and cultural identity. Our team of
          experienced event planners and designers will work closely with you to
          understand your vision and help you bring it to life. We can help you
          with everything from venue selection, decor, lighting, catering, and
          entertainment, to ensure that your cultural event is a success.
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="cultural events in bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l mt-10 mb-10 font-semibold text-gray-600 leading-7">
          We believe that every cultural event should be an immersive and
          engaging experience for all participants. That's why we offer a range
          of services to help you create a memorable and interactive program
          that will leave a lasting impression on your guests. So why wait?
          Contact us today and let us help you create a cultural event that will
          celebrate your heritage, bring people together, and create lasting
          memories. We're passionate about cultural events and can't wait to
          work with you to create a truly unforgettable experience.
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://images.unsplash.com/photo-1617184003170-1f266c325ff3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG9saSUyMGZlc3RpdmFsfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
              alt="Cultural Event"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              How We Can Help with Your Cultural Event
            </h2>
            <p class="text-lg mb-5">
              At Footsypopo Events, we understand that cultural events are an
              important way to celebrate your heritage and bring people
              together. Whether it's a festival, a wedding, or a religious
              ceremony, we are here to help you create a memorable and authentic
              cultural experience.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Theme and Decor</h3>
              <p>
                The theme and decor of your cultural event can set the tone and
                create a memorable experience for your guests. Our team of
                experienced decorators can help you design a theme and decor
                that reflects your cultural heritage and creates a warm and
                inviting atmosphere for your guests.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Traditional Attire</h3>
              <p>
                Wearing traditional attire is an important way to honor your
                cultural heritage at a cultural event. We can provide guidance
                on appropriate attire and help you arrange for attire rentals or
                purchases for you and your guests.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Cultural Performances</h3>
              <p>
                Cultural performances, such as traditional dances or music, can
                be a highlight of your event. We can help you arrange for
                authentic and talented performers to entertain your guests and
                bring your cultural experience to life.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Catering</h3>
              <p>
                Food is an important part of any cultural event, and we can
                provide catering services that feature traditional cuisine from
                your culture. We can also arrange for food stations that offer a
                variety of options for your guests to try.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Event Planning and Management
              </h3>
              <p>
                Planning a cultural event can be complex and time-consuming, but
                our experienced event planners are here to help. From
                coordinating vendors to managing logistics, we will work with
                you to ensure that your cultural event is a success and stays
                true to your heritage.
              </p>
            </div>
            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default CulturalEvents
