import { useState, useEffect } from 'react';
import { Disclosure, RadioGroup, Tab } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ref, onValue, getDatabase } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';
import Loading from './Loading';
import { getFirestore, collection, setDoc, doc, getDoc,where ,query ,deleteDoc, onSnapshot } from 'firebase/firestore';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import StickyBottomBar from './StickyBottomBar';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductImage({productKey, booking,productCategory, productN}) {
  const [selectedColor, setSelectedColor] = useState(null);
  const [product, setProduct] = useState(null);
  const [isImageSliderOpen, setImageSliderOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isMobile,setIsMobile] = useState(false);

  // ... (existing code)

  console.log(productKey);

  useEffect(()=>{
    if(window.screen.width<746){
      setIsMobile(true)
    }
    window.addEventListener("resize",(e) =>{
      if(e.target.innerWidth>746){
        setIsMobile(false)
      }else{
        setIsMobile(true);
      }
    })

  },[])

  const openImageSlider = (index) => {
    setImageSliderOpen(true);
    setSelectedImageIndex(index);
  };

  const closeImageSlider = () => {
    setImageSliderOpen(false);
    setSelectedImageIndex(0);
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
 
useEffect(() => {
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const productRef = doc(firestore, 'products', productKey);

  const unsubscribe = onSnapshot(productRef, (doc) => {
    if (doc.exists()) {
      const productData = doc.data();

      const formattedProduct = {
        id: productKey,
        name: productData.name,
        category: productData.category,
        originalPrice: productData.originalPrice,
        price: productData.price,
        images: productData.downloadUrls.map((url, index) => ({
          id: index + 1,
          name: 'Angled view',
          src: url,
          alt: 'Angled front view with bag zipped and handles upright.',
        })),
        colors: [
          // { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
          // { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
          // { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
        ],
        description: productData.description.split('\n'),
        details: [],
        rating: 4,
      };
      productN(formattedProduct.name);
      productCategory(formattedProduct.category)

      setProduct(formattedProduct);
      setSelectedColor(formattedProduct.colors[0]);
    } else {
      console.log('Product not found');
    }
  });

  return () => {
    unsubscribe();
  };
}, [firebaseConfig, productKey]);

  const handleBooking = (e) => {
    e.preventDefault();
    // Perform any validation or additional logic here
    // Submit the booking details to the server or perform any other necessary actions
    // Clear form fields
    booking();
  };
  
  
  
  
  
  
  

  if (!product) {
    return <Loading></Loading>; // Display a loading indicator while the product is being fetched
  }

  const imagesForGallery = product.images.map((image) => ({
    original: image.src,
    thumbnail: image.src, // You can provide thumbnails here if needed
    originalAlt: image.alt,
  }));



  return (
    <div className="bg-white">
          {isMobile && <StickyBottomBar productId={productKey}  ></StickyBottomBar>}

       {isImageSliderOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black z-50 flex items-center justify-center">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={closeImageSlider}
            aria-label="Close"
          >
            &times;
          </button>
          <ImageGallery
            items={product.images.map((image) => ({
              original: image.src,
              thumbnail: image.src,
              originalAlt: image.alt,
            }))}
            showThumbnails={false}
            startIndex={selectedImageIndex}
            onSlide={(currentIndex) => setSelectedImageIndex(currentIndex)}
            showPlayButton={false}
          />
        </div>
      )}
      <div className="mx-auto max-w-2xl mt-5 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            {/* Image gallery */}
            <Tab.Group
            as="div"
            className="flex flex-col-reverse w-full"
          >
            {/* Image selector */}
            <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product.images.map((image, index) => (
                  <Tab
                    key={image.id}
                    className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    // onClick={() => openImageSlider(index)}
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={image.src}
                            alt={image.alt}
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-indigo-500' : 'ring-transparent',
                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

             {/* Product images */}
             <div className="aspect-w-1 aspect-h-1 w-full">
              {product.images.map((image, index) => (
                <Tab.Panel key={image.id}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                    onClick={() => openImageSlider(index)}

                  />
                </Tab.Panel>
              ))}
            </div>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.name}</h1>

          <div className="mt-3">
            <h2 className="sr-only">Product information</h2>
            <div className="flex items-center">
              <p className="text-3xl tracking-tight text-gray-900 mr-2">₹{formatPrice(product.price)}</p>
              {product.originalPrice && (
                <p className="text-sm text-gray-500 line-through">₹{formatPrice(product.originalPrice)}</p>
              )}
            </div>
          </div>

            {/* Reviews */}
            <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        product.rating > rating ? 'text-indigo-500' : 'text-gray-300',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{product.rating} out of 5 stars</p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              {product.description.map((line, index) => (
                <p key={index} className="text-base text-gray-700">{line}</p>
              ))}
            </div>

            <form className="mt-6">
              {/* Colors */}
              <div>
                {/* <h3 className="text-sm text-gray-600">Color</h3> */}

                <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-2">
                  <RadioGroup.Label className="sr-only"> Choose a color </RadioGroup.Label>
                  <span className="flex items-center space-x-3">
                    {product.colors.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedColor,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        <RadioGroup.Label as="span" className="sr-only">
                          {' '}
                          {color.name}{' '}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.bgColor,
                            'h-8 w-8 border border-black border-opacity-10 rounded-full'
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </span>
                </RadioGroup>
              </div>

              <div className="sm:flex-col1 mt-10 flex">
                <button
                  onClick={handleBooking}
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  Book Now
                </button>

                <button
                  type="button"
                  className="ml-4 flex items-center justify-center rounded-md py-3 px-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <HeartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
                  <span className="sr-only">Add to favorites</span>
                </button>
              </div>
            </form>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>

              <div className="divide-y divide-gray-200 border-t">
                {product.details.map((detail) => (
                  <Disclosure as="div" key={detail.name}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                            <span
                              className={classNames(open ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-medium')}
                            >
                              {detail.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                          <ul role="list">
                            {detail.items.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
