import React from 'react';

const MobileCartView = ({ setCartOpen }) => {
  return (
    <div className="fixed inset-0 bg-white z-50">
      {/* Header */}
      <div className="absolute bg-gray-900 w-full top-0 left-0 p-4 flex items-center">
        <button
          onClick={() => setCartOpen(false)}
          className="focus:outline-none cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-white"
            viewBox="0 0 21 22"
          >
            <path
              fill="currentColor"
              d="M.27 9.992a1.289 1.289 0 0 0-.266.42L0 10.424l.004-.01c.061-.156.151-.3.267-.421Zm10.82-7.578-7.534 7.51h16.032a.989.989 0 0 1 0 1.977H3.555l7.536 7.512a.989.989 0 0 1 0 1.404l-.004.004h-.001a.988.988 0 0 1-1.404 0L.486 11.625a.988.988 0 0 1-.202-.32l-.007-.016a.988.988 0 0 1 0-.75l.007-.017a.989.989 0 0 1 .202-.32L9.68 1.006a.996.996 0 1 1 1.408 1.408Z"
            ></path>
          </svg>
        </button>
        <a className="flex text-white items-center mx-auto">
          Booking Cart
        </a>
      </div>
  
      <div className="pt-16">
        {/* Product */}
        <div className="flex flex-col bg-gray-100  w-full px-4 py-2">
          <div className="flex items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/images%2Fbabby.jpg?alt=media&token=167358d4-d384-4993-be0d-9b325d9940c8"
              alt="event planners in bangalore"
              className="h-12 w-12 rounded-full object-cover"
            />
            <div className="ml-3">
              <p className="text-gray-700 font-semibold">Dummy Product</p>
              <p className="text-gray-500">Price: ₹10,000</p>
              <p className="text-gray-500">Date: 12-03-2023</p>

            </div>
          </div>
          <button className="text-red-500 font-semibold">Remove</button>
        </div>
  
       
        <div className="bg-gray-100 px-4 py-2 border-t-2  pt-10">
        <div className="flex font-semibold justify-between">
          <p>Item(s) Total (Inclusive of taxes)</p>
          <p>₹4419.00</p>
        </div>
        <div className="flex text-green-500 font-semibold justify-between">
          <p>Discount</p>
          <p>₹1344.00</p>
        </div>
        <div className="flex font-semibold justify-between">
          <p>Service Charge</p>
          <p>₹0.00</p>
        </div>
        <div className="flex font-semibold justify-between">
          <p>Transportation</p>
          <p>Free</p>
        </div>
        <div className="flex font-semibold  justify-between border-t-2 mt-2 pt-2">
          <p>Order Total</p>
          <p>₹3075.00</p>
        </div>
      </div>


      
  
        {/*  Booking button */}
        <button
          className="bg-blue-500 text-white px-4 py-2 text-center fixed bottom-0 left-0 w-full"
          style={{ zIndex: 999 }}
        >
          Proceed to Booking
        </button>
      </div>

     
    </div>
  );
  
  
  
  
};

export default MobileCartView;
