import React from 'react';

const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="yellow"
  >
    <path
      fillRule="evenodd"
      d="M10 1l2.928 6.364H19.5l-5.616 4.636 2.165 6.773L10 15.455 3.951 19.37l2.165-6.773L.5 7.364h6.572L10 1z"
      clipRule="evenodd"
    />
  </svg>
);

// SVG for Google logo (example)
const GoogleLogo = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.728 11.986h-8.755v4.275h8.755c.4-1.847.16-3.92-.007-5.731zm-8.755-6.986c2.3 0 4.3.775 5.743 2.283l3.267-3.267c-2.382-2.24-5.66-3.626-9.01-3.626-3.343 0-6.432 1.37-8.622 3.575l3.233 3.233c1.413-3.044 4.414-5.167 7.929-5.167z"
      />
    </svg>
  );

const reviews = [
    {
      user: 'Sneha R.',
      review: 'Exceptional service and attention to detail. Footsypop Events made our corporate event a huge success!',
      stars: 5,
    },
    {
      user: 'Arjun K.',
      review: 'Professionalism at its best. They handled our conference with precision and creativity.',
      stars: 5,
    },
    {
      user: 'Deepika S.',
      review: 'Highly recommend Footsypop Events for any corporate event. They exceeded our expectations!',
      stars: 5,
    },
    {
      user: 'Rajesh V.',
      review: 'Outstanding event management. Our company’s annual event was flawless thanks to Footsypop Events.',
      stars: 5,
    },
    {
      user: 'Priya M.',
      review: 'Great experience working with Footsypop Events. They are truly experts in corporate event planning.',
      stars: 5,
    },
    {
      user: 'Karthik G.',
      review: 'Impressive service and creativity. Footsypop Events transformed our ideas into a memorable corporate event.',
      stars: 5,
    },
  ];

const GoogleReviewComponent = () => {
    return (
        <div className="bg-gray-100 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-8">Hear What Clients Have To Say About Footsypop Events</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {reviews.map((review, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md relative">
                  <div className="flex items-center mb-4">
                    <div className="bg-gray-200 w-12 h-12 flex items-center justify-center rounded-full text-gray-600 text-2xl font-bold">
                      {review.user.charAt(0)}
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold">{review.user}</h3>
                      <div className="flex items-center mt-1">
                        {Array.from({ length: review.stars }).map((_, i) => (
                          <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-700">{review.review}</p>
                  <div className="absolute top-2 right-2">
                <img src="https://steelbluemedia.com/wp-content/uploads/2019/06/new-google-favicon-512.png" alt="Google Logo" className="h-6 w-6" />
              </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
};

export default GoogleReviewComponent;
