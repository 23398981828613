import React from 'react'

const CircleCard = ({ name, imageSrc, link }) => {
  return (
    <div className="w-full max-w-sm rounded-lg shadow">
      <div className="flex justify-center px-5 pt-4">
        <div className="flex flex-col items-center pb-4">
          <img
            className="w-24 h-24 mb-0 rounded-full shadow-lg"
            src={imageSrc}
            alt="Event planners in Bangalore"
          />
          <h5 className="inline-flex items-center text-sm font-medium text-center text-teal-700 focus:ring-4 focus:outline-none md:mt-3 mb-1 text-2xl md:text-xl lg:text-lg xl:text-base 2xl:text-sm font-medium text-gray-900 dark:text-teal-700 sm:text-lg whitespace-nowrap">
            {name}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default CircleCard
