import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductNavigation from "../components/ProductNavigation";
import Slider from "../components/Slider";
import ProductList from "./ProductList";




function Birthdays() {
  return (
    <div className="App">
        <Header></Header>
        {/* <Slider></Slider> */}
        <ProductNavigation category={"Birthdays"}></ProductNavigation>
        <ProductList name={"Birthdays"}></ProductList>
        <Footer></Footer>
    </div>
  );
}

export default Birthdays;
