import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductList from "./ProductList";




function Anniversary() {
  return (
    <div className="App">
        <Header></Header>
        <ProductList name={"Anniversary"}></ProductList>
        <Footer></Footer>
    </div>
  );
}

export default Anniversary;
