import Footer from '../components/Footer'
import Header from '../components/Header'
import Slider from '../components/Slider'
import ProductList from './ProductList'
import { Helmet } from 'react-helmet'

const files = [
  {
    title: 'Naming Ceremony Organisers in Bangalore',
    size: '3.9 MB',
    source: 'https://www.bambooevents.co.in/images/gallery/baby-naming6.webp',
  },
  {
    title: 'Naming Ceremony Organisers in Bangalore',
    size: '3.9 MB',
    source:
      'https://firebasestorage.googleapis.com/v0/b/footsypop-3e30b.appspot.com/o/category%2Fnaming.jpg?alt=media&token=08c30051-8eff-4289-bf0f-150ae0ff2fbc',
  },
  {
    title: 'Naming Ceremony Organisers in Bangalore',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/474x/82/d7/24/82d724d326f8861875aab2483364d37c.jpg',
  },
  {
    title: 'Naming Ceremony Organisers in Bangalore',
    size: '3.9 MB',
    source:
      'https://i.pinimg.com/originals/1f/9d/7b/1f9d7bf341e02439fbc87198efae8427.jpg',
  },
]

function NamingCeremonyBlog() {
  return (
    <div className="App">
      <Helmet>
        <title>Naming Ceremony Organisers in Bangalore | Footsypop Events</title>
        <meta name="title" content="Naming Ceremony Organisers in Bangalore | Footsypop Events" />
        <meta
          name="description"
          content="Discover the best Naming Ceremony Organisers in Bangalore with Footsypop Events. Professional, stress-free services tailored to your needs. Book now!"
        />
        <meta
          property="og:title"
          content="Naming Ceremony Organisers in Bangalore | Footsypop Events"
        />
        <meta
          property="og:description"
          content="Discover the best Naming Ceremony Organisers in Bangalore with Footsypop Events. Professional, stress-free services tailored to your needs. Book now!"
        />
        <meta
          property="og:url"
          content="https://www.footsypopevents.com/NamingCermony"
        />
      </Helmet>
      <Header></Header>
      <Slider></Slider>
      <div className="text-center">
        <h1 className="text-3xl mt-5 font-bold mb-4">
          Naming Ceremony Organisers in Bangalore
        </h1>
      </div>
      <div class="pt-5 pb-5 px-4 py-4">
        <p class="text-l font-semibold text-gray-600 leading-7">
          Naming ceremonies are special occasions held to celebrate and formally
          introduce a newborn to friends, family, and the community. In many
          cultures, these ceremonies hold great significance as they mark the
          beginning of a child's journey in the world. Bangalore, being a city
          rich in traditions and culture, witnesses numerous naming ceremonies
          throughout the year. <a href='https://www.footsypopevents.com/'><strong>click here to see our other services!</strong></a>
        </p>

        <ul
          role="list"
          className="grid pt-5 pb-5 grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file) => (
            <li className="relative cursor-pointer">
              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  src={file.source}
                  alt="Naming Ceremony Organisers in Bangalore"
                  className="pointer-events-none object-cover object-center group-hover:opacity-75 h-full w-full"
                  style={{ aspectRatio: '355/224' }}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">{file.title}</span>
                </button>
              </div>
              {/* <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p> */}
              {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
            </li>
          ))}
        </ul>

        <p class="text-l font-semibold text-gray-600 leading-7">
          Organizing a naming ceremony involves meticulous planning and
          attention to detail. From selecting a venue to arranging decorations,
          catering, and entertainment, there are numerous tasks involved in
          ensuring the event runs smoothly. This is where naming ceremony
          organisers play a crucial role. They take the burden off your
          shoulders, allowing you to enjoy the occasion stress-free.
        </p>

        <div class="flex flex-col md:flex-row items-center justify-center gap-10 py-10 md:py-20 bg-gray-100">
          <div class="w-full md:w-1/2">
            <img
              src="https://runiq.in/wp-content/uploads/2022/01/Best-Cradle-Ceremony-Planners-Hyderabad.jpeg"
              alt="Naming ceremony organisers in bangalore"
              class="w-full h-full object-cover rounded-lg shadow-md"
            ></img>
          </div>
          <div class="w-full md:w-1/2">
            <h2 class="text-3xl font-bold mb-5">
              How We Can Help with Your Naming Ceremony
            </h2>
            <p class="text-lg mb-5">
              At Footsypop Events, we understand that a naming ceremony is a
              special and meaningful event for you and your family. It is a time
              when you officially introduce your new baby to your loved ones and
              give them a name. We are here to help you make your naming
              ceremony a memorable one.
            </p>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Venue Selection and Decor - Naming Ceremony Organisers</h3>
              <p>
                Choosing the right venue for your naming ceremony is crucial.
                Our team of experienced event planners can help you find the
                perfect venue that suits your style and budget. We can also
                assist with decorating the venue to create a warm and welcoming
                atmosphere for your guests.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Naming Ceremony Rituals and Prayers
              </h3>
              <p>
                The naming ceremony is usually accompanied by various rituals
                and prayers. Our team can help you with the selection and
                arrangement of the rituals and prayers to make them meaningful
                and memorable for you and your guests.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Mandap and Seating Arrangements - Naming Ceremony Organisers
              </h3>
              <p>
                The mandap is the focal point of any naming ceremony. Our team
                can help you design and decorate your mandap in a way that
                complements the rest of your decor and creates a beautiful focal
                point for the ceremony. We can also help you with the seating
                arrangements to ensure that all your guests have a comfortable
                and enjoyable experience.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Photography and Videography
              </h3>
              <p>
                Your naming ceremony is a special event that you'll want to
                remember for years to come. Our photography and videography team
                can capture all the special moments of the ceremony, so you can
                relive them whenever you want.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Food and Beverages</h3>
              <p>
                Food is an important part of any celebration, and your naming
                ceremony is no exception. Our catering team can provide a range
                of delicious food options to suit your taste and budget, whether
                you want traditional Indian cuisine or something more exotic. We
                can also provide a selection of beverages to keep your guests
                refreshed throughout the event.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Event Planning and Management - Naming Ceremony Organisers
              </h3>
              <p>
                Planning a naming ceremony can be overwhelming, but our
                experienced event planners are here to help. From creating the
                guest list to coordinating all the details of your event, we
                will be with you every step of the way to ensure that your
                naming ceremony is a success.
              </p>
            </div>
            <h2 class="text-3xl font-bold mb-5">
              Benefits of Hiring Footsypop Events for Your Naming Ceremony
            </h2>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Customization and Personalization by Naming Ceremony Organisers
              </h3>
              <p>
                Footsypop Events prides itself on its professionalism and
                commitment to excellence. Their team pays close attention to
                every detail, ensuring that your naming ceremony exceeds your
                expectations.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">Stress-Free Experience by Naming Ceremony Organisers</h3>
              <p>
                By entrusting Footsypop Events with the organization of your
                naming ceremony, you can enjoy a stress-free experience from
                start to finish. Their team takes care of all the logistics,
                allowing you to focus on celebrating this special milestone with
                your loved ones.
              </p>
            </div>
            <div class="mb-5">
              <h3 class="text-xl font-bold mb-3">
                Professionalism and Attention to Detail by Naming Ceremony Organisers
              </h3>
              <p>
                Footsypop Events prides itself on its professionalism and
                commitment to excellence. Their team pays close attention to
                every detail, ensuring that your naming ceremony exceeds your
                expectations.
              </p>
            </div>
            <div class="flex justify-center mt-5">
              <a
                href="https://api.whatsapp.com/send?phone=918904409694&text=Hello%2C%20"
                class="bg-gray-900  mt-5 text-white px-6 py-3 rounded-md hover:bg-gray-800 focus:bg-gray-800 focus:outline-none"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <p class="text-l mb-10 font-semibold text-gray-600 leading-7">
          Organizing a naming ceremony is a joyous occasion, but it can also be
          overwhelming without the right support. By entrusting Footsypop Events
          with the organization of your naming ceremony, you can rest assured
          that your special day will be memorable and stress-free. With our
          expertise, attention to detail, and commitment to excellence,
          Footsypop Events stands out as the premier choice for organizing your
          special day. Let Footsypop Events turn your vision into reality and
          create cherished memories that will last a lifetime. Contact us today
          to book your event and experience the difference with Footsypop
          Events.
        </p>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default NamingCeremonyBlog
