import Checkout from "./components/Checkout"
import Footer from "./components/Footer"
import Header from "./components/Header"

export default function CheckoutPage({ match }) {


    const orderId = match.params.orderId;

    console.log(orderId);


    return(
        <div>
            <Header></Header>
            <Checkout   orderKey={orderId} ></Checkout>
            <Footer></Footer>
        </div>
        
    )

}