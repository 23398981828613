import { addDoc, collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';

const ViewCustomerModal = ( {onClose, customer} ) => {
    console.log(customer)
  const [customerName,setcustomerName] = useState(customer.name);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedcustomer, setSelectedcustomer] = useState(null);
  const [customerData, setCustomerData] = useState(customer.data);
  const [serviceName, setServiceName] = useState('');
  const [vendorCost, setVendorCost] = useState(customer.totalProfit- customer.totalCost);
  const [serviceProfit, setServiceProfit] = useState(customer.totalProfit);



  const handcustomerdRow = () => {
    // Add a new row to the customer data table
    setCustomerData((prevData) => [
      ...prevData,
      {
        serviceName: serviceName,
        vendorCost: vendorCost,
        serviceProfit: serviceProfit,
        totalCost: vendorCost + serviceProfit,
      },
    ]);

    // Clear input fields for the next row
    setServiceName('');
    setVendorCost(0);
    setServiceProfit(0);
  };

  
  function formatIndianPrice(price) {
    // Check if the input is a valid number
    if (isNaN(price)) {
      return 'Invalid Price';
    }
  
    // Convert the number to Indian formatted string
    const formattedPrice = price.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
    });
  
    return formattedPrice;
  }

  const handleRemoveRow = (index) => {
    // Remove a row from the customer data table
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };


  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-full lg:w-1/2">
        <h2 className="text-xl font-bold mb-4">View Customer</h2>
        <div className="mb-4">
        <h3 className='text-l font-semibold'> Customer Name : {customerName}</h3> 
        </div>
        {selectedcustomer && (
          <div>
            {/* Display customer details from selectedcustomer */}
            <p>customer Name: {selectedcustomer.name}</p>
            <p>Event Date: {selectedcustomer.eventDate}</p>
          </div>
        )}
        <div className="mb-4">
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Service Name</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Vendor Cost</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Service Profit</th>
                <th className="px-6 py-3 bg-gray-200 text-gray-700 border">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {customerData.map((row, index) => (
                <tr key={index}>
                  <td className="px-6 py-3 border">
                  {row.serviceName}
                   
                  </td>
                  <td className="px-6 py-3 border">
                  {row.vendorCost}
                   
                  </td>
                  <td className="px-6 py-3 border">
                  {row.serviceProfit}
                  </td>
                  <td className="px-6 py-3 border">{row.totalCost}</td>
                 
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4">
          
          </div>
        </div>
        <div className="mb-4">
          <table className="w-full table-auto">
            <tbody>
              <tr>
                <td className="px-6 py-3 border">Total:</td>
                <td className="px-6 py-3 border">
                {formatIndianPrice(customerData.reduce((total, row) => total + row.vendorCost, 0))}
                </td>
                <td className="px-6 py-3 bg-green-400 border">
                {formatIndianPrice(customerData.reduce((total, row) => total + row.serviceProfit, 0))}

                </td>
                <td className="px-6 py-3  border">
                  {formatIndianPrice(customerData.reduce((total, row) => total + row.totalCost, 0))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <button
            onClick={()=> onClose(false)}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  // Helper functions for handling row edits
  function handleEditServiceName(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].serviceName = value;
      return updatedData;
    });
  }

  function handleEditVendorCost(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].vendorCost = parseFloat(value);
      updatedData[index].totalCost =
        parseFloat(value) + updatedData[index].serviceProfit;
      return updatedData;
    });
  }

  function handleEditServiceProfit(index, value) {
    setCustomerData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].serviceProfit = parseFloat(value);
      updatedData[index].totalCost =
        updatedData[index].vendorCost + parseFloat(value);
      return updatedData;
    });
  }
};

export default ViewCustomerModal;
