import React, { useEffect, useState } from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { ref, onValue, getDatabase } from 'firebase/database';
import firebaseConfig from '../firebaseconfig';
import { initializeApp } from 'firebase/app';

const app = initializeApp(firebaseConfig);
 const database = getDatabase();

const Reviews = ({ setReviewModal, productKey }) => {
  const [reviews, setReviews] = useState({
    average: 0,
    totalCount: 0,
    counts: [],
    featured: [],
  });

  useEffect(() => {
    // Assuming you have access to Firebase database and references
    const reviewsRef = ref(database,`products/${productKey}/reviews`);

    // Fetch reviews data from Firebase
    onValue(reviewsRef, (snapshot) => {
      console.log(snapshot.val());
      const reviewsData = snapshot.val();
      if (reviewsData) {
        const reviewsCount = [];
        let totalRatingCount = 0;
        let totalRatingSum = 0;

        // Calculate average, total count, and individual rating counts
        // Calculate average, total count, and individual rating counts
        for (let review in reviewsData) {
          const { name, rating, comment } = reviewsData[review];
          console.log(rating);
          const ratingValue = parseInt(rating); // Parse the rating as an integer
          const existingRatingIndex = reviewsCount.findIndex((item) => item.rating === ratingValue);

          if (existingRatingIndex !== -1) {
            // If the rating exists, increment the count at the corresponding index
            reviewsCount[existingRatingIndex].count += 1;
          } else {
            // If the rating does not exist, add a new entry
            reviewsCount.push({ rating: ratingValue, count: 1 });
          }
        
          totalRatingCount += 1;
          totalRatingSum += ratingValue;
        }
        console.log(reviewsCount);

        // Calculate average rating
        const averageRating = totalRatingCount > 0 ? totalRatingSum / totalRatingCount : 0;

        console.log(averageRating);
        // Retrieve featured reviews (assuming they are stored separately in the database)
        const featuredReviews = [];
        const reviewKeys = Object.keys(reviewsData); // Get an array of review keys
        
        // Iterate over the keys in reverse order and retrieve the latest three ratings
        for (let i = reviewKeys.length - 1; i >= Math.max(0, reviewKeys.length - 3); i--) {
          const key = reviewKeys[i];
          const { rating, comment, name } = reviewsData[key];
        
          featuredReviews.unshift({
            id: key,
            rating,
            content: `<p>${comment}</p>`,
            author: name,
            avatarSrc: 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=', // Replace with the appropriate URL for the avatar image
          });
        }

        // Update the reviews state
        setReviews({
          average: averageRating,
          totalCount: totalRatingCount,
          counts: reviewsCount,
          featured: featuredReviews,
        });
      }
    });
  }, [productKey]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }


  return (
    <div className="bg-gray-900">
      <div className="bg-gray-900 mx-auto max-w-2xl py-14 px-12 sm:py-14 sm:px-12 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-x-8 lg:py-24 lg:px-24">
        <div className="lg:col-span-4">
          <h2 className="text-2xl font-bold tracking-tight text-white">Customer Reviews</h2>

          <div className="mt-3 flex items-center">
            <div>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    className={classNames(
                      reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',
                      'flex-shrink-0 h-5 w-5'
                    )}
                    aria-hidden="true"
                  />
                ))}
              </div>
              <p className="sr-only">{reviews.average} out of 5 stars</p>
            </div>
            <p className="ml-2 text-sm text-white">Based on {reviews.totalCount} reviews</p>
          </div>

          <div className="mt-6">
            <h3 className="sr-only">Review data</h3>

            <dl className="space-y-3">
              {reviews.counts.map((count) => (
                <div key={count.rating} className="flex items-center text-sm">
                  <dt className="flex flex-1 items-center">
                    <p className="w-3 font-medium text-white">
                      {count.rating}
                      <span className="sr-only"> star reviews</span>
                    </p>
                    <div aria-hidden="true" className="ml-1 flex flex-1 items-center">
                      <StarIcon
                        className={classNames(
                          count.count > 0 ? 'text-yellow-400' : 'text-gray-300',
                          'flex-shrink-0 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />

                      <div className="relative ml-3 flex-1">
                        <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                        {count.count > 0 ? (
                          <div
                            className="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                            style={{ width: `calc(${count.count} / ${reviews.totalCount} * 100%)` }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </dt>
                  <dd className="ml-3 w-10 text-right text-sm tabular-nums text-white">
                    {Math.round((count.count / reviews.totalCount) * 100)}%
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10">
            <h3 className="text-lg font-medium text-white">Share your thoughts</h3>
            <p className="mt-1 text-sm text-gray-400">
              If you’ve used this product, share your thoughts with other customers
            </p>

            <button
              onClick={() => setReviewModal(true)}
              className="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white py-2 px-8 text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
            >
              Write a review
            </button>
          </div>
        </div>

        <div className="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0">
          <h3 className="sr-only">Recent reviews</h3>

          <div className="flow-root">
            <div className="-my-12 divide-y divide-gray-200">
              {reviews.featured.map((review) => (
                <div key={review.id} className="py-12">
                  <div className="flex items-center">
                    <img src={review.avatarSrc} alt={`${review.author}.`} className="h-12 w-12 rounded-full" />
                    <div className="ml-4">
                      <h4 className="text-sm font-bold text-white">{review.author}</h4>
                      <div className="mt-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                              'h-5 w-5 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="sr-only">{review.rating} out of 5 stars</p>
                    </div>
                  </div>

                  <div
                    className="mt-4 space-y-6 text-base italic text-gray-400"
                    dangerouslySetInnerHTML={{ __html: review.content }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Reviews;